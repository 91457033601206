import React, { useState, useEffect } from "react";
import { Select, message, Spin } from "antd";
import SchoolService from "../services/school";
import { getGradeName } from "../utils/util";
const { Option } = Select;

export default function GradeYearSelect(props) {
  const { campusId, onChange } = props;

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    SchoolService.getGrade(campusId, (res) => {
      setLoading(false);
      if (res.success) {
        let data = res.data || [];
        setRows(
          data.map((i) => {
            return {
              ...i,
              name: getGradeName(i),
            };
          })
        );
      } else {
        message.error("加载校区年级出错");
      }
    });
  }, [campusId]);

  const onYearChange = (year) => {
    onChange && onChange(year);
  };

  return loading ? (
    <Spin />
  ) : (
    <Select
      onChange={onYearChange}
      style={{ width: "200px" }}
      placeholder="请选择"
    >
      {rows.map((g) => (
        <Option value={g.year} key={g.year}>
          {g.name}
        </Option>
      ))}
    </Select>
  );
}
