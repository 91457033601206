import React, { Component } from "react";
import { Breadcrumb, Button } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import BadgeTable, {
   E_TO_ADD_BADGE
} from "../../components/BadgeTable";
import emitter from "../../emitter";

export class Badge extends Component {
  render() {
    const toAdd = () => {
      emitter.emit(E_TO_ADD_BADGE);
    };
    return (
      <div>
        <div className="h46 padding bg-white flex justify-between align-center">
          <Breadcrumb>
            <Breadcrumb.Item>任务管理</Breadcrumb.Item>
            <Breadcrumb.Item>奖章管理</Breadcrumb.Item>
          </Breadcrumb>
          <div>
            <Button type="primary" icon={<PlusOutlined/>}  onClick={toAdd}>
              新增奖章
            </Button>
          </div>
        </div>
        <div className="margin bg-white">
          <BadgeTable />
        </div>
      </div>
    );
  }
}

export default Badge;
