import React, { Component } from "react";
import { Modal, notification, Button } from "antd";
import AuthTree from "./AuthTree";
import UserService from "../services/user";

const {confirm} = Modal;

export class RoleUserModal extends Component {
  constructor(props) {
    super();
    this.state = {
      rows: []
    };
    this.onCheck = this.onCheck.bind(this);
    this.toSave = this.toSave.bind(this);
  }
  componentDidMount() {
    this.load();
  }
  load() {
    UserService.getRoleAuth(this.props.item.id, res => {
      if (res.success) {
        let rows = res.data;
        this.setState({ rows });
      } else {
        notification.error({
          message: "加载角色权限失败",
          description: res.message
        });
      }
    });
  }
  onCheck(arr) {
    this.setState({ rows: arr });
  }
  toSave(){
    let that = this;
    confirm({
      title: "请确认",
      content: "您确定要保存角色权限信息吗？",
      onOk: ()=> {
        UserService.saveRoleAuth(that.props.item.id,that.state.rows.map(a=>a.id).join(','), res => {
          if (res.success) {
            that.props.onCancel();            
            notification.success({
              message: "操作成功！",
              description: "保存角色权限成功！"
            });
          } else {
            notification.error({
              message: "保存角色权限失败",
              description: res.message
            });
          }
        });
      }
    });
  }

  render() {
    const checkedKeys = this.state.rows.map(item=>{
      return item.id;
    })
    return (
      <div>
        <Modal title="角色权限信息" visible={this.props.visible} onCancel={this.props.onCancel} footer={null} maskClosable={false}>
          <AuthTree checkable={true} checkedKeys={checkedKeys} onCheck={this.onCheck} />
          <div className="text-center margin-top">
            <Button type="primary" onClick={this.toSave}>保存</Button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default RoleUserModal;
