import Service from './service';


const CACHE = {};

/**
 * 设置相关服务接口
 */
class SettingService extends Service {

    static getAll(callback){
        let url = './api/setting';
        super.get(url,callback);
    }
    
    static remove(id,callback){
        let url = `./api/setting/${id}`;
        super.delete(url,callback);
    }
    static save(payload,callback){
        let url = `./api/setting`;
        super.post(url,payload,callback);
    }
    
    static getByCode(code,callback){
        let res = CACHE[code];
        if(res){
            callback(res)
        }else{
            let url = `./api/setting/${code}`;
            super.get(url,rs=>{
                CACHE[code] = rs;
                callback(rs);
            });
        }
    }

}

export default SettingService;