import React, { Component } from "react";
import { Button, Table, message, Modal, notification,  Tag,Avatar } from "antd";
import SchoolService from "../services/school";
import emitter from "../emitter";
import StudentForm from "./StudentForm";
import util from "../utils/util";

const { confirm } = Modal;
export const E_TO_ADD_STUDENT = "toAddStudent";
export const E_TO_RELOAD_STUDENT = "toReloadStudent";

export class StudentTable extends Component {
  mLoadToken = null;
  mLoadGradeToken = null;

  constructor(props) {
    super();
    this.state = {
      rows: [],
      size: 20,
      total: 0,
      page: 0,
      crtGrade: null,
      gid: props.gid,
      removing: false,
    };
    this.toAdd = this.toAdd.bind(this);
    this.reload = this.reload.bind(this);
    this.toModify = this.toModify.bind(this);
    this.toRemove = this.toRemove.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.payloadChange = this.payloadChange.bind(this);
    
  }
  componentDidMount() {
    this.loadGrade();
    this.loadStudent(0);
    emitter.addListener(E_TO_ADD_STUDENT, this.toAdd);
    emitter.addListener(E_TO_RELOAD_STUDENT, this.reload);
  }
  componentWillUnmount() {
    emitter.removeListener(E_TO_ADD_STUDENT, this.toAdd);
    emitter.removeListener(E_TO_RELOAD_STUDENT, this.reload);
  }

  loadGrade() {
    let id = this.props.gid;
    if (id) {
      if (this.mLoadGradeToken) {
        this.mLoadGradeToken.cancel();
      }
      this.mLoadGradeToken = SchoolService.getGradeById(id, (res) => {
        if (res.success) {
          let crtGrade = res.data;
          this.setState({ crtGrade });
        } else {
          notification.error({
            message: "加载班级信息出错啦",
            description: res.message,
          });
        }
      });
    }
  }
  reload() {
    this.loadGrade();
    this.loadStudent(0);
  }
  loadStudent(page) {
    let params = Object.assign(
      {
        size: this.state.size,
        page,
        sort: "articleCount,desc",
      },
      this.props.filter
    );
    if (this.props.gid) {
      params.gid = this.props.gid;
    }
    this.setState({ loading: true });

    if (this.mLoadToken) {
      this.mLoadToken.cancel();
    }

    this.mLoadToken = SchoolService.getStudent(params, (res) => {
      this.setState({ loading: false });
      if (!this.unmount) {
        if (res && res.success) {
          let { total, rows } = res.data;
          this.setState({ total, rows, page });
        } else {
          message.error("加载学生数据出错！");
        }
      }
    });
  }

  toUnbind(item, index) {
    let that = this;
    let rows = [].concat(this.state.rows);
    confirm({
      title: "请确认",
      content: "您确定要解除学生微信绑定吗？",
      onOk: () => {
        // this.setState({removing:true})
        SchoolService.unbindWx(item.id, (res) => {
          // this.setState({removing:false})
          if (res.success) {
            message.success("学生微信解绑成功");
            // item.wxs = [];

            // rows[index] = item;
            // that.setState({ rows });
            that.loadStudent(that.state.page)
          } else {
            message.error("解除学生微信绑定失败");
          }
        });
      },
    });
  }

  getColumns() {
    let pre = this.state.size * this.state.page;
    
    const getWx = (text, record) => {      
      return record.wxs && record.wxs.length > 0 ? (       
        <Avatar.Group>
          {
            record.wxs.map(wx=><Avatar key={wx.openId} src={wx.avatarUrl} title={wx.nickName}/>)
          }          
          </Avatar.Group>
      ) : (
        "-"
      );
    };
    let columns = [
      {
        title: "序号",
        key: "index",
        className: "text-center",
        render: (text, record, index) => <span>{pre + index + 1}</span>,
        width: "80px",
      },

      {
        title: "姓名",
        key: "name",
        render: (text, record) => {
          return (
            <div>
              <span>{record.name}</span> <br />
              <small>
                {record.grade.campus.name} / {record.grade.name}{" "}
              </small>
            </div>
          );
        },
      },
      {
        title: "性别",
        dataIndex: "sex",
        width: "70px",
        className: "text-center",
      },
      {
        title: "激活码",
        dataIndex: "code",
        width: "100px",
        className: "text-center",
      },
      {
        title: "记录数",
        dataIndex: "articleCount",
        width: "80px",
        className: "text-center",
      },
      {
        title: "徽章数",
        key: "badge",
        width: "80px",
        className: "text-center",
        render: (text, record) => {
          return record.badges ? (
            <Tag
              color="green"
              className="pointer"
              onClick={() => this.setState({ badges: record.badges })}
            >
              {record.badges.length}
            </Tag>
          ) : (
            <Tag>0</Tag>
          );
        },
      },
      {
        title: "称号",
        key: "title",
        width: "120px",
        render: (text, record) => {
          return <span>{record.title ? record.title.name : "-"}</span>;
        },
      },
      { title: "绑定微信", key: "wx", width: "180px", render: getWx },
      {
        title: "操作",
        key: "action",
        width: "180px",
        className: "text-center",
        render: (text, record, index) => {
          return this.props.actionRender ? (
            this.props.actionRender(text, record, index)
          ) : (
            <Button.Group size="small">
              <Button onClick={() => this.toModify(record)}>修改</Button>
              <Button
                loading={this.state.removing}
                onClick={() => this.toUnbind(record, index)}
                disabled={!record.wxs || record.wxs.length === 0}
              >
                解绑
              </Button>
              <Button
                type="danger"
                loading={this.state.removing}
                onClick={() => this.toRemove(record, index)}
              >
                删除
              </Button>
            </Button.Group>
          );
        },
      },
    ];
    return columns;
  }
  toModify(item) {
    // console.log(item);
    let payload = Object.assign({}, item);
    this.setState({ payload });
  }
  toRemove(record, index) {
    let that = this;
    confirm({
      title: "请确认",
      content: "您确定要删除学生吗？",
      onOk: () => {
        // this.setState({removing:true})
        SchoolService.removeStudent(record.id, (res) => {
          // this.setState({removing:false})
          if (res.success) {
            notification.success({
              message: "操作成功！",
              description: "学生删除成功！",
            });
            // let rows = [].concat(that.state.rows);
            // rows.splice(index, 1);
            // that.setState({ rows });
            that.loadStudent(that.state.page)
          } else {
            notification.error({
              message: "删除学生失败",
              description: res.message,
            });
          }
        });
      },
    });
  }
  toAdd() {
    if (!this.unmount) {
      let payload = { sex: "男", grade: this.state.crtGrade };
      this.setState({ payload });
    }
  }
  handleCancel() {
    this.setState({ payload: null });
  }
  payloadChange(payload) {
    this.setState({ payload });
  }
  handleOk() {
    let { payload, page } = this.state;
    if (!payload.grade) {
      message.warn("请先选择校区和班级");
      return;
    }
    if (!payload.name) {
      message.warn("请输入姓名");
      return;
    }
    if (!payload.code) {
      message.warn("请输入激活码");
      return;
    }
    confirm({
      title: "请确认",
      content: "您确定要保存学生信息吗？",
      onOk: () => {
        SchoolService.saveStudent(payload, (res) => {
          if (res.success) {
            notification.success({
              message: "操作成功！",
              description: "保存学生成功！",
            });
            this.loadStudent(page);
            // let rs = res.data;
            // let rows = [].concat(that.state.rows);
            // let index = util.findIdxInArr(rows, "id", rs);
            // if (index >= 0) {
            //   rows[index] = rs;
            // } else {
            //   rows.splice(0, 0, rs);
            // }
            // that.setState({ rows });
            this.handleCancel();
          } else {
            notification.error({
              message: "保存学生失败",
              description: res.message,
            });
          }
        });
      },
    });
  }

  render() {
    let showTotal = (total) => `共${total}条记录`;
    let pagination = {
      current: this.state.page + 1,
      pageSize: this.state.size,
      total: this.state.total,
      onChange: (page,size) => {
        this.setState({ size },()=>{
          this.loadStudent(page - 1);
        })
      },
      showTotal,
    };
    const getAttUrl = util.getAttUrl;
    return (
      <div>
        <Table
          loading={this.state.loading}
          bordered={true}
          className="bg-white"
          columns={this.getColumns()}
          dataSource={this.state.rows}
          rowKey={(record) => record.id}
          pagination={pagination}
        />
        {this.state.payload ? (
          <Modal
            title="学生信息编辑"
            visible={!!this.state.payload}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            <StudentForm
              item={this.state.payload}
              onChange={this.payloadChange}
            />
          </Modal>
        ) : null}
        <Modal
          title="已获得徽章"
          visible={!!this.state.badges}
          onOk={() => this.setState({ badges: null })}
          onCancel={() => this.setState({ badges: null })}
        >
          <div className="cu-list grid col-2">
            {this.state.badges
              ? this.state.badges.map((bad) => {
                  return (
                    <div className="cu-item" key={bad.id}>
                      <img src={getAttUrl(bad.icon)} alt="icon" />
                      <div>{bad.name}</div>
                    </div>
                  );
                })
              : null}
          </div>
        </Modal>
      </div>
    );
  }
}

export default StudentTable;
