import Service from './service';

/**
 * 调查问题相关服务接口
 */
class QuestionService extends Service {

    static getAll(callback) {
        let url = './api/question';
        super.get(url, callback);
    }

    static save(payload, callback) {
        let url = './api/question';
        super.post(url, payload, callback);
    }
    static remove(id, callback) {
        let url = `./api/question/${id}`;
        super.delete(url, callback);
    }
    static getQuestionStatistic(params, callback) {
        let url = `./api/question/statistic?`;
        url += Object.keys(params)
        .map(key => {
          return `${key}=${params[key]}`;
        })
        .join("&");
        super.get(url, callback);
    }

}

export default QuestionService;