import React, { Component } from "react";
import { Button, Table, message, Modal, notification } from "antd";
import SchoolService from "../services/school";
import emitter from "../emitter";
import TeacherForm from "./TeacherForm";

const { confirm } = Modal;
export const E_TO_ADD_TEACHER = "toAddTeacher";
export const E_TO_RELOAD_TEACHER = "toReloadTeacher";

export class TeacherTable extends Component {
  constructor(props) {
    super();
    this.state = {
      loading:false,
      rows: [],
      size: 20,
      total: 0,
      page: 0,
    };
    this.toAdd = this.toAdd.bind(this);
    this.toModify = this.toModify.bind(this);
    this.toRemove = this.toRemove.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.payloadChange = this.payloadChange.bind(this);
    this.reload = this.reload.bind(this);
  }
  componentDidMount() {
    this.loadCampus();
    this.loadTeacher(0);
    emitter.addListener(E_TO_ADD_TEACHER, this.toAdd);
    emitter.addListener(E_TO_RELOAD_TEACHER, this.reload);
  }

  componentWillUnmount() {
    this.unmount = true;
    emitter.removeListener(E_TO_ADD_TEACHER, this.toAdd);
    emitter.removeListener(E_TO_RELOAD_TEACHER, this.reload);
  }


  loadCampus(){
    if(!this.props.cid) return ;
    SchoolService.getCampusById(this.props.cid,res=>{
      if (res && res.success) {
        let crtCampus = res.data;
        this.setState({ crtCampus });
      } else {
        message.error("加载校区数据出错！");
      }
    });
  }

  reload(){
    setTimeout(() => {
      this.loadCampus();
      this.loadTeacher(0);
    }, 10);
  }

  loadTeacher(page) {
    page = page || 0;
    this.setState({loading:true});
    let param = {
      size: this.state.size,
      page,
      sort: "user.name"
    }
    if(this.props.cid){
      param.cid =  this.props.cid
    }

    let params = Object.assign(param,this.props.filter);
    SchoolService.getTeacher(params,
      res => {
        this.setState({loading:false})
        if (!this.unmount) {
          if (res && res.success) {
            let { total, rows } = res.data;
            this.setState({ total, rows, page });
          } else {
            message.error("加载教师数据出错！");
          }
        }
      }
    );
  }

  getColumns() {
    let pre = this.state.size * this.state.page;
    const getWx = (text, record) => {
      return record.wx ? (
        <div className="flex align-center">
          <img
            src={record.wx.avatarUrl}
            alt="avatar"
            style={{ height: "46px" }}
          />{" "}
          <span className="margin-left-sm">{record.wx.nickName}</span>
        </div>
      ) : (
        "-"
      );
    };
    return [
      {
        title: "序号",
        key: "index",
        className: "text-center",
        render: (text, record, index) => <span>{pre + index + 1}</span>,
        width: "80px"
      },
      {
        title: "校区",
        key: "campus",
        width:'200px',
        render: (text, record) => <span>{record.campus.alias}</span>
      },
      {
        title: "姓名",
        key: "name",
        render: (text, record) => <span>{record.user.name}</span>
      },
      {
        title: "电话",
        key: "phone",
        width:'120px',
        render: (text, record) => <span>{record.user.phone||'-'}</span>
      },
      {
        title: "激活码",
        dataIndex: "code",
        width: "120px",
      },
      {
        title: "学科",
        dataIndex: "course",
        width: "120px",
      },
      { title: "绑定微信", key: "wx", width: "180px", render: getWx },
      {
        title: "操作",
        key: "action",
        width: "150px",
        className: "text-center",
        render: (text, record) => {
          return (
            <Button.Group size="small">
              <Button onClick={() => this.toModify(record)}>修改</Button>
              <Button
                type="danger"
                onClick={() => this.toRemove(record)}
              >
                删除
              </Button>
            </Button.Group>
          );
        }
      }
    ];
  }
  toModify(item) {
    // console.log(item);
    let payload = Object.assign({}, item);
    this.setState({ payload });
  }
  toRemove(record) {
    let that = this;
    confirm({
      title: "请确认",
      content: "您确定要删除教师吗？",
      onOk: ()=> {
        SchoolService.removeTeacher(record.id, res => {
          if (res.success) {
            notification.success({
              message: "操作成功！",
              description: "教师删除成功！"
            });
            that.loadTeacher(that.state.page)
          } else {
            notification.error({
              message: "删除教师失败",
              description: res.message
            });
          }
        });
      }
    });
  }
  toAdd() {
    if (!this.unmount) {
      let payload = { sex: "男", campus: this.state.crtCampus };
      this.setState({ payload });
    }
  }
  handleCancel() {
    this.setState({ payload: null });
  }
  payloadChange(payload) {
    this.setState({ payload });
  }
  handleOk() {
    let that = this;
    let {  payload } = this.state;

    if (!payload.campus) {
      message.warn("请选择校区");
      return;
    }
    if (!payload.user || !payload.user.name) {
      message.warn("请输入姓名");
      return;
    }
    if (!payload.user || !payload.user.phone) {
      message.warn("请输入电话");
      return;
    }
    if (!payload.code) {
      message.warn("请输入激活码");
      return;
    }
    confirm({
      title: "请确认",
      content: "您确定要保存教师信息吗？",
      onOk: ()=> {
        SchoolService.saveTeacher(payload, res => {
          if (res.success) {
            that.handleCancel();
            notification.success({
              message: "操作成功！",
              description: "保存教师成功！"
            });
            that.loadTeacher(0)
          } else {
            notification.error({
              message: "保存教师失败",
              description: res.message
            });
          }
        });
      }
    });
  }

  render() {
    let showTotal = total => `共${total}条记录`;
    let pagination = {
      current: this.state.page + 1,
      pageSize: this.state.size,
      total: this.state.total,
      onChange: (page,size) => {
        this.setState({size},()=>{
          this.loadTeacher(page - 1);
        })
      },
      showTotal
    };
    return (
      <div>
        <Table
          loading={this.state.loading}
          bordered={true}
          className="bg-white"
          columns={this.getColumns()}
          dataSource={this.state.rows}
          rowKey={record => record.id}
          pagination={pagination}
        />
        {this.state.payload ? (
        <Modal
          title="教师信息编辑"
          visible={!!this.state.payload}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          
            <TeacherForm
              item={this.state.payload}
              onChange={this.payloadChange}
            />
        </Modal>):null}
      </div>
    );
  }
}

export default TeacherTable;
