import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { Layout, Row, Col } from "antd";
import queryString from "query-string";

import { setMe } from "../store/reducer";

import LoginFrom from "../components/LoginForm";
import UserService from "../services/user";

const { Header, Footer, Content } = Layout;

class Login extends Component {
  constructor(options) {
    super();
    this.state = {
      me: null,
    };
  }
  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    // console.log(values);
    if (values && values.status) {
      //message.error("您尚未登录!");
    } else {
      UserService.getMe(res => {
        if (res && res.success && res.data) {
          let me = res.data;
          this.props.setMe(me);
          this.setState({ me });
        }
      });
    }
  }
  render() {
    if (this.state.me) {
      return <Redirect to="/" />;
    } else{
      return (
        <Layout className="h100">
          <Header>
            <h1 className="text-white">{this.props.site.title}</h1>
          </Header>
          <Content className="content-with-header">
            <Row type="flex" justify="center" align="top">
              <Col xl={{ span: 8 }} md={{ span: 18 }} sm={{ span: 22 }}>
                <div className="m-1 p-1 bg-white">
                  <LoginFrom />
                </div>
              </Col>
            </Row>
          </Content>
          <Footer className="text-center">{this.props.site.copyright}</Footer>
        </Layout>
      );
    }
  }
}

function mapStateToProps(state) {
  let site = state.site;
  return { site };
}

const mapDispatchToProps = dispatch => ({
  setMe: me => dispatch(setMe(me))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
