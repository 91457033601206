import React, { useState, useEffect } from "react";
import { Button, DatePicker, message, Table, Drawer } from "antd";

import { timeranges } from "../../../utils/cons";

import TaskService from "../../../services/task";

const { RangePicker } = DatePicker;

export default function TaskStatCampus(props) {
  const { downloader } = props;

  const [range, setRange] = useState(timeranges["30天内"]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [studentLoading, setStudentLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [studentList, setStudentList] = useState({});
  const [studentDownloadLink, setStudentDownloadLink] = useState(null);

  const onRangeChange = (arr) => {
    setRange([arr[0].startOf("day"), arr[1].endOf("day")]);
  };

  const getParams = () => {
    return {
      start: range[0].format(),
      end: range[1].endOf('day').format(),
    };
  };

  const showTitleStudentDetail = (cid, tid) => {
    let params = getParams();
    params.cid = cid;
    params.tid = tid;

    setStudentDownloadLink(TaskService.getCampusTitleStudentExportLink(params));

    setStudentLoading(true);

    setDrawerVisible(true);
    TaskService.getCampusTitleStudent(params, (res) => {
      setStudentLoading(false);
      if (res.success) {
        let rc = res.data;
        rc.columns = (rc.columns || []).map((c) => {
          return {
            ...c,
            sorter: (a, b) => a.year - b.year,
          };
        });
        setStudentList(rc);
      } else {
        message.error("加载学生列表失败");
      }
    });
  };

  const onCloseDrawer = () => {
    setDrawerVisible(false);
  };

  const doQuery = () => {
    setLoading(true);
    let params = getParams();
    TaskService.getCampusStat(params, (res) => {
      setLoading(false);
      if (res.success) {
        let { rows, columns } = res.data || { rows: [], columns: [] };
        setRows(rows);
        setColumns(
          columns.map((c) => {
            let col = {
              ...c,
              sorter: (a, b) => {
                let x = a[c["dataIndex"]],
                  y = b[c["dataIndex"]];
                if (typeof x === "number") {
                  return x - y;
                } else {
                  return (x + "").localeCompare(y + "");
                }
              },
              sortDirections: ["descend", "ascend"],
            };

            // 2022-03-14 处理点击 铁器萌主 展示列表 : 5aa318c1-6c5d-4655-86b7-7abeac5ea2bb
            let ckey = "title_";
            if (c.dataIndex.indexOf(ckey) >= 0) {
              let tid = c.dataIndex.substring("title_".length);
              col.render = (text, row) => {
                let cid = row["id"];

                return (
                  <div
                    onClick={() => showTitleStudentDetail(cid, tid)}
                    style={{
                      color: "#1da57a",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                  >
                    {text}
                  </div>
                );
              };
            }

            return col;
          })
        );

        downloader.link = TaskService.getCampusStatExport(params);
      } else {
        message.error("获取统计数据失败");
      }
    });
  };
  useEffect(doQuery, []);

  const toDownloadStudent = () => {
    let link = studentDownloadLink;
    if (link) {
      let a = document.createElement("a");
      a.target = "_blank";
      a.href = link;
      a.click();
    } else {
      message.warn("没有可以下载的内容");
    }
  };

  return (
    <div className="padding w100">
      <div className="margin-bottom ">
        <RangePicker
          defaultValue={range}
          ranges={timeranges}
          onChange={onRangeChange}
          format="YYYY-MM-DD"
          className="margin-right"
        />
        <Button type="primary" onClick={doQuery}>
          统计
        </Button>
      </div>

      <Table
        className="bg-white"
        columns={columns}
        dataSource={rows}
        rowKey={(record) => record.id}
        loading={loading}
        pagination={false}
      />

      <Drawer
        title="萌主学生名单"
        placement="right"
        size="large"
        onClose={onCloseDrawer}
        visible={drawerVisible}
      >
        <div className="mb-1">
          <Button type="primary" onClick={() => toDownloadStudent()}>
            下载
          </Button>
        </div>
        <Table
          loading={studentLoading}
          columns={studentList.columns}
          dataSource={studentList.rows}
          rowKey={(record) => record.year + "" + record.class + record.name + record.titleTime}
          pagination={false}
        />
      </Drawer>
    </div>
  );
}
