import React, { Component } from "react";
import BadgeService from "../services/badge";
import { notification, message, Table, Button, Modal } from "antd";
import emitter from "../emitter";
import BadgeForm from "./BadgeForm";
import util from "../utils/util";
import AttachService from "../services/attach";
import { Switch } from "antd";

const { confirm } = Modal;

export const E_TO_ADD_BADGE = "toAddBadge";

export class BadgeTable extends Component {
  constructor() {
    super();
    this.state = {
      rows: [],
      payload: null,
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.payloadChange = this.payloadChange.bind(this);
    this.toAdd = this.toAdd.bind(this);
  }

  componentDidMount() {
    BadgeService.getAll((res) => {
      if (res.success) {
        let rows = res.data;
        this.setState({ rows });
      } else {
        message.error("加载奖章出错：" + res.message);
      }
    });
    emitter.addListener(E_TO_ADD_BADGE, this.toAdd);
  }
  toAdd() {
    let payload = { times: 10, advance: true };
    this.setState({ payload });
  }
  toModify(item) {
    // console.log(item);
    let payload = Object.assign({}, item);
    this.setState({ payload });
  }
  toRemove(record, index) {
    let that = this;
    confirm({
      title: "请确认",
      content: "您确定要删除奖章吗？",
      onOk: () => {
        BadgeService.remove(record.id, (res) => {
          if (res.success) {
            notification.success({
              message: "操作成功！",
              description: "奖章删除成功！",
            });
            let rows = [].concat(that.state.rows);
            rows.splice(index, 1);
            that.setState({ rows });
          } else {
            notification.error({
              message: "删除奖章失败",
              description: res.message,
            });
          }
        });
      },
    });
  }
  getColumns() {
    return [
      {
        title: "序号",
        key: "index",
        className: "text-center",
        render: (text, record, index) => <span>{index + 1}</span>,
        width: "80px",
      },
      { title: "名称", dataIndex: "name" },
      {
        title: "所属分类",
        key: "category",
        width: "120px",
        render: (text, record) => {
          return record.category.title;
        },
      },
      {
        title: "晋级",
        key: "advance",
        width: "80px",
        className: "text-center",
        render: (text, record) => {
          return <Switch checked={record.advance} disabled />;
        },
      },
      {
        title: "图标",
        key: "icon",
        width: "120px",
        className: "text-center",
        render: this.getIcon,
      },
      {
        title: "要求",
        dataIndex: "times",
        width: "80px",
        className: "text-center",
      },

      {
        title: "操作",
        key: "action",
        width: "150px",
        render: (text, record, index) => {
          return (
            <Button.Group size="small">
              <Button
                onClick={() => {
                  this.toModify(record);
                }}
              >
                修改
              </Button>
              <Button
                type="danger"
                onClick={() => {
                  this.toRemove(record, index);
                }}
              >
                删除
              </Button>
            </Button.Group>
          );
        },
      },
    ];
  }
  getIcon(text, record) {
    const getAttUrl = util.getAttUrl;

    return record.icon ? (
      <img src={getAttUrl(record.icon)} alt="icon" style={{ height: "46px" }} />
    ) : (
      "默认"
    );
  }
  handleOk() {
    let that = this;
    let { payload } = this.state;
    if (!payload.name) {
      message.warn("请填写奖章名称");
      return;
    }
    if (!payload.category) {
      message.warn("请选择奖章所属任务分类");
      return;
    }
    if (!payload.icon && !payload.file) {
      message.warn("请设置奖章图标");
      return;
    }
    if (!payload.times) {
      message.warn("请设置奖章获得要求任务完成次数");
      return;
    }
    confirm({
      title: "请确认",
      content: "您确定要保存奖章信息吗？",
      onOk: () => {
        // 先上传图标
        if (payload.file) {
          AttachService.upload([payload.file], (res) => {
            if (res.success) {
              payload.icon = res.data[0];
              that.save(payload);
            } else {
              notification.error({
                message: "上传奖章图片出错！",
                description: res.message,
              });
            }
          });
        } else {
          that.save(payload);
        }
      },
    });
  }
  save(payload) {
    let rows = [].concat(this.state.rows);
    BadgeService.save(payload, (res) => {
      if (res.success) {
        notification.success({
          message: "操作成功！",
          description: "保存奖章成功！",
        });
        let rs = res.data;
        let index = util.findIdxInArr(rows, "id", rs);
        if (index >= 0) {
          rows[index] = rs;
        } else {
          rows.splice(0, 0, rs);
        }
        rows.sort((a, b) => b.sort - a.sort);
        this.setState({ rows });
        this.handleCancel();
      } else {
        notification.error({
          message: "保存奖章失败",
          description: res.message,
        });
      }
    });
  }
  handleCancel() {
    this.setState({ payload: null });
  }
  payloadChange(payload) {
    this.setState({ payload });
  }
  render() {
    return (
      <div>
        <Table
          bordered={true}
          className="bg-white"
          columns={this.getColumns()}
          dataSource={this.state.rows}
          rowKey={(record) => record.id}
          pagination={false}
        />
        {this.state.payload ? (
          <Modal
            title="奖章编辑"
            visible={!!this.state.payload}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            <BadgeForm
              item={this.state.payload}
              onChange={this.payloadChange}
            />
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default BadgeTable;
