import React, { Component } from "react";
import util from "../utils/util";
import { Form, Input, Empty } from "antd";

export class DictForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.item
    };
    this.iptChange = this.iptChange.bind(this);
  }

  iptChange(e) {
    e.persist();
    let { name, value } = e.target;
    let payload = util.setValue(this.state.payload, name, value);

    this.triggerChange(payload);
  }
  triggerChange(payload) {
    this.setState({ payload });
    this.props.onChange(payload);
  }

  render() {
    let payload = this.state.payload;
    return payload ? (
      <Form layout="vertical">
        <Form.Item label="字典CODE" className="margin-bottom-sm">
          <Input
            defaultValue={payload.code}
            onChange={this.iptChange}
            name="code"
          />
        </Form.Item>
        <Form.Item label="字典名称" className="margin-bottom-sm">
          <Input
            defaultValue={payload.cname}
            onChange={this.iptChange}
            name="cname"
          />
        </Form.Item>

        <Form.Item label="字典值">
          <Input
            defaultValue={payload.value}
            onChange={this.iptChange}
            name="value"
          />
        </Form.Item>
      </Form>
    ) : (
      <Empty />
    );
  }
}

export default DictForm;
