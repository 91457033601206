import React, { Component } from "react";
import { Breadcrumb,Button } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import DictTable, { E_TO_ADD_DICT } from '../../components/DictTable';
import emitter from "../../emitter";
export class Dict extends Component {
  render() {
    const toAdd=()=>{
      emitter.emit(E_TO_ADD_DICT);
    };
    return (
      <div>
        <div className="padding bg-white flex justify-between  align-center h46">
          <Breadcrumb>
            <Breadcrumb.Item>系统设置</Breadcrumb.Item>
            <Breadcrumb.Item>字典管理</Breadcrumb.Item>
          </Breadcrumb>
          <div>
            <Button type="primary" icon={<PlusOutlined/>}  onClick={toAdd}>新增字典</Button>          
          </div>
        </div>
        <div className="margin bg-white">
          <DictTable />
        </div>
      </div>
    );
  }
}

export default Dict;
