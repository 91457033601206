import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  notification,
  Empty,
  Modal,
  message,
  Pagination,
} from "antd";
import { RViewer, RViewerTrigger } from "react-viewerjs";
import emitter from "../emitter";
import util from "../utils/util";
import ArticleService from "../services/article";
import AttachService from "../services/attach";
import ArticleForm from "./ArticleForm";
import { Spin } from "antd";
import { Tag } from "antd";

export const E_TO_ADD_ARTICLE = "toAddArticle";
export const E_TO_RELOAD_ARTICLE = "toReloadArticle";

const { confirm } = Modal;

export class ArticleTable extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: true,
      rows: [],
      page: 0,
      size: 20,
      total: 0,
      article: null,
    };
    this.toAdd = this.toAdd.bind(this);
    this.reload = this.reload.bind(this);
    this.getArticleView = this.getArticleView.bind(this);
    this.toDetail = this.toDetail.bind(this);
    this.handleCancelDetail = this.handleCancelDetail.bind(this);
    this.toRemove = this.toRemove.bind(this);
    // 发布
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.payloadChange = this.payloadChange.bind(this);
  }
  componentDidMount() {
    emitter.addListener(E_TO_ADD_ARTICLE, this.toAdd);
    emitter.addListener(E_TO_RELOAD_ARTICLE, this.reload);
    this.load(0);
    const childRef = this.props.childRef;
    childRef(this);
  }
  componentWillUnmount() {
    emitter.removeListener(E_TO_ADD_ARTICLE, this.toAdd);
    emitter.removeListener(E_TO_RELOAD_ARTICLE, this.reload);
  }
  reload() {
    setTimeout(() => {
      this.load(0);
    }, 10);
  }
  load(page,s) {
    let size = s || this.state.size
    if(s){
      this.setState({size : s})
    }
    //let { size } = this.state;
    let params = Object.assign(
      {
        page,
        size,
      },
      {
        ...this.props.params,
        topFirst: false,
      }
    );

    this.setState({ loading: true });
    ArticleService.get(params, (res) => {
      if (res.success) {
        let { rows, total } = res.data;
        if (this.props.onPage) {
          this.props.onPage({
            page,
            total,
            size,
          });
        }
        this.setState({ page, rows, total, loading: false });
      } else {
        notification.error({
          message: "加载记录失败",
          description: res.message,
        });
      }
    });
  }

  toAdd() {
    let payload = { type: 1, authorId: this.props.me.id };
    this.setState({ payload });
  }
  toDetail(article) {
    this.setState({ article });
  }
  handleCancelDetail() {
    this.setState({ article: null });
  }
  toRemove(item) {
    let that = this;
    let rows = [].concat(that.state.rows);
    confirm({
      title: "请确认",
      content: "您确定要删除此记录吗？",
      onOk: () => {
        ArticleService.remove(item.id, (res) => {
          if (res.success) {
            notification.success({
              message: "操作成功！",
              description: "删除记录成功",
            });
            let idx = util.findIdxInArr(rows, "id", item);
            rows.splice(idx, 1);
            that.setState({ rows });
          } else {
            notification.error({
              message: "删除文章失败了！",
              description: res.message,
            });
          }
        });
      },
    });
  }
  handleOk() {
    let that = this;
    let { payload } = this.state;
    if (!payload.content) {
      message.warn("请填写文章内容");
      return;
    }
    if (payload.shareGrade && !payload.campus) {
      message.warn("同校区共享，请先选择校区");
      return;
    }
    confirm({
      title: "请确认",
      content: "您确定要保存文章信息吗？",
      onOk: () => {
        if (payload.imgList && payload.imgList.length > 0) {
          let files = payload.imgList.map((item) => item.file);
          AttachService.upload(files, (res) => {
            if (res.success) {
              payload.attachments = res.data.map((att) => {
                return { id: att.id };
              });
              that.doSave(payload);
            } else {
              notification.error({
                message: "上传附件出错了",
                description: res.message,
              });
            }
          });
        } else {
          that.doSave(payload);
        }
      },
    });
  }
  doSave(payload) {
    delete payload.imgList;
    ArticleService.save(payload, (res) => {
      if (res.success) {
        notification.success({
          message: "保存成功",
          description: "保存文章信息成功！",
        });
        let rows = [].concat(this.state.rows);
        rows.splice(0, 0, res.data);
        this.setState({ rows });
        this.handleCancel();
      } else {
        notification.error({
          message: "保存文章出错了",
          description: res.message,
        });
      }
    });
  }
  handleCancel() {
    this.setState({ payload: null });
  }
  payloadChange(payload) {
    this.setState({ payload });
  }

  getAnswerView(arr) {
    if (arr && arr.length > 0) {
      return (
        <div className="solids padding">
          {arr.map((item, index) => {
            return (
              <div className="margin-bottom-sm" key={item.id}>
                <div className="text-grey margin-bottom-sm">
                  {1 + index}: {item.question.title}
                </div>
                <div className="padding-left">{item.value}</div>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  }

  playVideo(att) {
    this.setState({ video: util.getAttUrl(att) });
  }

  export() {
    let params = {
      ...this.props.params,
    };

    let url = ArticleService.getExportUrl(params);
    let a = document.createElement('a')
    a.href = url;
    a.target = '_blank'
    a.click()
  }

  getArticleView(item, inModal) {
    let author = item.author;
    let defautlAvatar = "./images/avatar.png";
    let avatarUrl = author
      ? author.avatar
        ? util.getAttUrl(author.avatar) +
          (author.avatar.type === "oss"
            ? "?x-oss-process=image/resize,w_300"
            : "?thumbnail=true")
        : author.wxs && author.wxs.length > 0
        ? author.wxs[0].avatarUrl
        : defautlAvatar
      : defautlAvatar;
    let atts = item.attachments;
    let imgs = atts.filter((i) => i.contentType.indexOf("image") >= 0);
    let vids = atts.filter((i) => i.contentType.indexOf("video") >= 0);
    return (
      <div className="cu-card bg-white margin-bottom-sm">
        <div className="cu-list menu-avatar">
          <div className="cu-item">
            <div
              className="cu-avatar round lg"
              style={{ backgroundImage: `url(${avatarUrl})` }}
            ></div>
            <div className="content">
              <div>
                {author.name}
                {item.type === 1 ? (
                  <span className="margin-left-sm cu-tag round sm bg-brown">
                    老师
                  </span>
                ) : null}
                {item.isTop ? (
                  <Tag color="red" className="margin-left-sm">
                    置顶
                  </Tag>
                ) : null}
                {item.shareGrade ? (
                  <Tag color="blue" className="margin-left-sm">
                    共享
                  </Tag>
                ) : null}
              </div>
              <div className="text-sm text-gray">
                {util.mom(item.createTime)}
              </div>
            </div>
            {inModal ? null : (
              <div className="action width-auto margin-right">
                <span
                  className="cu-btn line-blue  pointer margin-right-xs"
                  onClick={() => {
                    this.toDetail(item);
                  }}
                >
                  详情
                </span>
                <span
                  className="cu-btn line-red  pointer"
                  onClick={() => {
                    this.toRemove(item);
                  }}
                >
                  删除
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="harticle">
          {item.grade ? (
            <div className="padding-lr">
              <div className="cu-tag radius line-brown">{item.grade.name}</div>
              <div className="cu-tag radius line-brown">
                {item.grade.campus.name}
              </div>
            </div>
          ) : null}
          <div
            className={
              "text-content padding-lr " + (inModal ? "" : "text-ellipsis")
            }
          >
            {item.content}
          </div>
          <div className="grid flex-sub padding-lr   grid-square margin-top">
            {imgs.length > 0 ? (
              <RViewer imageUrls={imgs.map((att) => util.getAttUrl(att))}>
                {imgs.map((att, index) => (
                  <RViewerTrigger index={index} key={att.id}>
                    <div
                      className="bg-img pointer wh-120"
                      style={{
                        backgroundImage: `url(${
                          util.getAttUrl(att) +
                          (att.type === "oss"
                            ? "?x-oss-process=image/resize,w_300"
                            : "?thumbnail=true")
                        })`,
                      }}
                    ></div>
                  </RViewerTrigger>
                ))}
              </RViewer>
            ) : null}
            {vids.length > 0
              ? vids.map((att) => (
                  <div
                    key={att.id}
                    title="点击播放视频"
                    className="wh-120 pointer bg-video "
                    style={{
                      backgroundImage: `url(${
                        util.getAttUrl(att) +
                        (att.type === "oss"
                          ? "?x-oss-process=video/snapshot,t_10000,m_fast"
                          : "?thumbnail=true")
                      })`,
                    }}
                    onClick={() => {
                      this.playVideo(att);
                    }}
                  ></div>
                ))
              : null}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-gray text-sm text-left padding">
            {item.shareCampus ? (
              <span className="cu-tag sm bg-red">全校区</span>
            ) : item.shareGrade ? (
              <span className="cu-tag sm bg-orange">同校区</span>
            ) : null}
            {item.task ? (
              <div className="cu-tag radius sm bg-olive">
                {item.task.category.title} {item.task.title}
              </div>
            ) : null}
            {item.timeCost ? (
              <div className="cu-tag radius sm bg-blue">
                劳动时长：{item.timeCost}
              </div>
            ) : null}
          </div>
          <div className="text-gray text-sm text-right padding">
            <span className="cuIcon-appreciatefill margin-lr-xs ">
              <span className="padding-left-xs">{item.approval.length}</span>
            </span>
          </div>
        </div>
        {this.getAnswerView(item.answers)}
      </div>
    );
  }
  render() {
    let {
      page,
      total,
      size,
      rows,
      loading,
      article,
      payload,
      video,
    } = this.state;

    return (
      <div className="margin">
        {loading ? (
          <div className="padding text-center">
            {" "}
            <Spin />{" "}
          </div>
        ) : rows.length > 0 ? (
          <div>
            <Row gutter={16}>
              {rows.map((a) => (
                <Col lg={12} md={24} key={a.id}>
                  {this.getArticleView(a)}
                </Col>
              ))}
            </Row>
            <div className="text-center margin-top">
              <Pagination
                current={page + 1}
                total={total}
                pageSize={size}
                onChange={(p,size) => {
                  this.load(p - 1,size);
                }}
              />
              <div className="text-grey margin-top-sm">共{total}条</div>
            </div>
          </div>
        ) : (
          <Empty />
        )}

        <Modal
          title="记录详情"
          visible={!!article}
          footer={false}
          onCancel={this.handleCancelDetail}
        >
          {article ? this.getArticleView(article, true) : null}
        </Modal>
        {!!payload ? (
        <Modal
          title="发布文章"
          visible={!!payload}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          
            <ArticleForm item={payload} onChange={this.payloadChange} />
         
        </Modal>):null}
        <Modal
          className="p-0"
          width="800px"
          title={null}
          footer={null}
          visible={!!video}
          onCancel={() => {
            this.setState({ video: null });
          }}
        >
          {video ? (
            <video autoPlay controls src={video} className="w100" />
          ) : null}
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  let me = state.me;
  return { me };
};
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleTable);
