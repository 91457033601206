import React, { Component } from "react";

import { message } from "antd";
import SchoolService from "../services/school";
import util from "../utils/util";
import { Empty } from "antd";
import { Spin } from "antd";

export class ClazzFilter extends Component {
  constructor(props) {
    super();

    this.state = { loading: true, year: null, clazz: null, grades: [] };
    this.renderGrade = this.renderGrade.bind(this);
  }

  componentDidMount() {
    this.loadGrade(this.props.cid);
  }

  static getDerivedStateFromProps(props, state) {
    let cid = props.cid;
    if (cid !== state.cid) {
      return { cid };
    }
    return null;
  }
  componentDidUpdate(pre) {
    let cid = this.props.cid;
    if (cid !== pre.cid) {
      this.loadGrade(cid);
    }
  }

  componentWillUnmount() {
    this.unmount = true;
  }

  loadGrade(cid) {
    this.setState({ cid, loading: true });
    SchoolService.getGrade(cid, (res) => {
      if (!this.unmount) {
        if (res && res.success) {
          let grades = res.data || [];
          this.setState({ grades });
          if (grades.length > 0) {
            let grade = grades[0];
            let year = grade.year;
            let clazzItem = grade.grades.sort((a, b) => a.clazz - b.clazz)[0];
            let clazz = clazzItem.clazz;
            this.triggerClazz(clazzItem);
            this.setState({ year, clazz, loading: false });
          } else {
            this.props.onClick && this.props.onClick(null);
          }
        } else {
          message.error("获取班级数据失败");
        }
      }
    });
  }

  clickGrade(g) {
    let clazz = g.grades.sort((a, b) => a.clazz - b.clazz)[0];
    this.triggerClazz(clazz);
  }
  clickClazz(clazz) {
    this.triggerClazz(clazz);
  }
  triggerClazz(item) {
    let { year, clazz } = item;
    this.setState({ year, clazz });
    this.props.onClick && this.props.onClick(item);
  }

  renderGrade() {
    if (!this.state.year) {
      return null;
    }

    let grades = this.state.grades;
    let arr = grades.filter((g) => g.year === this.state.year);
    if (arr.length === 0) {
      return null;
    }
    let clazzs = arr[0].grades.sort((a, b) => a.clazz - b.clazz);
    return (
      <div className="padding">
        <div className="flex">
          <div
            className="text-right padding-lr text-black"
            style={{ width: "80px" }}
          >
            年级：
          </div>
          <div className="flex-sub">
            {grades.map((g) => {
              return (
                <span
                  onClick={() => {
                    this.clickGrade(g);
                  }}
                  className={
                    "pointer cu-tag " +
                    (this.state.year === g.year ? "bg-brown" : "line-brown")
                  }
                  key={g.year}
                >
                  {util.getGradeName(g)}
                </span>
              );
            })}
          </div>
        </div>
        <div className="flex margin-top-sm">
          <div
            className="text-right padding-lr text-black"
            style={{ width: "80px" }}
          >
            班级：
          </div>
          <div className="flex-sub">
            {clazzs.map((c) => {
              return (
                <span
                  onClick={() => {
                    this.clickClazz(c);
                  }}
                  className={
                    "pointer cu-tag " +
                    (this.state.clazz === c.clazz ? "bg-brown" : "line-brown")
                  }
                  key={c.id}
                >
                  {c.clazz} 班
                </span>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
  render() {
    return this.state.loading ? (
      <div className="padding text-center">
        <Spin />
      </div>
    ) : this.state.grades.length > 0 ? (
      this.renderGrade()
    ) : (
      <div className="padding">
        {" "}
        <Empty description="还没有班级数据哦" />
      </div>
    );
  }
}

export default ClazzFilter;
