import React, { Component } from "react";
import { Breadcrumb,Button,Alert } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import SettingTable, { E_TO_ADD_SETTING } from '../../components/SettingTable';
import emitter from "../../emitter";
export class Config extends Component {
  render() {
    const toAdd=()=>{
      emitter.emit(E_TO_ADD_SETTING);
    };
    return (
      <div>
        <div className="padding bg-white flex justify-between align-center h46">
          <Breadcrumb>
            <Breadcrumb.Item>系统设置</Breadcrumb.Item>
            <Breadcrumb.Item>参数设置</Breadcrumb.Item>
          </Breadcrumb>
          <div>
            <Button type="primary" icon={<PlusOutlined/>}  onClick={toAdd}>新增参数</Button>          
          </div>
        </div>
        <div className="margin bg-white">          
          <Alert message="参数 SYSTEM_MAINTENANCE 设置之后，小程序会进入维护信息显示模式，不能正常使用！" type="warning" showIcon closable className="margin-bottom"/>
          <SettingTable />

        </div>
      </div>
    );
  }
}

export default Config;
