import React, { Component } from "react";
import { connect } from "react-redux";
import { notification, DatePicker, Button } from "antd";
import ReactEcharts from "echarts-for-react";
import SchoolService from "../../services/school";
import Copyright from "../../components/Copyright";
import util from "../../utils/util";
import { timeranges } from "../../utils/cons";

const { RangePicker } = DatePicker;

export class Home extends Component {
  constructor() {
    super();
    let week = timeranges["本月"];
    this.state = {
      campus: [],
      start: week[0],
      end: week[1]
    };
    this.getCampusOption = this.getCampusOption.bind(this);
  }
  componentDidMount() {
    this.load();
  }
  load() {
    let start = this.state.start.format("YYYY-MM-DD HH:mm:ss");
    let end = this.state.end.format("YYYY-MM-DD HH:mm:ss");

    SchoolService.getCampusArticleStatistic(start, end, res => {
      if (res.success) {
        let campus = res.data;
        this.setState({ campus });
      } else {
        notification.error({
          message: "加载校区数据出错",
          description: res.message
        });
      }
    });
  }
  getCampusOption(campus) {
    let { start, end } = this.state;
    let records = campus.records || [];
    let days = [];
    let diff = end.diff(start, "days");
    for (let i = 0; i <= diff; i++) {
      days.push(
        start
          .clone()
          .add(i, "days")
          .format("YYYY-MM-DD")
      );
    }

    let data = days.map(d => {
      let item = util.findItemInArr(records, "date", { date: d });
      if (item) {
        return item.count;
      }
      return 0;
    });
    return {
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: days
      },
      title: {
        text: campus.name,
        subtext: campus.alias,
        left: "left"
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow"
        }
      },
      toolbox: {
        show: true,
        feature: {
          dataView: { title: "数据视图" },
          magicType: {
            type: ["line", "bar"]
          },
          saveAsImage: { title: "保存图片" }
        }
      },
      yAxis: {
        type: "value"
      },
      series: [
        {
          data,
          smooth: true,
          type: "line",
          areaStyle: {}
        }
      ]
    };
  }
  getCampusView() {
    return this.state.campus.map(c => {
      return (
        <div className="margin bg-white padding" key={c.id}>
          <ReactEcharts option={this.getCampusOption(c)} />
        </div>
      );
    });
  }
  render() {
    const onChange = dates => {
      if (dates) {
        let start = dates[0],
          end = dates[1];
        this.setState({ start, end });
      }
    };
    const query = () => {
      this.load();
    };
    return (
      <div>
        <div className="padding margin bg-white flex justify-center">
          <div>
            统计日期：
            <RangePicker
              defaultValue={[this.state.start, this.state.end]}
              ranges={timeranges}
              onChange={onChange}
            />
            <Button type="primary" className="margin-left-sm" onClick={query}>
              查询
            </Button>
          </div>
        </div>
        {this.getCampusView()}
        <Copyright />
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
