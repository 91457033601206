import React, { Component } from "react";
import {
  Empty,
  Input,
  Form,
  Select,
  Checkbox,
  Row,
  Switch,
  Col
} from "antd";
import TaskService from "../services/task";

import util from "../utils/util";
import emitter from "../emitter";
import ColorRadio from "./ColorRadio";

const { TextArea } = Input;
const { Option } = Select;

export const E_TASK_CHANGE = "taskChangeEvent";

export class TaskForm extends Component {
  constructor(props) {
    super();
    this.state = {
      colors: [],
      categories: [],
      payload: Object.assign({}, props.item)
    };
    this.onBgColorChange = this.onBgColorChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.iptChange = this.iptChange.bind(this);
    this.optionalChange = this.optionalChange.bind(this);
  }
  componentDidMount() {
    TaskService.getCategory(res => {
      if (res.success) {
        let categories = res.data;
        this.setState({ categories });
      }
    });
  
  }

  getCategorySelect() {
    let cat = this.state.payload.category;
    let id = cat ? cat.id : "";
    return (
      <Select defaultValue={id} onChange={this.handleCategoryChange}>
        {this.state.categories.map(c => {
          return (
            <Option value={c.id} key={c.id}>
              {c.title}
            </Option>
          );
        })}
      </Select>
    );
  }
  onBgColorChange(value) {
    let payload = util.setValue(this.state.payload,'bgcolor',value);    
    this.triggerChange(payload);
  }
  handleCategoryChange(id) {
    let category = util.findItemInArr(this.state.categories, "id", { id });
    let payload = util.setValue(this.state.payload, "category", category);
    this.triggerChange(payload);
  }
  triggerChange(payload) {
    this.setState({ payload });
    emitter.emit(E_TASK_CHANGE, payload);
  }
  iptChange(e) {
    e.persist();
    let { name, value } = e.target;
    let payload = util.setValue(this.state.payload, name, value);
    
    this.triggerChange(payload);
  }
  optionalChange(value){
    let payload = util.setValue(this.state.payload,'optional',value);
    this.triggerChange(payload);
  }
  getGradeSelect() {
    const gradeChange = values => {
      let payload = util.setValue(
        this.state.payload,
        "grade",
        values.join(",")
      );
      this.triggerChange(payload);
    };
    let arr = ['1', '2', '3', '4', '5', '6'];
    let options = arr.map(value => {
      return {
        label: util.toChineseNum(value) + "年级",
        value
      };
    });
    let defaultValue = this.state.payload.grade
      ? this.state.payload.grade.split(",")
      : [];

    return (
      <Checkbox.Group
        options={options}
        defaultValue={defaultValue}
        onChange={gradeChange}
      />
    );
  }

  render() {
    let payload = this.state.payload;
    let inline = {
      labelCol:{span:8},
      wrapperCol:{span:16},
    }
    return payload ? (
      <Form  layout="vertical">
        <Form.Item label="名称" className="margin-bottom-sm">
          <Input
            defaultValue={payload.title}
            onChange={this.iptChange}
            name="title"
          />
        </Form.Item>
        <Form.Item label="分类" className="margin-bottom-sm">
          {this.getCategorySelect()}
        </Form.Item>
        <Form.Item label="适合年级" className="margin-bottom-sm">
          {this.getGradeSelect()}
        </Form.Item>
        <Row className="inline-form">
          {/* <Col span={12}>
            <Form.Item labelCol={inline.labelCol} wrapperCol={inline.wrapperCol}  label="奖励星" className="margin-bottom-sm">
              <Input
                type="number"
                defaultValue={payload.reward}
                onChange={this.iptChange}
                name="reward"
              />
            </Form.Item>
          </Col> */}
          <Col span={12} className="padding-left">
            <Form.Item  labelCol={inline.labelCol} wrapperCol={inline.wrapperCol} label="是否自选" className="margin-bottom-sm">
              <Switch
                checkedChildren="是"
                unCheckedChildren="否"
                defaultChecked={payload.optional}
                onChange={this.optionalChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="背景色" className="margin-bottom-sm">
          <ColorRadio onChange={this.onBgColorChange} defaultValue={payload.bgcolor}/>
        </Form.Item>
        <Form.Item label="排序值(大值优先)" className="margin-bottom-sm">
          <Input
            type="number"
            defaultValue={payload.sort||1}
            onChange={this.iptChange}
            name="sort"
          />
          </Form.Item>
        <Form.Item label="描述">
          <TextArea
            rows={3}
            defaultValue={payload.description}
            onChange={this.iptChange}
            name="description"
          />
        </Form.Item>
      </Form>
    ) : (
      <Empty />
    );
  }
}

export default TaskForm;
