import React, { useState, useEffect } from "react";
import { Button, Tabs, Radio, message } from "antd";

import TaskStatCampus from "./task/TaskStatCampus";
import TaskStatGrade from "./task/TaskStatGrade";
import TaskStatClass from "./task/TaskStatClass";
import TaskStatStudent from "./task/TaskStatStudent";

export default function TaskStat() {
  const [tab, setTab] = useState("campus");

  const downloader = {
    link: null,
  };

  const onTabChange = (e) => {
    setTab(e.target.value);
  };

  const toExport = () => {
    let link = downloader.link;
    if (link) {
      let a = document.createElement("a");
      a.target = "_blank";
      a.href = link;
      a.click();
    } else {
      message.warn("没有可以下载的内容");
    }
  };

  useEffect(() => {
    downloader.link = null;
  }, [tab]);

  const renderTabBar = () => {
    return (
      <div className="flex-row bg-white padding">
        <div className="tabs flex-1">
          <Radio.Group
            defaultValue="campus"
            buttonStyle="solid"
            onChange={onTabChange}
          >
            <Radio.Button value="campus">校区对比</Radio.Button>
            <Radio.Button value="grade">年级对比</Radio.Button>
            <Radio.Button value="class">班级对比</Radio.Button>
            <Radio.Button value="student">学生对比</Radio.Button>
          </Radio.Group>
        </div>
        <div className="right">
          <Button type="primary" onClick={toExport}>
            导出
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Tabs
      defaultActiveKey="campus"
      renderTabBar={renderTabBar}
      tabBarStyle={{
        paddingLeft: "10px",
        paddingRight: "10px",
        backgroundColor: "#fff",
      }}
    >
      {tab === "campus" ? (
        <TaskStatCampus downloader={downloader} />
      ) : tab === "grade" ? (
        <TaskStatGrade downloader={downloader} />
      ) : tab === "class" ? (
        <TaskStatClass downloader={downloader} />
      ) : tab === "student" ? (
        <TaskStatStudent downloader={downloader} />
      ) : null}
    </Tabs>
  );
}
