import React, { Component } from "react";
import { Breadcrumb, Button } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import QuestionTable, {
   E_TO_ADD_QUESTION
} from "../../components/QuestionTable";
import emitter from "../../emitter";

export class Title extends Component {
  render() {
    const toAdd = () => {
      emitter.emit(E_TO_ADD_QUESTION);
    };
    return (
      <div>
        <div className="h46 padding bg-white flex justify-between align-center">
          <Breadcrumb>
            <Breadcrumb.Item>任务管理</Breadcrumb.Item>
            <Breadcrumb.Item>问题管理</Breadcrumb.Item>
          </Breadcrumb>
          <div>
            <Button type="primary" icon={<PlusOutlined/>}  onClick={toAdd}>
              新增问题
            </Button>
          </div>
        </div>
        <div className="margin bg-white">
          <QuestionTable />
        </div>
      </div>
    );
  }
}

export default Title;
