import React, { Component } from 'react';
import DictService from '../services/dict';

export class Copyright extends Component {
    constructor(){
        super();
        this.state = {
            copyright:[],
            support:[],
        }
    }
    componentDidMount(){
        DictService.getAll(res=>{
            if(res.success){
                let dicts = res.data.rows;
                let copyright = dicts.filter(d=>d.code==='COPYRIGHT').map(d=>d.value);
                let support = dicts.filter(d=>d.code==='SUPPORT').map(d=>d.value);

                this.setState({copyright,support})
            }
        })
    }
    render() {
        return (
            <div className="margin-lg text-center padding-lg">
                <div className="text-grey">版权所有&copy;2020</div>
                {this.state.copyright.map(c=>{
                    return <div className="text-sm text-gray" key={c}>{c}</div>
                })}
                <div className="text-grey margin-top">技术支持</div>
                {this.state.support.map(c=>{
                    return <div className="text-sm text-gray" key={c}>{c}</div>
                })}
            </div>
        );
    }
}

export default Copyright;
