import { configureStore } from "@reduxjs/toolkit";
// import { applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import preloadedState from "./state";
import reducer from "./reducer";

const middlewares = [];

if (process.env.NODE_ENV === `development`) {
  const logger = createLogger({
    // ...options
  });
  middlewares.push(logger);
}


const store = configureStore({
  preloadedState,
  reducer,
  middleware: middlewares
});
export default store;
