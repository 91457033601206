import React, { Component } from "react";
import { Breadcrumb, Button } from "antd";
import Search from "antd/lib/input/Search";
import { PlusOutlined } from "@ant-design/icons";
import UserTable, {
  E_TO_ADD_USER,
  E_TO_RELOAD_USER
} from "../../components/UserTable";
import emitter from "../../emitter";
export class User extends Component {
  constructor(props) {
    super();
    this.state = {
      filter: {
        keyword:''
      }
    };
    this.onSearch = this.onSearch.bind(this);
  }

  onSearch(keyword) {
    let filter = this.state.filter;
    filter.keyword = keyword;
    this.setState({ filter });
    emitter.emit(E_TO_RELOAD_USER);
  }

  render() {
    const toAdd = () => {
      emitter.emit(E_TO_ADD_USER);
    };
    return (
      <div>
        <div className="h46 padding bg-white flex justify-between  align-center">
          <Breadcrumb>
            <Breadcrumb.Item>系统设置</Breadcrumb.Item>
            <Breadcrumb.Item>用户管理</Breadcrumb.Item>
          </Breadcrumb>
          <div className="flex-row">
            <Search
              placeholder="关键字"
              onSearch={this.onSearch}
              style={{ width: 200 }}
            />
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={toAdd}
              className="margin-left-sm"
            >
              新增用户
            </Button>
          </div>
        </div>
        <div className="margin bg-white">
          <UserTable action={true} filter={this.state.filter} />
        </div>
      </div>
    );
  }
}

export default User;
