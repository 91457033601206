import React, { Component } from "react";
import TaskService from "../services/task";
import {notification, message, Table, Button,Modal, Switch } from "antd";
import emitter from "../emitter";
import CategoryForm from './CategoryForm';
import util from '../utils/util';

const {confirm} = Modal;

export const E_TO_ADD_CATEGORY = "toAddCategory";

export class CategoryTable extends Component {
  constructor() {
    super();
    this.state = {
      rows: [],
      payload: null,
      loading:false,
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.payloadChange = this.payloadChange.bind(this);
    this.toAdd = this.toAdd.bind(this);
  }

  componentDidMount() {
    this.load()
    emitter.addListener(E_TO_ADD_CATEGORY, this.toAdd);
  }
  load(){
    this.setState({ loading:true  });
    TaskService.getCategory(res => {
      this.setState({ loading:false  });
      if (res.success) {
        let rows = res.data;
        this.setState({ rows });
      } else {
        message.error("加载任务分类出错：" + res.message);
      }
    });
  }
  toAdd() {
    let payload = { bgcolor: "red" };
    this.setState({ payload });
  }
  toModify(task) {
    // console.log(item);
    let payload = Object.assign({}, task);
    this.setState({ payload });
  }
  toRemove(record, index) {
    let that = this;
    confirm({
      title: "请确认",
      content: "您确定要删除任务分类吗？",
      onOk: () =>{
        TaskService.removeCategory(record.id, res => {
          if (res.success) {
            notification.success({
              message: "操作成功！",
              description: "任务分类删除成功！"
            });
            let rows = [].concat(that.state.rows);
            rows.splice(index, 1);
            that.setState({ rows });
          } else {
            notification.error({
              message: "删除任务分类失败",
              description: res.message
            });
          }
        });
      }
    });
  }

  changeEnable(item,checked){
    let msg = checked ? '启用' :'关闭';
    confirm({
      title: "请确认",
      content: "您确定要"+msg+"此分类吗？",
      onOk: () =>{
        TaskService.changeCategoryEnable(item.id,checked,res=>{
          if(res.success && res.data){
            notification.success({
              message: "操作成功！",
              description: "修改启用状态成功！"
            });
            this.load();

          }else{
            notification.error({
              message: "修改启用状态失败",
              description: res.message
            });
          }
        });        
      }
    })
  }

  getColumns() {
    return [
      {
        title: "序号",
        key: "index",
        className:"text-center",
        render: (text, record, index) => <span>{index + 1}</span>,
        width: "80px"
      },
      { title: "名称", dataIndex: "title" },
      { title: "图标", key: "icon", width: "120px", render: this.getIcon },
      { title: "排序", dataIndex:"sort", width: "80px" ,className:"text-center",},
      { title: "启用", key:"enable", width: "80px" ,className:"text-center",render:(text,record)=>{
        return <Switch checked={record.enable} onChange={(v)=>{
          this.changeEnable(record,v);
        }}></Switch>
      }},
      {
        title: "背景色",
        key: "bgcolor",
        width: "80px",
        render: this.getBgcolor
      },
      {
        title: "操作",
        key: "action",
        width: "150px",
        render: (text, record, index) => {
          return (
            <Button.Group size="small">
              <Button
                onClick={() => {
                  this.toModify(record);
                }}
              >
                修改
              </Button>
              <Button
                type="danger"
                onClick={() => {
                  this.toRemove(record, index);
                }}
              >
                删除
              </Button>
            </Button.Group>
          );
        }
      }
    ];
  }
  getIcon(text, record) {
    return record.icon ? (
      <img src={record.icon} alt="icon" style={{height:'46px'}}/>
    ) : (
      "默认"
    );
  }
  getBgcolor(text, record) {
    return record.bgcolor ? (
      <div className={`cu-tag bg-${record.bgcolor}`}>{record.bgcolor}</div>
    ) : (
      "-"
    );
  }
  handleOk() {
    let that = this;
    let {rows,payload}= this.state;
    if(!payload.title){
      message.warn('请填写分类名称')
      return;
    }
    if(!payload.bgcolor){
      message.warn('请选择分类背景色')
      return;
    }
    confirm({
      title: "请确认",
      content: "您确定要保存分类信息吗？",
      onOk: ()=> {
        TaskService.saveCategory(payload, res => {
          if (res.success) {
            notification.success({
              message: "操作成功！",
              description: "保存任务分类成功！"
            });
            let rs = res.data;
            let index = util.findIdxInArr(rows,'id',rs);
            if(index >= 0){
                rows[index] = rs;
            }else{
                rows.splice(0,0,rs);
            }           
            rows.sort((a,b)=>b.sort-a.sort);
            that.setState({ rows });
            that.handleCancel();
          } else {
            notification.error({
              message: "保存任务分类失败",
              description: res.message
            });
          }
        });
      }
    });
  }
  handleCancel() {
    this.setState({ payload: null });
  }
  payloadChange(payload) {
    this.setState({ payload });
  }
  render() {
    return (
      <div>
        <Table
          bordered={true}
          loading={this.state.loading}
          className="bg-white"
          columns={this.getColumns()}
          dataSource={this.state.rows}
          rowKey={record => record.id}
          pagination={false}
        />
         {this.state.payload ? (
        <Modal
          title="任务分类编辑"
          visible={!!this.state.payload}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
         
            <CategoryForm
              item={this.state.payload}
              onChange={this.payloadChange}
            />
        </Modal>):null}

      </div>
    );
  }
}

export default CategoryTable;
