const OSS_URI = "https://ldtransport.cqrm.com";

const moment = require('moment');


const formatTime = date => {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const second = date.getSeconds()

  return [year, month, day].map(formatNumber).join('/') + ' ' + [hour, minute, second].map(formatNumber).join(':')
}

const formatNumber = n => {
  n = n.toString()
  return n[1] ? n : '0' + n
}

const getToday = () => {
  const date = new Date();
  const year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()

  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;

  return `${year}-${month}-${day}`;
}

const toChineseNum = num => {

  let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']; //changeNum[0] = "零"
  let unit = ["", "十", "百", "千", "万"];
  num = parseInt(num);
  let getWan = (temp) => {
    let strArr = temp.toString().split("").reverse();
    let newNum = "";
    for (var i = 0; i < strArr.length; i++) {
      newNum = (i === 0 && strArr[i] === 0 ? "" : (i > 0 && strArr[i] === 0 && strArr[i - 1] === 0 ? "" : changeNum[strArr[i]] + (strArr[i] === 0 ? unit[0] : unit[i]))) + newNum;
    }
    return newNum;
  }
  let overWan = Math.floor(num / 10000);
  let noWan = num % 10000;
  if (noWan.toString().length < 4) noWan = "0" + noWan;
  let rs = overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
  // console.log(rs);
  return rs;
}

const random = n => {
  return Math.floor(Math.random() * n);
}
const findIdxInArr = (arr, name, item) => {
  let rs = -1;
  for (let i = 0; i < arr.length; i++) {
    let tmp = arr[i];
    if (tmp[name] === item[name]) {
      rs = i;
      break;
    }
  }
  return rs;
}

const findItemInArr = (arr, name, item) => {
  let rs = null;
  let idx = findIdxInArr(arr, name, item);
  if (idx >= 0) {
    rs = arr[idx];
  }
  return rs;
}

const fixTen = n => {
  return n < 10 ? '0' + n.toString() : n.toString();
}

const formatDate = (date, option) => {
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();

  month = fixTen(month);
  day = fixTen(day);

  var hour = function () {
    return fixTen(date.getHours());
  }

  var minute = function () {
    return fixTen(date.getMinutes());
  }

  var second = function () {
    return fixTen(date.getSeconds());
  }

  //获取 年月日
  if (option === 'YYYY-MM-DD') return " " + year + "-" + month + "-" + day;

  //获取年月
  if (option === 'YYYY-MM') return " " + year + "-" + month;

  //获取年
  if (option === 'YYYY') return " " + year;

  //获取月
  if (option === 'MM') return " " + month;

  //获取日
  if (option === 'DD') return " " + day;

  //获取昨天
  if (option === 'yesterday') return " " + day - 1;

  //获取时分秒
  if (option === 'hh-mm-ss') return " " + hour() + ":" + minute() + ":" + second();

  //获取时分
  if (option === 'hh-mm') return " " + hour() + ":" + minute();

  //获取分秒
  if (option === 'mm-ss') return minute() + ":" + second();

  //获取分
  if (option === 'mm') return minute();

  //获取秒
  if (option === 'ss') return second();

  //默认时分秒年月日
  return year + '-' + month + '-' + day + ' ' + hour() + ':' + minute() + ":" + second();
}

const time = () => new Date().getTime();

const uuid = function () {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  var uuid = s.join("");
  return uuid

}

const getGradeName = g => {
  let age = "";
  let now = new Date();
  let year = now.getFullYear();
  let month = now.getMonth() + 1;

  let left = year - g.year;
  if (left <= 6) {
    // 九月份之后年级加一
    if(month >= 9){
      left += 1
    }

    age = left <= 1 ? toChineseNum(1) : toChineseNum(left);
    age = `（${age}年级）`;
  }
  return `${g.year}级${age}`
}

const setValue = (obj, name, value) => {
  obj[name] = value;
  return Object.assign({}, obj);
}

const getLastDays = num => {
  let rs = [];
  for (let i = num; i >= 0; i--) {
    rs.push(moment().subtract(i, "days").format("YYYY-MM-DD"));
  }
  return rs;
}
const mom = (time, format) => {
  return moment(time).format(format || 'YYYY-MM-DD HH:mm:ss');
}

const findNode = (tree, key) => {
  let rs = null;
  for (let i = 0; i < tree.length; i++) {
    let item = tree[i];
    if (item.key === key) {
      rs = item;
      break;
    } else if (item.children && item.children.length > 0) {
      rs = findNode(item.children, key);
      if (rs) break;
    }
  }
  return rs;
}

const treeMap = (tree, handler) => {
  let arr = [];
  for (let i = 0; i < tree.length; i++) {
    let item = tree[i];
    if (item.children && item.children.length > 0) {
      item.children = treeMap(item.children,handler)
    }
    
    item = handler(item);
    
    arr.push(item);
  }
  return arr;
}

const getFileExtention = filename =>{
  return filename.split('.').pop();
}

const getAttUrl = att => {
  if(att.type === 'oss'){
    return OSS_URI + att.path;
  }
  // let arr = att.name.split('.');
  // let ext = arr.length > 1? '.'+arr.pop():'';
  let ext = "";
  return "./api/attach/file/" + att.id + ext;
}

const hasAuth = (item,arr)=>{
  return findIdxInArr(arr,'code',item) >= 0;
}

module.exports = {
  formatTime,
  getToday,
  toChineseNum,
  random,
  findIdxInArr,
  findItemInArr,
  formatDate,
  fixTen,
  time,
  uuid,
  getGradeName,
  setValue,
  getLastDays,
  mom,
  findNode,
  treeMap,
  getFileExtention,
  getAttUrl,
  hasAuth,
}
