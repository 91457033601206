import React, { Component } from "react";
import util from "../utils/util";
import { Form, Input, Empty, Button } from "antd";

export class TitleForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.item,
      iconUrl: null
    };
    this.iptChange = this.iptChange.bind(this);
    this.toChooseImg = this.toChooseImg.bind(this);
  }
  componentDidMount() {
    if (this.props.item && this.props.item.icon) {
      let iconUrl = this.getAttUrl(this.props.item.icon);
      this.setState({ iconUrl });
    }
  }
  getAttUrl(att) {
    return util.getAttUrl(att);
  }
  iptChange(e) {
    e.persist();
    let { name, value } = e.target;
    let payload = util.setValue(this.state.payload, name, value);

    this.triggerChange(payload);
  }

  triggerChange(payload) {
    this.setState({ payload });
    this.props.onChange(payload);
  }
  toChooseImg() {
    let input = this.input;
    if (!input) {
      input = document.createElement("input");
      input.setAttribute("type", "file");
      // input.setAttribute('multiple','multiple');
      input.setAttribute("accept", "image/*");
      input.addEventListener("change", e => {
        // console.log(input);
        let files = input.files;

        let file = files[0];
        let payload = this.state.payload;
        payload.file = file;
        this.triggerChange(payload);

        const reader = new FileReader();
        // eslint-disable-next-line
        reader.addEventListener("load", e2 => {
          // console.log(e2);
          let target = e2.currentTarget;
          let iconUrl = target.result;
          this.setState({ iconUrl });
        });
        reader.readAsDataURL(file);
      });
      this.input = input;
    }
    input.click();
  }
  render() {
    let payload = this.state.payload;

    return payload ? (
      <Form layout="vertical">
        <Form.Item label="称号" className="margin-bottom-sm">
          <Input
            defaultValue={payload.name}
            onChange={this.iptChange}
            name="name"
          />
        </Form.Item>
        <Form.Item label="图标" className="margin-bottom-sm">
          {this.state.iconUrl ? (
            <img
              src={this.state.iconUrl}
              alt="icon"
              className="margin-right"
              style={{ height: "46px" }}
            />
          ) : null}
          <Button type="primary" onClick={this.toChooseImg}>
            选择图片
          </Button>
        </Form.Item>
      

        <Form.Item label="要求（奖章数量）" className="margin-bottom-sm">
          <Input
            type="number"
            defaultValue={payload.times || 1}
            onChange={this.iptChange}
            name="times"
          />
        </Form.Item>
      </Form>
    ) : (
      <Empty />
    );
  }
}

export default TitleForm;
