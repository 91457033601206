import React, { Component } from "react";
import { Modal, Table, message, Input } from "antd";
import SchoolService from "../services/school";

const { Search } = Input;
class TeacherChooser extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: false,
      rows: [],
      size: 20,
      total: 0,
      page: 0,
      keyword: null,
    };

    this.getColumns = this.getColumns.bind(this);
  }

  componentDidMount() {
    this.loadTeacher(0);
  }

  loadTeacher(page) {
    page = page || 0;
    this.setState({ loading: true });
    let params = Object.assign(
      {
        cid: this.props.campus.id,
        size: this.state.size,
        page,
        sort: "user.name",
      },
      this.props.filter
    );
    if(this.state.keyword){
      params.keyword = this.state.keyword;
    }
    SchoolService.getTeacher(params, (res) => {
      this.setState({ loading: false });
      if (!this.unmount) {
        if (res && res.success) {
          let { total, rows } = res.data;
          this.setState({ total, rows, page });
        } else {
          message.error("加载教师数据出错！");
        }
      }
    });
  }

  getColumns() {
    let pre = this.state.size * this.state.page;
    const getWx = (text, record) => {
      return record.wx ? (
        <div className="flex align-center">
          <img
            src={record.wx.avatarUrl}
            alt="avatar"
            style={{ height: "46px" }}
          />{" "}
          <span className="margin-left-sm">{record.wx.nickName}</span>
        </div>
      ) : (
        "-"
      );
    };
    return [
      {
        title: "序号",
        key: "index",
        className: "text-center",
        render: (text, record, index) => <span>{pre + index + 1}</span>,
        width: "80px",
      },
      {
        title: "姓名",
        key: "name",
        render: (text, record) => <span>{record.user.name}</span>,
      },
      {
        title: "电话",
        key: "phone",
        width: "120px",
        render: (text, record) => <span>{record.user.phone}</span>,
      },
      {
        title: "激活码",
        dataIndex: "code",
        width: "120px",
      },
      {
        title: "学科",
        dataIndex: "course",
        width: "120px",
      },
      { title: "绑定微信", key: "wx", width: "180px", render: getWx },
    ];
  }

  render() {
    const rowSelection = {
      type: "radio",
      onChange: (selectedRowKeys, selectedRows) => {
        let item = selectedRows[0];
        this.setState({ item });
      },
    };
    const handleOk = () => {
      let item = this.state.item;
      if (!item) {
        message.warn("请先选择教师");
        return;
      }
      this.props.onOk && this.props.onOk(item);
    };

    // 查询
    const onSearch = (keyword) => {
      // console.log(value);
      this.setState({keyword},()=>{
        this.loadTeacher(0)
      })
    };

    let showTotal = (total) => `共${total}条记录`;
    let pagination = {
      current: this.state.page + 1,
      pageSize: this.state.size,
      total: this.state.total,
      onChange: (page,size) => {
        this.setState({size},()=>{
          this.loadTeacher(page - 1);
        })
      },
      showTotal,
    };
    let name = this.props.campus.alias || this.props.campus.name;

    return (
      <Modal
        title={"请选择[" + name + "]教师"}
        visible={!!this.props.visible}
        width="800px"
        className="p-0"
        onOk={handleOk}
        onCancel={this.props.onCancel}
      >
        <Search
          placeholder="请输入姓名或激活码查询"
          allowClear
          enterButton="查询"
          size="large"
          onSearch={onSearch}
        />
        <Table
          rowSelection={rowSelection}
          loading={this.state.loading}
          bordered={true}
          className="bg-white"
          columns={this.getColumns()}
          dataSource={this.state.rows}
          rowKey={(record) => record.id}
          pagination={pagination}
        />
      </Modal>
    );
  }
}

export default TeacherChooser;
