import React, { Component } from "react";
import { Form, Input, Empty, Switch } from "antd";

export class UserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enabled: this.props.item.enabled,
    };
    this.form = React.createRef();
    this.enabledChange = this.enabledChange.bind(this)
  }

  triggerChange(payload) {

    this.props.onChange(payload);
  }

  componentDidUpdate(pre) {
    let item = this.props.item;
    if (item !== pre.item) {
      this.setState({ enabled:item.enabled  });
      this.form.current.setFieldsValue(item);
    }
  }

  enabledChange(enabled) {
    this.setState({ enabled });
    this.triggerChange({ ...this.props.item, ...this.form.current.getFieldsValue() ,enabled});   
  }

  render() {
    let {enabled} = this.state;
    let payload = this.props.item;

    const onFieldsChange = () => {
      this.triggerChange({ ...payload, ...this.form.current.getFieldsValue() ,enabled});
    };

    return payload ? (
      <Form ref={this.form} layout="vertical" initialValues={payload} onFieldsChange={onFieldsChange}>
        <Form.Item label="姓名" className="margin-bottom-sm " name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item label="用户名" className="margin-bottom-sm" name="username" rules={[{ required: true }]}>
          <Input disabled={!!payload.id} autoComplete="new-password"/>
        </Form.Item>
        <Form.Item label="电话" className="margin-bottom-sm" name="phone" >
          <Input autoComplete="new-password"/>
        </Form.Item>

        <Form.Item label="启用" className="margin-bottom-sm" >
          <Switch
            checkedChildren="是"
            unCheckedChildren="否"
            checked={enabled}
            onChange={this.enabledChange}
          />
        </Form.Item>

        <Form.Item label="密码" name="password" >
          <Input type="password" autoComplete="new-password"/>
        </Form.Item>
      </Form>
    ) : (
      <Empty />
    );
  }
}

export default UserForm;
