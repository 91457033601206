import React, { Component } from "react";
import {
  Breadcrumb,
  Modal,
  Menu,
  message,
  notification,
  Button,
  Dropdown,
  Spin
} from "antd";
import { DownOutlined, PlusOutlined, MoreOutlined } from "@ant-design/icons";
import util from "../../utils/util";
import emitter from "../../emitter";
import SchoolService from "../../services/school";
// import Loading from "../../components/Loading";
import TeacherTable, {
  E_TO_ADD_TEACHER,
  E_TO_RELOAD_TEACHER
} from "../../components/TeacherTable";
import Search from "antd/lib/input/Search";

const { confirm } = Modal;

export class Teacher extends Component {
  constructor() {
    super();
    this.state = {
      campus: [],
      crtCampus: null,
      importing: false,
      filter: {
        keyword: ""
      }
    };

    // bind this.
    this.onCampus = this.onCampus.bind(this);
    this.moreAction = this.moreAction.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  componentDidMount() {
    SchoolService.getCampus({ size: 999 }, (res) => {
      if (res && res.success) {
        let campus = res.data.rows;

        let crtCampus = campus[0];
        this.setState({ campus, crtCampus });
        emitter.emit(E_TO_RELOAD_TEACHER);
      } else {
        message.error("加载校区数据失败");
      }
    });
  }

  onCampus(e) {
    let id = e.key;
    let crtCampus =
      id === "all" ? null : util.findItemInArr(this.state.campus, "id", { id });
    this.setState({ crtCampus });
    emitter.emit(E_TO_RELOAD_TEACHER);
  }

  getCampusOverlay() {
    return (
      <Menu
        onClick={this.onCampus}
        selectedKeys={
          this.state.crtCampus ? [this.state.crtCampus.id] : ["all"]
        }
      >
        <Menu.Item key="all"> 全部校区 </Menu.Item>
        {this.state.campus.map((item) => {
          return (
            <Menu.Item key={item.id}>
              {item.name}({item.alias})
            </Menu.Item>
          );
        })}
      </Menu>
    );
  }

  moreAction(e) {
    let key = e.key;
    if (!this.state.crtCampus) {
      message.warn("请先选择校区");
      return;
    }
    if (key === "template") {
      this.toDownloadTemplate();
    } else if (key === "import") {
      this.toImport();
    } else if (key === "export") {
      this.toExport();
    }
  }
  toDownloadTemplate() {
    var link = document.createElement("a");
    link.setAttribute("href", "./api/teacher/template");
    link.setAttribute("download", "教师数据导入模板.xls");
    document.body.appendChild(link); // Required for FF
    link.click();
  }

  toImport() {
    let that = this;
    let campus = this.state.crtCampus;
    if (campus) {
      let input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", ".xls");
      input.addEventListener("change", (e) => {
        // console.log(input);
        let files = input.files;
        if (files.length > 0) {
          let file = files[0];
          let ext = util.getFileExtention(file.name);
          if (ext !== "xls" && ext !== "XLS") {
            message.warn("请选择.xls格式的文件");
            return;
          }
          confirm({
            title: "请确认",
            content:
              "您确定要将老师数据导入到【" +
              campus.name +
              "(" +
              campus.alias +
              ")】校区吗？",
            onOk: () => {
              that.setState({ importing: true });
              SchoolService.importTeacher(campus.id, file, (res) => {
                that.setState({ importing: false });
                if (res.success) {
                  notification.success({
                    message: "操作成功！",
                    description: "导入教师数据成功!"
                  });
                  emitter.emit(E_TO_RELOAD_TEACHER);
                } else {
                  notification.error({
                    message: "导入教师数据失败",
                    description: res.message
                  });
                }
              });
            }
          });
        } else {
          message.warn("请选择导入csv文件");
        }
      });
      input.click();
    } else {
      message.warn("请先选择校区！");
    }
  }
  toExport() {
    let campus = this.state.crtCampus;
    if (campus) {
      var link = document.createElement("a");
      link.setAttribute("href", "./api/teacher/export?cid=" + campus.id);
      link.setAttribute("download", campus.name + "教师数据.xls");
      document.body.appendChild(link); // Required for FF

      link.click();
    } else {
      message.warn("请先选择校区");
    }
  }

  onSearch(keyword) {
    let filter = this.state.filter;
    filter.keyword = keyword;
    this.setState({ filter });
    emitter.emit(E_TO_RELOAD_TEACHER);
  }

  render() {
    const toAdd = () => {
      if (!this.state.crtCampus) {
        message.warn("请先选择校区");
        return;
      }
      emitter.emit(E_TO_ADD_TEACHER);
    };
    const moreActionView = () => {
      return (
        <Menu onClick={this.moreAction}>
          <Menu.Item key="template">下载导入模板</Menu.Item>
          <Menu.Item key="import">批量导入</Menu.Item>
          <Menu.Item key="export">导出全部</Menu.Item>
        </Menu>
      );
    };
    return (
      <div>
        <div className="h46 padding-lr bg-white flex justify-between align-center shadow">
          <Breadcrumb>
            <Breadcrumb.Item>信息管理</Breadcrumb.Item>
            <Breadcrumb.Item>教师管理</Breadcrumb.Item>
          </Breadcrumb>

          <div className="flex-row">
            <Search
              placeholder="关键字"
              onSearch={this.onSearch}
              style={{ width: 200 }}
            />
            <Dropdown overlay={this.getCampusOverlay()}>
              <Button className="margin-left-sm">
                {this.state.crtCampus ? this.state.crtCampus.name : "全部校区"}
                <DownOutlined />
              </Button>
            </Dropdown>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              className="margin-left-sm"
              onClick={toAdd}
            >
              新增教师
            </Button>

            <Dropdown overlay={moreActionView()} trigger={["click"]}>
              <Button icon={<MoreOutlined />} className="margin-left-sm" />
            </Dropdown>
          </div>
        </div>

        <div className="bgwhite margin">
          <TeacherTable
            cid={this.state.crtCampus ? this.state.crtCampus.id : null}
            filter={this.state.filter}
          />
        </div>

        <Modal
          title={null}
          footer={null}
          visible={this.state.importing}
          maskClosable={false}
          closable={false}
        >
          <div className="padding text-center">
            <Spin size="large" tip="导入中..." />
          </div>
        </Modal>
      </div>
    );
  }
}

export default Teacher;
