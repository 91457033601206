export default {
  site: {
    title: "人民小学劳动教育评价系统",
    copyright: "Copyright © "+new Date().getFullYear()+" 重庆市人民小学"
  },
  mePreload:false, // 是否已经执行了加载
  me: null,
  dicts:[],
  auth: [],
};
