import React, { Component } from "react";
import {
  Button,
  Table,
  notification,
  Modal,
  Empty,
  Switch,
  message,
} from "antd";
import UserService from "../services/user";
import UserForm from "./UserForm";
import emitter from "../emitter";
import util from "../utils/util";

const { confirm } = Modal;

export const E_TO_ADD_USER = "toAddUser";
export const E_TO_RELOAD_USER = "toReloadUser";

export class UserTable extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      page: 0,
      rows: [],
      size: 20,
      payload: null,
    };
    this.toAdd = this.toAdd.bind(this);
    this.toReload = this.toReload.bind(this);
    this.toModify = this.toModify.bind(this);
    this.toRemove = this.toRemove.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.payloadChange = this.payloadChange.bind(this);
  }
  componentDidMount() {
    this.load(0);
    emitter.addListener(E_TO_ADD_USER, this.toAdd);
    emitter.addListener(E_TO_RELOAD_USER, this.toReload);
  }
  componentWillUnmount() {
    emitter.removeListener(E_TO_ADD_USER, this.toAdd);
    emitter.removeListener(E_TO_RELOAD_USER, this.toReload);
  }
  toReload() {
    this.load(0);
  }
  load(page) {
    let params = { page, size: this.state.size };
    if (this.props.filter) {
      params = Object.assign(params, this.props.filter);
    }
    this.setState({ loading: true });
    UserService.getUser(params, (res) => {
      this.setState({ loading: false });
      if (res.success) {
        let rows = res.data.rows || [];
        let total = res.data.total || 0;
        this.setState({ page, rows, total });
      } else {
        notification.error({
          message: "加载用户列表失败",
          description: res.message,
        });
      }
    });
  }
  getColumns() {
    let arr = [
      {
        title: "序号",
        key: "index",
        render: (text, record, index) => (
          <span>{this.state.page * this.state.size + index + 1}</span>
        ),
        className: "text-center",
        width: "80px",
      },
      { title: "姓名", dataIndex: "name" },
      { title: "电话", dataIndex: "phone", width: "120px" },
      { title: "用户名", dataIndex: "username", width: "120px" },
      {
        title: "角色",
        key: "role",
        width: "120px",
        render: (text, record) => (
          <span>{record.role ? record.role.name : "-"}</span>
        ),
      },
      {
        title: "启用",
        key: "enable",
        width: "80px",
        render: (text, record) => <Switch checked={record.enabled} disabled />,
      },
    ];
    if (this.props.action) {
      arr.push({
        title: "操作",
        key: "action",
        width: "150px",
        render: (text, record, index) => {
          return (
            <Button.Group size="small">
              <Button onClick={() => this.toModify(record)}>修改</Button>
              <Button
                type="danger"
                onClick={() => this.toRemove(record, index)}
              >
                删除
              </Button>
            </Button.Group>
          );
        },
      });
    }
    return arr;
  }
  toModify(item) {
    // console.log(item);
    let payload = Object.assign({}, item);
    this.setState({ payload });
  }
  toRemove(record, index) {
    let that = this;
    confirm({
      title: "请确认",
      content: "您确定要删除用户吗？",
      onOk: () => {
        UserService.removeUser(record.id, (res) => {
          if (res.success) {
            notification.success({
              message: "操作成功！",
              description: "用户删除成功！",
            });
            let rows = [].concat(that.state.rows);
            rows.splice(index, 1);
            that.setState({ rows });
          } else {
            notification.error({
              message: "删除用户失败",
              description: res.message,
            });
          }
        });
      },
    });
  }

  toAdd() {
    if (!this.unmount) {
      let payload = { passed: true, enabled: true,name:null,username:null,phone:null,password:null, };
      this.setState({ payload });
    }
  }
  handleCancel() {
    this.setState({ payload: null });
  }
  payloadChange(payload) {
    this.setState({ payload });
  }
  handleOk() {
    let that = this;
    let { payload } = this.state;
    if (!payload.name) {
      message.warn("请输入姓名");
    }
    if (!payload.username) {
      message.warn("请输入用户名");
    }
    if (!payload.id && !payload.password) {
      message.warn("请为新用户创建密码");
    }
    confirm({
      title: "请确认",
      content: "您确定要保存用户信息吗？",
      onOk: () => {
        let func = payload.id ? UserService.update : UserService.save;
        func.call(that, payload, (res) => {
          if (res.success) {
            notification.success({
              message: "操作成功！",
              description: "保存用户成功！",
            });
            let rs = res.data;
            let rows = [].concat(that.state.rows);
            let index = util.findIdxInArr(rows, "id", rs);
            if (index >= 0) {
              rows[index] = rs;
            } else {
              rows.splice(0, 0, rs);
            }
            that.setState({ rows });
            that.handleCancel();
          } else {
            notification.error({
              message: "保存用户失败",
              description: res.message,
            });
          }
        });
      },
    });
  }
  render() {
    let that = this;
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        that.props.onCheck && that.props.onCheck(selectedRows);
      },
    };
    let showTotal = (total) => `共${total}条记录`;
    const getPagination = () => ({
      current: this.state.page + 1,
      pageSize: this.state.size,
      total: this.state.total,
      onChange: (p,size) => {
        this.setState({size},()=>{
          this.load(p - 1)
        })
      },
      showTotal,
    });
    return (
      <div>
        <Table
          loading={this.state.loading}
          bordered={true}
          className="bg-white"
          columns={this.getColumns()}
          dataSource={this.state.rows}
          rowKey={(record) => record.id}
          pagination={getPagination()}
          rowSelection={this.props.checkable ? rowSelection : null}
        />
        {this.props.action ? (
          <Modal
            title="用户信息编辑"
            visible={!!this.state.payload}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            {this.state.payload ? (
              <UserForm
                item={this.state.payload}
                onChange={this.payloadChange}
              />
            ) : (
              <Empty />
            )}
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default UserTable;
