import React, { Component } from "react";
import { Modal, Table, notification, Button, Pagination } from "antd";
import UserService from "../services/user";
import UserChooser from "./UserChooser";

const { confirm } = Modal;

export class RoleUserModal extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: false,
      choose: false,
      page: 0,
      size: 20,
      total: 0,
      rows: []
    };
    this.getColumns = this.getColumns.bind(this);
    this.toRemove = this.toRemove.bind(this);
    this.chooseUserOk = this.chooseUserOk.bind(this);
  }
  componentDidMount() {
    this.load(0);
  }
  load(page) {
    this.setState({ loading: true });
    UserService.getUser(
      {
        roleId: this.props.item.id,
        page,
        size: this.state.size
      },
      res => {
        this.setState({ loading: false });
        if (res.success) {
          let rows = res.data.rows || [];
          let total = res.data.total || 0;

          this.setState({ rows, total, page });
        } else {
          notification.error({
            message: "加载角色用户失败",
            description: res.message
          });
        }
      }
    );
  }
  toRemove(record) {
    let that = this;
    confirm({
      title: "请确认",
      content: "您确定要移除此用户与角色关联吗？",
      onOk: ()=> {
        UserService.removeUserRole(record.id, res => {
          if (res.success) {
            notification.success({
              message: "操作成功！",
              description: "移除角色用户成功！"
            });
            that.load(that.state.page);
          } else {
            notification.error({
              message: "移除角色用户失败",
              description: res.message
            });
          }
        });
      }
    });
  }
  getColumns() {
    return [
      {
        title: "姓名",
        key: "name",
        render: (text, record) => (
          <div>
            <span>{record.name}</span>{" "}
            <small className="margin-left">{record.useranme}</small>
          </div>
        )
      },
      { title: "电话", dataIndex: "phone", width: "120px" },
      {
        title: "操作",
        key: "action",
        width: "150px",
        render: (text, record) => {
          return (
            <Button.Group size="small">
              <Button type="danger" onClick={() => this.toRemove(record)}>
                移除
              </Button>
            </Button.Group>
          );
        }
      }
    ];
  }
  chooseUserOk(users) {
    this.setState({ choose: false });
    let that = this;
    confirm({
      title: "请确认",
      content: `您确定要添加选择的${users.length}位用户到此角色吗？`,
      onOk: ()=> {
        UserService.saveRoleUser(
          that.props.item.id,
          users.map(u => u.id).join(","),
          res => {
            if (res.success) {
              notification.success({
                message: "操作成功！",
                description: "添加角色用户成功！"
              });
              that.load(0);
            } else {
              notification.error({
                message: "添加角色用户失败",
                description: res.message
              });
            }
          }
        );
      }
    });
  }
  render() {
    const getFooter = () => {
      return (
        <div className="flex justify-between">
          <Button
            type="primary"
            onClick={() => this.setState({ choose: true })}
          >
            添加用户
          </Button>
          <Pagination
            current={this.state.page +1}
            onChange={p => this.load(p - 1)}
            total={this.state.total}
            size={this.state.size}
          />
        </div>
      );
    };

    return (
      <div>
        <Modal
          width="600px"
          title="角色用户信息"
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          footer={null}
          className="p-0"
        >
          <Table
            className="bg-white"
            rowKey={record => record.id}
            loading={this.state.loading}
            bordered={true}
            columns={this.getColumns()}
            dataSource={this.state.rows}
            pagination={false}
            footer={getFooter}
          ></Table>
        </Modal>
        <UserChooser
          visible={this.state.choose}
          onOk={this.chooseUserOk}
          onCancel={() => this.setState({ choose: false })}
        />
      </div>
    );
  }
}

export default RoleUserModal;
