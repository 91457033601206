import React, { Component } from "react";
import {
  Breadcrumb,
  Button,
  Table,
  message,
  notification,
  Modal,
  Form,
  Menu,
  Dropdown,
  Input,
  Select,
} from "antd";
import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import util from "../../utils/util";
import SchoolService from "../../services/school";
import TeacherChooser from "../../components/TeacherChooser";

const { confirm } = Modal;
const { Option } = Select;

export class Grade extends Component {
  constructor() {
    super();
    this.state = {
      payload:null,
      loading: true,
      campus: [],
      crtCampus: null,
      grades: [],
    };
    this.iptChange = this.iptChange.bind(this);
    this.actionRender = this.actionRender.bind(this);
    this.onCampus = this.onCampus.bind(this);
    this.toAdd = this.toAdd.bind(this);
    this.toModify = this.toModify.bind(this);
    this.toRemove = this.toRemove.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOk = this.handleOk.bind(this);
  }

  componentDidMount() {
    this.loadCampus();
  }

  loadCampus() {
    SchoolService.getCampus({ size: 999 }, (res) => {
      if (res && res.success) {
        let campus = res.data.rows;

        let crtCampus = campus[0];
        this.setState({ campus, crtCampus });
        this.getGrades(crtCampus.id);
      } else {
        message.error("加载校区数据失败");
      }
    });
  }

  getGrades(id) {
    SchoolService.getGrade(id, (res) => {
      let grades = [];
      if (res.success) {
        grades = res.data;
      }
      this.setState({ grades, loading: false });
    });
  }

  getColumns() {
    return [
      { title: "班级", dataIndex: "name" },
      {
        title: "班主任",
        key: "teacher",
        width: "120px",
        render: (text, record) =>
          record.teacher ? <span>{record.teacher.name}</span> : "-",
      },
      {
        title: "操作",
        key: "action",
        width: "150px",
        render: this.actionRender,
      },
    ];
  }
  actionRender(text, record, index) {
    return record.id ? (
      <Button.Group size="small">
        <Button
          onClick={() => {
            this.toModify(record);
          }}
        >
          修改
        </Button>
        <Button
          type="danger"
          onClick={() => {
            this.toRemove(record, index);
          }}
        >
          删除
        </Button>
      </Button.Group>
    ) : (
      "-"
    );
  }

  onCampus(e) {
    let id = e.key;
    let crtCampus = util.findItemInArr(this.state.campus, "id", { id });
    this.setState({ crtCampus });
    this.getGrades(id);
  }

  getCampusOverlay() {
    return (
      <Menu onClick={this.onCampus}>
        {this.state.campus.map((item) => {
          return <Menu.Item key={item.id}>{item.name}</Menu.Item>;
        })}
      </Menu>
    );
  }
  toAdd() {
    let payload = {
      year: new Date().getFullYear(),
      clazz: 1,
      campus: { id: this.state.crtCampus.id },
    };
    this.setState({ payload });
  }
  iptChange(e) {
    e.persist && e.persist();
    let { name, value } = e.target;
    let payload = util.setValue(this.state.payload, name, value);
    this.setState({payload})
  }
  toModify(item) {
    // console.log(item);
    let payload = Object.assign({}, item);
    this.setState({ payload });
  }
  toRemove(record, index) {
    let that = this;
    confirm({
      title: "请确认",
      content: "您确定要删除班级吗？",
      onOk: ()=> {
        SchoolService.removeGrade(record.id, (res) => {
          if (res.success) {
            notification.success({
              message: "操作成功！",
              description: "班级删除成功！",
            });
            that.getGrades(that.state.crtCampus.id);
          } else {
            notification.error({
              message: "删除班级失败",
              description: res.message,
            });
          }
        });
      },
    });
  }
  handleOk() {
    let that = this;
    let { payload } = this.state;
    confirm({
      title: "请确认",
      content: "您确定要保存班级信息吗？",
      onOk: ()=> {
        SchoolService.saveGrade(payload, (res) => {
          if (res.success) {
            notification.success({
              message: "操作成功！",
              description: "保存班级班级成功！",
            });
            that.getGrades(that.state.crtCampus.id);
            that.handleCancel();
          } else {
            notification.error({
              message: "保存班级班级失败",
              description: res.message,
            });
          }
        });
      },
    });
  }
  handleCancel() {
    this.setState({ payload: null });
  }

  getRows() {
    let tree = [];
    this.state.grades.forEach((g) => {
      g.key = util.uuid();
      g.name = g.year + "级";
      g.children = g.grades
        .sort((a, b) => a.clazz - b.clazz)
        .map((c) => {
          c.key = c.id;
          c.name = c.clazz + "班";
          return c;
        });
      tree.push(g);
    });
    return tree;
  }

  render() {
    let payload = this.state.payload;
    const onChooseTeacherOk = (teacher) => {
      payload.teacher = teacher;
      this.setState({ payload, teacherModal: false });
    };
    return (
      <div>
        <div className="h46 padding-lr bg-white flex justify-between align-center shadow">
          <Breadcrumb>
            <Breadcrumb.Item>信息管理</Breadcrumb.Item>
            <Breadcrumb.Item>班级管理</Breadcrumb.Item>
          </Breadcrumb>
          <div>
            <Dropdown overlay={this.getCampusOverlay()}>
              <Button>
                {this.state.crtCampus ? this.state.crtCampus.name : "请选择"}
                <DownOutlined />
              </Button>
            </Dropdown>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              className="margin-left-sm"
              onClick={this.toAdd}
            >
              新增班级
            </Button>
          </div>
        </div>
        <div className="margin bg-white">
          <Table
            bordered={true}
            loading={this.state.loading}
            pagination={false}
            dataSource={this.getRows()}
            columns={this.getColumns()}
            rowKey={(record) => record.key}
          />
        </div>
        {payload ? (
        <Modal
          title="班级编辑"
          visible={!!this.state.payload}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          
            <Form layout="vertical">
              <Form.Item label="所属校区">
                <Select
                  defaultValue={
                    payload.campus ? payload.campus.id : this.state.crtCampus.id
                  }
                  onChange={this.onPayloadCampusChange}
                >
                  {this.state.campus.map((c) => (
                    <Option value={c.id} key={c.id}>
                      {c.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="年级">
                <Input
                  defaultValue={payload.year}
                  type="number"
                  onChange={this.iptChange}
                  name="year"
                />
              </Form.Item>
              <Form.Item label="班级">
                <Input
                  defaultValue={payload.clazz}
                  type="number"
                  onChange={this.iptChange}
                  name="clazz"
                />
              </Form.Item>
              <Form.Item label="班主任">
                <Input
                  value={
                    payload.teacher
                      ? payload.teacher.name
                        ? payload.teacher.name
                        : payload.teacher.user.name
                      : ""
                  }
                  disabled
                  addonAfter={
                    <span
                      className="pointer"
                      onClick={() => {
                        this.setState({ teacherModal: true });
                      }}
                    >
                      选择
                    </span>
                  }
                />
              </Form.Item>
            </Form>          
        </Modal>) : null}
        {this.state.teacherModal && this.state.crtCampus ? (
          <TeacherChooser
            visible={this.state.teacherModal}
            campus={this.state.crtCampus}
            onOk={onChooseTeacherOk}
            onCancel={() => {
              this.setState({ teacherModal: false });
            }}
          />
        ) : null}
      </div>
    );
  }
}

export default Grade;
