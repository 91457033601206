import React, { Component } from "react";
import { Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Layout, Menu, Modal, message } from "antd";
import {
  PoweroffOutlined,
  HomeOutlined,
  CalculatorOutlined,
  ContactsOutlined,
  SettingOutlined,
  WindowsOutlined,
} from "@ant-design/icons";
import { setMe, setDict, setAuth } from "../store/reducer";

import Loading from "../components/Loading";
import Delayed from "../components/Delayed";

import Home from "./home";
import Task from "./task";
import Info from "./info";
import Setting from "./setting";
import Workstation from "./workstation";
import UserService from "../services/user";
import DictService from "../services/dict";
import { hasAuth } from "../utils/util"; 
import * as keycloak  from '../utils/keycloak'


const { Header, Content } = Layout;
const { confirm } = Modal;

const menus = [
  {
    title: "首页",
    path: "home",
    icon: <HomeOutlined />,
    component: Home,
    code: "HOME",
  },
  {
    title: "统计分析",
    path: "workstation",
    icon: <WindowsOutlined />,
    component: Workstation,
    code: "STAT",
  },
  {
    title: "劳动任务",
    path: "task",
    icon: <CalculatorOutlined />,
    component: Task,
    code: "TASK",
  },
  {
    title: "信息管理",
    path: "info",
    icon: <ContactsOutlined />,
    component: Info,
    code: "INFO",
  },
  {
    title: "系统设置",
    path: "setting",
    icon: <SettingOutlined />,
    component: Setting,
    code: "SETTING",
  },
];

export class Index extends Component {
  constructor() {
    super();
    this.state = {
      me: null,
      login: false,
      unknown: false,
    };
    this.toLogout = this.toLogout.bind(this);
  }

  componentDidMount() {

  
    UserService.getMe( async (res) => {
      if (res && res.success && res.data) {
        let me = res.data;
        this.props.setMe(me);
        this.setState({ me });

        UserService.getMeAuth((res2) => {
          if (res2 && res2.success) {
            this.props.setAuth(res2.data || []);
          }
        });
      } else {
        console.log(this.props)
        const params = new URLSearchParams(window.location.search)        

        if(!window.location.search){
          this.setState({ login: true });
        }else{
          const code = params.get('code')         
          const profile= await keycloak.getAccountByCode(code)
          if(profile){
            this.loginKeycloak(profile.id,profile.username)
          }   
        }
       
      }
    });
    this.loadDicts();
  }
  // 通过keycloak返回的信息进行登录
  loginKeycloak(id,username){
    UserService.loginKeycloak(id,username,kres=>{
      if(kres.data){
        UserService.getMe((res) => {
          if (res && res.success && res.data) {
            let me = res.data;
            this.props.setMe(me);
            this.setState({ me });
    
            UserService.getMeAuth((res2) => {
              if (res2 && res2.success) {
                this.props.setAuth(res2.data || []);
              }
            });
          }
        })
      }else{
        this.setState({ unknown: true });
      }
    })
  }
  loadDicts() {
    DictService.getAll((res) => {
      if (res.success) {
        let arr = res.data.rows || [];
        this.props.setDict && this.props.setDict(arr);
      }
    });
  }

  toLogout() {
    // let history = this.props.history;
    confirm({
      title: "请确认?",
      content: "您确定要退出系统吗？",
      onOk: () => {
        UserService.logout((res) => {
          if (res && res.success) {
            UserService.setAuth("");
            message.success("退出成功！");
            // history.push("/login");
            // window.location.href= OPENID_URL
            keycloak.logout()
          } else {
            message.error("退出失败，请稍后再试");
          }
        });
      },
    });
  }

  getMenus() {
    let { pathname } = this.props.location;
    let { url } = this.props.match;
    let { auth } = this.props;

    let vMenu = menus.filter((i) => hasAuth(i, auth));

    let arr = vMenu.filter((m) => {
      return pathname.indexOf(m.path) >= 0;
    });
    let key = arr.length > 0 ? arr[0].path : "";
    //console.log(this.props);

    return (
      <Menu
        theme="dark"
        selectedKeys={[key]}
        mode="horizontal"
        className="h64"
      >
        {vMenu.map((item) => (
          <Menu.Item key={item.path}>
            <Link to={`${url}/${item.path}`}>
              {item.icon} {item.title}
            </Link>
          </Menu.Item>
        ))}
        <Menu.Item
          key="logout"
          className="margin-left-lg"
          onClick={this.toLogout}
        >
          <PoweroffOutlined className="text-red" style={{ marginRight: "0" }} />
        </Menu.Item>
      </Menu>
    );
  }

  getRoutes() {
    let { match, auth } = this.props;
    let { path } = match;
    let vMenu = menus.filter((i) => hasAuth(i, auth));

    return vMenu.length > 0 ? (
      <Switch>
        <Redirect exact from={path} to={`${path}/${vMenu[0].path}`} />

        {vMenu.map((item) => (
          <Route path={`${path}/${item.path}`} key={item.path}>
            <item.component />
          </Route>
        ))}
      </Switch>
    ) : (
      <div className="padding text-center">您当前无权访问</div>
    );
  }

  render() {
    if (this.state.me) {
      return (
        <Layout className="h100">
          <Header className="flex justify-between align-center">
            
              <div className="flex align-center">
                <img
                  style={{ height: "32px" }}
                  src="./images/homework.svg"
                  className="margin-right"
                  alt="logo"
                />
                <h1 className="text-white dpib margin-0 text-lg">
                  {this.props.site.title}
                </h1>
              </div>
              <div style={{marginRight:'-50px'}}> 
                <Delayed waitBeforeShow={500}>
                  {this.getMenus()} 
                </Delayed>
              </div>
        
          </Header>
          <Content className="content-with-header">{this.getRoutes()}</Content>
        </Layout>
      );
    } else if (this.state.login) {
      // return <Redirect to="/login?status=403" />;
      // https://oauth.cqrm.com/realms/master/protocol/openid-connect/auth?response_type=code&scope=openid&client_id=ldmz-dev&redirect_uri=http%3A%2F%2Flocalhost%3A9141
      keycloak.login()
    } else if (this.state.unknown){
      // keycloak中返回的用户不是系统用户
      return <div style={{padding:'120px'}}>
        <div className="padding text-center">您当前无权访问</div>
      </div>
    }

    return <Loading />;
  }
}
const mapStateToProps = (state) => {
  let site = state.site;
  let me = state.me;
  let auth = state.auth;
  return { site, me, auth };
};
const mapDispatchToProps = (dispatch) => ({
  setMe: (me) => dispatch(setMe(me)),
  setAuth: (arr) => dispatch(setAuth(arr)),
  setDict: (arr) => dispatch(setDict(arr)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
