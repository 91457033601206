import React, { Component } from "react";
import SettingService from "../services/setting";
import { Radio } from "antd";

export class ColorRadio extends Component {
  constructor() {
    super();
    this.state = {
      colors: []
    };
  }

  componentDidMount() {
    SettingService.getByCode("COLORS", res => {
      if (res.success && res.data) {
        let json = res.data.value;
        let colors = JSON.parse(json);
        this.setState({ colors });
      }
    });
  }

  render() {
    const onChange = e => {
      let color = e.target.value;
      this.props.onChange(color);
    };
    return (
      <Radio.Group className="grid col-3" onChange={onChange} defaultValue={this.props.defaultValue}>
        {this.state.colors.map(c => {
          return (
            <Radio value={c.name} key={c.name}>
              <span
                style={{ width: "100px" }}
                className={`cu-tag bg-${c.name} margin-bottom-xs`}
              >
                {c.name}
              </span>
            </Radio>
          );
        })}
      </Radio.Group>
    );
  }
}

export default ColorRadio;
