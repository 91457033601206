import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu,  Layout, message } from "antd";
import { Switch, Route, Link, withRouter, Redirect } from "react-router-dom";
import { setAuth} from "../store/reducer";
import { hasAuth } from "../utils/util";

const { Content, Sider } = Layout;

export class Main extends Component {
  constructor(props) {
      super();
  }

  getMenus() {
    let {menus,location,match,auth} = this.props;
    let { pathname } = location;
    let { url } = match;
    let vMenu = menus.filter(i=>hasAuth(i,auth));
    let arr = vMenu.filter(m => {
      return pathname.indexOf(m.path) >= 0;
    });
    let key = arr.length > 0 ? `${url}/${arr[0].path}` : "";

    if(vMenu.length === 0){
      message.warn('您当前没有分配访问权限！');
    }


    return (
      <Menu selectedKeys={[key]} mode="inline" theme="dark">
        {vMenu.map(m => {
          return (
            <Menu.Item key={`${url}/${m.path}`}>
              <Link to={`${url}/${m.path}`}>
                {m.icon}
                <span>{m.title}</span>
              </Link>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  }

  getRoutes() {
    let {menus, match,auth}= this.props;
    let { path } = match;
    let vMenu = menus.filter(i=>hasAuth(i,auth));
    return vMenu.length > 0 ? (
      <Switch>
        <Redirect exact from={path} to={`${path}/${vMenu[0].path}`} />
        {vMenu.map(item => (
          <Route path={`${path}/${item.path}`} key={`${path}/${item.path}`}>
            <item.component />
          </Route>
        ))}
      </Switch>
    ) : null;
  }

  render() {
    return (
      <Layout className="h100">
        <Sider collapsible defaultCollapsed={this.props.collapsed === true ? true : false}>{this.getMenus()}</Sider>
        <Content className="ofa">{this.getRoutes()}</Content>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  let auth = state.auth;
  return {  auth };
};
const mapDispatchToProps = (dispatch) => ({
  setAuth: (arr) => dispatch(setAuth(arr)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
