import React, { Component } from "react";
import { Breadcrumb, Button } from "antd";
import { PlusOutlined,CaretUpOutlined,FilterOutlined } from '@ant-design/icons';
import TaskTable,{E_TO_ADD_TASK} from "../../components/TaskTable";
import TaskService from "../../services/task";
import util from '../../utils/util'
import emitter from "../../emitter";

export class TaskManage extends Component {
  constructor() {
    super();
    this.state = {
      categories: [],
      category: null,
      filter: null,
      filterShow: true,
      grade:null,
    };
    this.toggleFilter = this.toggleFilter.bind(this);
  }
  componentDidMount() {
    TaskService.getCategory(res => {
      if (res.success) {
        let categories = res.data;
        this.setState({ categories });
      }
    });
  }

  clickCategory(category) {
    let cid = category ? category.id : null;
    let filter = this.state.filter||{};
    filter.cid = cid;
    this.setState({ category, filter });
  }
  toggleFilter() {
    let filterShow = !this.state.filterShow;
    this.setState({ filterShow });
  }
  getFilter() {
    return (
      <div className="padding bg-brown light">
        <div className="flex">
          <div
            className="text-right padding-lr text-black"
            style={{ width: "100px" }}
          >
            分类：
          </div>
          <div className="flex-sub">
            <span
              onClick={() => {
                this.clickCategory(null);
              }}
              className={
                "pointer cu-tag " +
                (this.state.category === null ? "bg-brown" : "line-brown")
              }
              key="all"
            >
              全部
            </span>
            {this.getCategory()}
          </div>
        </div>
        {this.getGrade()}
      </div>
    );
  }
  getGrade(){
    
    return <div className="flex margin-top-sm">
        <div
            className="text-right padding-lr text-black"
            style={{ width: "100px" }}
          >
           适合年级：
          </div>
          <div className="flex-sub">
            <span
              onClick={() => {
                this.clickGrade(null);
              }}
              className={
                "pointer cu-tag " +
                (this.state.grade === null ? "bg-brown" : "line-brown")
              }
              key="all"
            >
              全部
            </span>
            {[1,2,3,4,5,6].map(c=>{
              return   <span
              onClick={() => {
                this.clickGrade(c);
              }}
              className={
                "pointer cu-tag " +
                (this.state.grade === c ? "bg-brown" : "line-brown")
              }
              key={c+''}
            >{util.toChineseNum(c)}年级</span>
            })}
          </div>
    </div>
  }
  clickGrade(grade){
    let filter = this.state.filter ||{};
    filter.grade = grade;
    this.setState({grade: grade,filter});
  }
  getCategory() {
    return this.state.categories.map(g => {
      return (
        <span
          onClick={() => {
            this.clickCategory(g);
          }}
          className={
            "pointer cu-tag " +
            (this.state.category === g ? "bg-brown" : "line-brown")
          }
          key={g.id}
        >
          {g.title}
        </span>
      );
    });
  }

  render() {
    const toAdd = ()=>{
      emitter.emit(E_TO_ADD_TASK);
    }
    return (
      <div>
        <div className="h46 padding bg-white flex justify-between align-center">
          <Breadcrumb>
            <Breadcrumb.Item>任务管理</Breadcrumb.Item>
            <Breadcrumb.Item>任务管理</Breadcrumb.Item>
          </Breadcrumb>
          <div>
            <Button type="primary" icon={<PlusOutlined/>}  onClick={toAdd}>新增任务</Button>
            <Button
              className="margin-left-sm"
              icon={this.state.filterShow ? <CaretUpOutlined/> : <FilterOutlined/>}
              theme="outlined"
              onClick={this.toggleFilter}
            ></Button>
          </div>
        </div>
        {this.state.filterShow ? this.getFilter() : null}
        <div className="margin bg-white">
          <TaskTable filter={this.state.filter} />
        </div>
      </div>
    );
  }
}



export default TaskManage;
