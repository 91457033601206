import Service from './service';

/**
 * 学校相关服务接口
 */
class SchoolService extends Service {

    // campus 
    // campus 
    // campus 

    static getCampus(params,callback){
        let url = './api/campus?';
        url += Object.keys(params).map(key=>{
            return  `${key}=${params[key]}`;
        }).join('&');
        super.get(url,callback);
    }

    static getCampusById(id,callback){
        let url = `./api/campus/${id}`;      
        super.get(url,callback);
    }
    static saveCampus(payload,callback){
        let url = `./api/campus`;      
        super.post(url,payload,callback);
    }
    static removeCampus(id,callback){
        let url = `./api/campus/${id}`;      
        super.delete(url,callback);
    }
    static getMyCampus(callback){
        let url = `./api/campus/my`;      
        super.get(url,callback);
    }
    static getCampusGradeStatistic(id,start,end,callback){
        let url = `./api/campus/${id}/grade/statistic?start=${start}&end=${end}`;
        super.get(url,callback);
    }

    
    // grade
    // grade
    // grade

    static getGrade(cid,callback){
        let url = `./api/campus/${cid}/grade`;      
        super.get(url,callback);
    }
    static getGradeById(id,callback){
        let url = `./api/grade/${id}`;      
        return super.get(url,callback);
    }
    static getCampusArticleStatistic(start,end,callback){
        let url = `./api/campus/statistic/article?start=${start}&end=${end}`;      
        super.get(url,callback);
    }

    static saveGrade(payload,callback){
        let url = `./api/grade`;      
        super.post(url,payload,callback);
    }
    static removeGrade(id,callback){
        let url = `./api/grade/${id}`;      
        super.delete(url,callback);
    }
    static getMyGrades(callback){
        let url = `./api/grade/my`;      
        super.get(url,callback);
    }
    static getGradeStatistic(id,callback){
        let url = `./api/grade/${id}/statistic`;      
        super.get(url,callback);
    }

    // teacher
    // teacher
    // teacher
    
    static getTeacher(params,callback){
        let url = './api/teacher?';
        url += Object.keys(params).map(key=>{
            return  `${key}=${params[key]}`;
        }).join('&');
        super.get(url,callback);
    }
    static saveTeacher(payload,callback){
        let url = `./api/teacher`;      
        super.post(url,payload,callback);
    }
    static removeTeacher(id,callback){
        let url = `./api/teacher/${id}`;      
        super.delete(url,callback);
    }
    static importTeacher(cid,file,callback){
        let url = `./api/teacher/import`;      
        let data = new FormData();
        data.set('cid',cid);
        data.set('file',file);
        super.postForm(url,data,callback);
    }
    static getMyTeacher(callback){
        let url = `./api/teacher/me`;      
        super.get(url,callback);
    }

    // student
    // student
    // student

    static getStudent(params,callback){
        let url = './api/student?';
        url += Object.keys(params).map(key=>{
            return  `${key}=${params[key]}`;
        }).join('&');
        return super.get(url,callback);
    }
    static saveStudent(payload,callback){
        let url = `./api/student`;      
        super.post(url,payload,callback);
    }
    static removeStudent(id,callback){
        let url = `./api/student/${id}`;      
        super.delete(url,callback);
    }    
    static unbindWx(id,callback){
        let url = `./api/student/${id}/unbind`;      
        super.delete(url,callback);
    }
    static importStudent(cid,file,callback){
        let url = `./api/student/import`;      
        let data = new FormData();
        data.set('cid',cid);
        data.set('file',file);
        super.postForm(url,data,callback);
    }



}

export default SchoolService;