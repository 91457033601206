import Axios from "axios";
import { OPENID_URL } from "../utils/keycloak";

export const axios = Axios.create();
const CancelToken = Axios.CancelToken;

const catchError =  error => {
  if ( error.response &&  (401 === error.response.status || 403 === error.response.status) ) {
    let status = error.response.status;
    //如果提示401，没有授权, 403 无法访问
    // var url = window.location.href.split("#")[0] + "#/login?status=" + status;
    // window.location.href = url;
    // window.location.reload();
    // window.location.href= OPENID_URL
  } else {
    return Promise.reject(error);
  }
};

//配置Axios
axios.interceptors.response.use(
  response => response,
  catchError
);

/**
 * 所有服务基类
 */
class Service {
  /**
   * 获取URL的HEADER值
   * @param {*} url
   * @param {*} key
   * @param {*} callback
   */
  static head(url, key, callback) {
    axios.head(url).then(res => {
      let val = res.headers[key];
      callback && callback(val);
    }).catch(Service.handleError);
  }

  /**
   * HTTP GET
   * @param {*} url  URL
   * @param {*} callback 回调函数
   */
  static get(url, callback, options) {
    const source = CancelToken.source()
    axios
      .get(url, Object.assign({
        cancelToken: source.token
      },options))
      // success
      .then(res => {
        callback && callback(res ? res.data : null);
      }).catch(Service.handleError);

      return source;
  }
  // 下载二进制内容
  static getBlob(url, callback) {
    const source = CancelToken.source()
    axios({
      url,
      method: "GET",
      responseType: "blob" // important
    },{
      cancelToken: source.token
    }).then(response => {
      callback(new Blob([response.data]));
    }).catch(Service.handleError);

    return source;
  }
  /**
   * HTTP DELETE
   * @param {*} url  URL
   * @param {*} callback 回调函数
   */
  static delete(url, callback) {
    axios.delete(url).then(res => {
      callback && callback(res.data);
    }).catch(Service.handleError);
  }

  /**
   * HTTP POST
   * @param {*} url      URL
   * @param {*} payload  post body
   * @param {*} callback 回调函数
   */
  static post(url, payload, callback) {
    let config = { headers: { "Content-Type": "application/json" } };
    Service.postWithConfig(url, payload, config, callback);
  }
  static postForm(url, formdata, callback, onUploadProgress) {
    let config = { headers: { "Content-Type": "multipart/form-data" } };
    if (onUploadProgress) {
      config = Object.assign(config, { onUploadProgress });
    }
    Service.postWithConfig(url, formdata, config, callback);
  }
  static postFormEncode(url, formdata, callback) {
    let config = {
      headers: { "Content-Type": "application/x-www-form-urlencoded" }
    };
    Service.postWithConfig(url, formdata, config, callback);
  }
  static postWithConfig(url, formdata, config, callback) {
    axios
      .post(url, formdata, config || {})
      .then(res => {
        callback && callback(res.data);
      })
      .catch(Service.handleError);
  }
  static postPromise(url, payload) {
    return axios.post(url, payload);
  }
  static handleError(response) {
    window.console.log(response);
    catchError({response});
  }

  /**
   * HTTP PUT
   * @param {*} url       URL
   * @param {*} payload  JSON 实体
   * @param {*} callback 回调函数
   */
  static put(url, payload, callback) {
    axios.put(url, payload).then(res => {
      callback && callback(res.data);
    }).catch(Service.handleError);
  }
  // HTTP PUT FORM
  static putForm(url, params, callback) {
    let config = {
      headers: { "Content-Type": "application/x-www-form-urlencoded" }
    };
    axios
      .put(url, params, config)
      .then(res => {
        callback && callback(res.data);
      })
      .catch(Service.handleError);
  }

  /**
   * HTTP 上传文件
   * @param url URL
   * @param data 文件
   * @param callback 回调函数
   */
  static upload(url, data, callback) {
    // axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    axios
      .post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(res => {
        callback(res.data);
      }).catch(Service.handleError);
  }

  /**
   * 设置全局授权token
   * @param {*} token
   */
  static setAuth(token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    window.localStorage.token = token;
    // Service.cookie(COOKIE_REMEMBER_ME, token);
  }

  /**
   *写入或者读取cookie
   *
   * @static
   * @param {*} key
   * @param {*} val
   * @memberof Service
   */
  static cookie(key, val) {
    if (val) {
      Service.setCookie(key, val, 2);
    } else {
      return Service.getCookie(key);
    }
  }

  //读Cookie
  static getCookie(objName) {
    //获取指定名称的cookie的值
    var arrStr = document.cookie.split("; ");
    for (var i = 0; i < arrStr.length; i++) {
      var temp = arrStr[i].split("=");
      //unescape
      if (temp[0] === objName) return temp[1];
    }
    return "";
  }

  //设置cookie的值
  static setCookie(cname, cvalue, exdays) {
    var d = new Date();

    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toGMTString();
    //escape
    document.cookie = cname + "=" + escape(cvalue) + "; " + expires + ";path=/";
  }
}

export default Service;
