import Service from "./service";

const CACHE = {};

/**
 * 设置相关服务接口
 */
class DictService extends Service {
  static getAll(callback) {
    let key = "dict";
    let res = CACHE[key];
    if (res) {
      callback(res);
    } else {
      let url = "./api/dict?size=999";
      super.get(url, res => {
        CACHE[key] = res;
        callback(res);
      });
    }
  }
  static remove(id, callback) {
    let url = `./api/dict/${id}`;
    super.delete(url, callback);
  }
  static save(payload, callback) {
    let url = `./api/dict`;
    super.post(url,payload, callback);
  }
}

export default DictService;
