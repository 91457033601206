import React, { Component } from "react";
import { Modal, Button, notification, message, Breadcrumb } from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import AuthTree, { E_TO_RELOAD_AUTH } from "./AuthTree";
import AuthForm from "./AuthForm";
import emitter from "../emitter";
import UserService from "../services/user";

const { confirm } = Modal;

export class AuthTable extends Component {
  constructor() {
    super();
    this.state = {
      payload: null,
      crtAuth: null
    };
    this.toAdd = this.toAdd.bind(this);
    this.toModify = this.toModify.bind(this);
    this.toRemove = this.toRemove.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.payloadChange = this.payloadChange.bind(this);
    this.selectAuth = this.selectAuth.bind(this);
  }

  selectAuth(item) {
    this.setState({ crtAuth: item });
  }
  toModify() {
    // console.log(item);
    let payload = Object.assign({}, this.state.crtAuth);
    this.setState({ payload });
  }
  toRemove() {
    let that = this;
    confirm({
      title: "请确认",
      content: "您确定要删除权限吗？",
      onOk: ()=> {
        UserService.removeAuth(that.state.crtAuth.id, res => {
          if (res.success) {
            notification.success({
              message: "操作成功！",
              description: "权限删除成功！"
            });
            emitter.emit(E_TO_RELOAD_AUTH);
          } else {
            notification.error({
              message: "删除权限失败",
              description: res.message
            });
          }
        });
      }
    });
  }
  toAdd() {
    if (!this.unmount) {
      let parent = this.state.crtAuth;
      let payload = {
        parent,
        code: parent ? parent.code+"_" : null,
        name:null,
        sort:10,
      };
      this.setState({ payload });
    }
  }

  handleCancel() {
    this.setState({ payload: null });
  }
  payloadChange(payload) {
    this.setState({ payload });
  }
  handleOk() {
    let that = this;
    let { payload } = this.state;
    if (!payload.code) {
      message.warn("请输入权限标识");
    }
    if (!payload.name) {
      message.warn("请输入权限名称");
    }
    confirm({
      title: "请确认",
      content: "您确定要保存权限信息吗？",
      onOk: ()=> {
        UserService.saveAuth(payload, res => {
          if (res.success) {
            that.handleCancel();
            notification.success({
              message: "操作成功！",
              description: "保存权限成功！"
            });
            emitter.emit(E_TO_RELOAD_AUTH);
          } else {
            notification.error({
              message: "保存权限失败",
              description: res.message
            });
          }
        });
      }
    });
  }
  render() {
    return (
      <div>
        <div className="h46 padding bg-white flex justify-between  align-center">
          <Breadcrumb>
            <Breadcrumb.Item>权限管理</Breadcrumb.Item>
          </Breadcrumb>
          <Button.Group>
            <Button
              type="primary"
              danger
              icon={<DeleteOutlined />}
              disabled={!this.state.crtAuth}
              onClick={this.toRemove}
            ></Button>
            <Button
              icon={<EditOutlined />}
              onClick={this.toModify}
              disabled={!this.state.crtAuth}
            ></Button>
            <Button type="primary" icon={<PlusOutlined />} onClick={this.toAdd}>
              新增权限
            </Button>
          </Button.Group>
        </div>
        <div className="bg-white padding solids-top">
          <AuthTree onSelect={this.selectAuth} />
          {this.state.payload ? (
          <Modal
            title="权限信息编辑"
            visible={!!this.state.payload}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            
              <AuthForm
                item={this.state.payload}
                onChange={this.payloadChange}
              />
          
          </Modal>):null}
        </div>
      </div>
    );
  }
}

export default AuthTable;
