import React, { Component } from "react";
import { Modal, Input } from "antd";
import UserTable,{E_TO_RELOAD_USER} from "./UserTable";
import { message } from "antd";
import emitter from "../emitter";

const { Search } = Input;

export class UserChooser extends Component {
  constructor(props) {
    super();
    this.state = {
        users :[],
        filter:null,
    }
    this.handleOk = this.handleOk.bind(this);
    this.onCheck = this.onCheck.bind(this);
  }
  onCheck(users){
      this.setState({users})
  }
  handleOk(){
      if(this.state.users.length === 0){
          message.warn('请至少勾选一位用户');
          return ;
      }
      this.props.onOk && this.props.onOk(this.state.users);
  }
  toSearch(keyword){
      let filter = {keyword}
      this.setState({filter});
      setTimeout(() => {
          emitter.emit(E_TO_RELOAD_USER);
      }, 100);
  }
  render() {
    const getTitle = () => {
      return (
        <div>
          <span className="margin-right">选择用户信息</span>
          <Search
            placeholder="姓名或手机号"
            onSearch={value => this.toSearch(value)}
            style={{ width: 200 }}
          />
        </div>
      );
    };
    return (
      <Modal
        width="800px"
        title={getTitle()}
        visible={this.props.visible}
        onOk={this.handleOk}
        onCancel={this.props.onCancel}
        className="p-0"
      >
          <UserTable checkable={true} onCheck={this.onCheck} filter={this.state.filter}/>
      </Modal>
    );
  }
}

export default UserChooser;
