import React, { Component } from "react";
import {
  Breadcrumb,
  Button,
  Table,
  message,
  notification,
  Modal,
} from "antd";
import { PlusOutlined } from '@ant-design/icons';
import util from "../../utils/util";
import CampusForm from "../../components/CampusForm";
import SchoolService from "../../services/school";

const { confirm } = Modal;

export class Campus extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      rows: []
    };
    this.toAdd = this.toAdd.bind(this);
    this.toModify = this.toModify.bind(this);
    this.toRemove = this.toRemove.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.payloadChange = this.payloadChange.bind(this);
  }

  componentDidMount() {
    SchoolService.getCampus({ size: 999 }, res => {
      if (res && res.success) {
        let rows = res.data.rows || [];
        this.setState({ rows });
      } else {
        message.error("加载校区数据失败");
      }
      this.setState({ loading: false });
    });
  }

  getColumns() {
    return [
      { title: "校区名称", dataIndex: "name" },
      { title: "简称", dataIndex: "alias", width: "250px" },
      { title: "学生人数", dataIndex: "count", width: "120px" },
      {
        title: "操作",
        key: "action",
        width: "150px",
        render: (text, record, index) => {
          return (
            <Button.Group size="small">
              <Button
                onClick={() => {
                  this.toModify(record);
                }}
              >
                修改
              </Button>
              <Button
                type="danger"
                onClick={() => {
                  this.toRemove(record, index);
                }}
              >
                删除
              </Button>
            </Button.Group>
          );
        }
      }
    ];
  }

  toAdd() {
    let payload = {  };
    this.setState({ payload });
  }
  toModify(task) {
    // console.log(item);
    let payload = Object.assign({}, task);
    this.setState({ payload });
  }
  toRemove(record, index) {
    let that = this;
    confirm({
      title: "请确认",
      content: "您确定要删除校区吗？",
      onOk: ()=> {
        SchoolService.removeCampus(record.id, res => {
          if (res.success) {
            notification.success({
              message: "操作成功！",
              description: "校区删除成功！"
            });
            let rows = [].concat(that.state.rows);
            rows.splice(index, 1);
            that.setState({ rows });
          } else {
            notification.error({
              message: "删除校区失败",
              description: res.message
            });
          }
        });
      }
    });
  }
  handleOk() {
    let that = this;
    let { payload } = this.state;
    if (!payload.name) {
      message.warn("请填写校区名称");
      return;
    }
    confirm({
      title: "请确认",
      content: "您确定要保存校区信息吗？",
      onOk: ()=> {
        SchoolService.saveCampus(payload, res => {
          if (res.success) {
            notification.success({
              message: "操作成功！",
              description: "保存校区校区成功！"
            });
            let rs = res.data;
            let rows = [].concat(that.state.rows);
            let index = util.findIdxInArr(rows, "id", rs);
            if (index >= 0) {
              rows[index] = rs;
            } else {
              rows.splice(0, 0, rs);
            }
            that.setState({ rows });
            that.handleCancel();
          } else {
            notification.error({
              message: "保存校区校区失败",
              description: res.message
            });
          }
        });
      }
    });
  }
  handleCancel() {
    this.setState({ payload: null });
  }
  payloadChange(payload) {
    this.setState({ payload });
  }

  render() {

    return (
      <div>
        <div className="h46 padding-lr bg-white flex justify-between align-center shadow">
          <Breadcrumb>
            <Breadcrumb.Item>信息管理</Breadcrumb.Item>
            <Breadcrumb.Item>校区管理</Breadcrumb.Item>
          </Breadcrumb>
          <div>
            <Button type="primary" icon={<PlusOutlined/>}  onClick={this.toAdd}>新增校区</Button>          
          </div>
        </div>
        <div className="margin bg-white">
          <Table
            bordered={true}
            loading={this.state.loading}
            pagination={false}
            dataSource={this.state.rows}
            columns={this.getColumns()}
            rowKey={record => record.id}
          />
        </div>
        {this.state.payload ?
        <Modal
          title="校区编辑"
          visible={true}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >          
            <CampusForm
              item={this.state.payload}
              onChange={this.payloadChange}
            />          
        </Modal> : null}
      </div>
    );
  }
}

export default Campus;
