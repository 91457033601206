import React, { Component, useState, useEffect } from "react";
import {
  Breadcrumb,
  Button,
  message,
  // Input,
  Form,
  Select,
  Input,
  Checkbox,
  DatePicker,
  Pagination
} from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import ArticleTable, {
  E_TO_ADD_ARTICLE,
  E_TO_RELOAD_ARTICLE
} from "../../components/ArticleTable";
// import Loading from "../../components/Loading";

import emitter from "../../emitter";
import { findItemInArr } from "../../utils/util";
import SchoolService from "../../services/school";
import { timeranges } from "../../utils/cons";
import TaskService from "./../../services/task";

const { Option } = Select;
// const { Search } = Input;
const { RangePicker } = DatePicker;

export class Article extends Component {
  constructor() {
    super();
    this.state = {
      campus: [],
      crtCampus: null,
      showSearch: false,
      params: {},
      pagi: { total: 0, size: 1, page: 0 }
    };
  }
  componentDidMount() {}

  reload() {
    setTimeout(() => {
      emitter.emit(E_TO_RELOAD_ARTICLE);
    }, 10);
  }

  render() {
    let { params, showSearch, pagi } = this.state;

    let { page, total, size } = pagi;

    const toAdd = () => {
      emitter.emit(E_TO_ADD_ARTICLE);
    };
    // const toSearch = (value) => {
    //   this.setState({ keyword: value });

    //   this.reload();
    // };

    const toggleSearch = () => {
      this.setState({ showSearch: !showSearch });
    };

    const doSearch = (params) => {
      this.setState({ params });
      this.reload();
    };

    const onPage = (pagi) => {
      this.setState({ pagi });
    };

    const load = (page,size) => {
      if (this.table) {
        this.table.load(page,size);
      }
    };

    const toExport = () => {
      if (total > 0) {
        if (this.table) {
          this.table.export();
        }
      } else {
        message.warn("没有可以导出的数据~");
      }
    };

    return (
      <div>
        <div className="h46 padding bg-white flex justify-between align-center">
          <Breadcrumb>
            <Breadcrumb.Item>任务管理</Breadcrumb.Item>
            <Breadcrumb.Item>任务记录管理</Breadcrumb.Item>
          </Breadcrumb>
          <div className="flex-1 text-center">
            <Pagination
              current={page + 1}
              total={total}
              size="small"
              showQuickJumper
              pageSize={size}
              onChange={(p,size) => {
                load(p - 1,size);
              }}
            />
          </div>
          <div>
            {/* <Search
              placeholder="搜索关键字"
              onSearch={toSearch}
              style={{ width: 200 }}
            /> 
            <Dropdown
              overlay={this.getCampusOverlay()}
              className="margin-left-sm"
            >
              <Button>
                {campus ? campus.name : "全部"}
                <DownOutlined />
              </Button>
            </Dropdown>
            */}
            <div
              className={
                showSearch
                  ? "bg-brown light padding-lr dpib pointer"
                  : "padding-lr dpib pointer"
              }
              onClick={toggleSearch}
            >
              <SearchOutlined />
            </div>

            <Button
              type="primary"
              icon={<PlusOutlined />}
              className="margin-left-sm margin-right-sm"
              onClick={toAdd}
            >
              发布文章
            </Button>
            {/*<Button onClick={toExport}>导出</Button>*/}
          </div>
        </div>

        {showSearch ? (
          <ArticleSearchBar
            onSearch={doSearch}
            onCancel={() => this.setState({ showSearch: false })}
          />
        ) : null}

        <ArticleTable
          childRef={(t) => (this.table = t)}
          params={params}
          onPage={onPage}
        />
      </div>
    );
  }
}

const ArticleSearchBar = (props) => {
  const [campuses, setCampuses] = useState([]);
  const [campus, setCampus] = useState(null);

  const [grades, setGrades] = useState([]);
  const [grade, setGrade] = useState(null);

  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(null);

  const [name, setName] = useState(null);

  const [top, setTop] = useState(null);
  const [share, setShare] = useState(null);

  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  useEffect(() => {
    loadCampus();
    loadCategories();
  }, []);

  useEffect(() => {
    setGrades([]);
    if (campus) {
      loadGrades(campus.id);
    }
  }, [campus]);

  const loadCategories = () => {
    TaskService.getCategory((res) => {
      let rows = res.data || [];
      setCategories(rows);
    });
  };

  const loadCampus = () => {
    SchoolService.getCampus({ size: 999 }, (res) => {
      if (res && res.success) {
        let arr = res.data.rows;
        arr.splice(0, 0, { name: "全部", id: "" });
        setCampuses(arr);
      } else {
        message.error("加载校区数据失败");
      }
    });
  };

  const loadGrades = (cid) => {
    SchoolService.getGrade(cid, (res) => {
      let grades = [];
      if (res.success) {
        res.data.forEach((g) => {
          grades.push(
            ...g.grades.sort(
              (a, b) => (typeof a === "number" ? a : a.clazz) - b.clazz
            )
          );
        });
      }
      grades.splice(0, 0, { name: "全部", id: "" });
      setGrades(grades);
    });
  };

  const onCampusChange = (id) => {
    let item = findItemInArr(campuses, "id", { id });
    setCampus(item);
  };

  const onGradeChange = (gid) => {
    let grade = findItemInArr(grades, "id", { id: gid });
    setGrade(grade);
  };
  const onCategoryChange = (id) => {
    let item = findItemInArr(categories, "id", { id });
    setCategory(item);
  };

  const onNameChange = (e) => {
    setName(e.target.value);
  };

  const onTopChange = (e) => {
    let checked = e.target.checked;
    setTop(checked);
  };

  const onShareChange = (e) => {
    let checked = e.target.checked;
    setShare(checked);
  };
  const onRangeChange = (arr, dates) => {
    setStart(dates[0]);
    setEnd(dates[1]);
  };

  const onSearch = () => {
    let params = {};
    if (campus && campus.id) {
      params.campusId = campus.id;
    }
    if (grade && grade.id) {
      params.gradeId = grade.id;
    }
    if (category) {
      params.categoryId = category.id;
    }
    if (name) {
      params.authorName = name;
    }
    if (top) {
      params.isTop = top;
    }
    if (share) {
      params.shareGrade = share;
    }
    if (start) {
      params.start = start;
    }
    if (end) {
      params.end = end;
    }

    props.onSearch && props.onSearch(params);
  };

  return (
    <div className="padding bg-brown light">
      <Form layout="inline">
        <Form.Item label="校区" className="margin-bottom-sm ">
          <Select
            onChange={onCampusChange}
            style={{ width: "200px" }}
            placeholder="请选择"
          >
            {campuses.map((g) => (
              <Option value={g.id} key={g.id}>
                {g.name + (g.year ? "(" + g.year + ")" : "")}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="班级" className="margin-bottom-sm ">
          <Select
            onChange={onGradeChange}
            style={{ width: "160px" }}
            placeholder="请选择"
          >
            {grades.map((g) => (
              <Option value={g.id} key={g.id}>
                {g.name + (g.year ? "(" + g.year + ")" : "")}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="分类" className="margin-bottom-sm ">
          <Select
            onChange={onCategoryChange}
            style={{ width: "160px" }}
            placeholder="请选择"
          >
            <Option value="" >全部</Option>
            {categories.map((c) => (
              <Option value={c.id} key={c.id}>
                {c.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="学生姓名" className="margin-bottom-sm">
          <Input onChange={onNameChange} placeholder="姓名关键字" />
        </Form.Item>
        <Form.Item label="状态" className="margin-bottom-sm">
          <Checkbox onChange={onTopChange}>置顶</Checkbox>
          <Checkbox onChange={onShareChange}>共享</Checkbox>
        </Form.Item>
        <Form.Item label="时间" className="margin-bottom-sm">
          <RangePicker
            showTime={true}
            ranges={timeranges}
            onChange={onRangeChange}
            format="YYYY-MM-DD HH:mm:ss"
          />
        </Form.Item>

        <Button
          type="primary"
          icon={<SearchOutlined />}
          onClick={onSearch}
          className="margin-right"
        >
          搜索
        </Button>
        <Button onClick={props.onCancel}>取消</Button>
      </Form>
    </div>
  );
};

export default Article;
