import React, { Component } from "react";
import { Breadcrumb, DatePicker, Button, message, Select } from "antd";
import SchoolService from "../../services/school";
import TaskService from "../../services/task";
import CampusQuestionStatistic, {
  E_RELOAD_QUESTION_STATISTIC,
} from "../../components/CampusQuestionStatistic";
import { timeranges } from "../../utils/cons";
import util from "../../utils/util";
import emitter from "../../emitter";

const { Option } = Select;
const { RangePicker } = DatePicker;

export class MyCampus extends Component {
  constructor(props) {
    super();
    this.unmount = false;
    let week = timeranges["本月"];
    this.state = {
      campuses: [],
      grades: [],
      classes: [],
      categories: [],
      campus: null,
      year: null,
      grade: null,
      category: null,
      start: week[0],
      end: week[1],
    };
  }
  componentDidMount() {
    this.init();
  }
  componentWillUnmount(){
    this.unmount = true;
  }
  init() {
    this.loadCampus();
    this.loadCategories();
  }
  loadCategories() {
    TaskService.getCategory((res) => {
      if(!this.unmount){
        if (res.success) {
          let categories = res.data;        
          this.setState({ categories });
        } else {
          message.error("加载任务分类失败");
        }
      }
    });
  }
  loadCampus() {
    this.setState({ loading: true });
    SchoolService.getCampus({ size: 999 }, (res) => {
      if (res && res.success) {
        let campuses = res.data.rows || [];
        this.setState({ campuses });
      } else {
        message.error("加载校区数据失败");
      }
      this.setState({ loading: false });
    });
  }

  renderQuery() {
    let { campuses, grades, classes, categories } = this.state;

    const onChange = (dates) => {
      if (dates) {
        let start = dates[0],
          end = dates[1];
        this.setState({ start, end });
      }
    };
    const campusChange = (id) => {
      let campus = null;
      if (id) {
        campus = campuses.find((item) => item.id === id);
        SchoolService.getGrade(campus.id, (res) => {
          if(!this.unmount){
          if (res && res.success) {
            let grades = res.data || [];
            this.setState({ grades });
          } else {
            message.error("获取班级数据失败");
          }
          }
        });
      } else {
        this.setState({ grades: [], classes: [], year: null, class: null });
      }
      this.setState({ campus });
    };
    const gradeYearChange = (year) => {
      if (year) {
        let classes = this.state.grades.find((i) => i.year === parseInt(year))
          .grades;
        this.setState({ year, classes });
      } else {
        this.setState({ year: null, classes: [], grade: null });
      }
    };
    const gradeChange = (id) => {
      let grade = null;
      if (id) {
        grade = this.state.classes.find((i) => i.id === id);
      }
      this.setState({ grade });
    };
    const taskCategoryChange = (id) => {
      let category = null;
      if (id) {
        category = this.state.categories.find((i) => i.id === id);
      }
      this.setState({ category });
    };
    const query = () => {
      emitter.emit(E_RELOAD_QUESTION_STATISTIC);
    };
    return (
      <div className="flex-col">
        <div className="text-center">
          <RangePicker
            defaultValue={[this.state.start, this.state.end]}
            ranges={timeranges}
            onChange={onChange}
          />
        </div>
        <div className="text-center margin-top">
          <Select
            placeholder="校区"
            style={{ width: 200 }}
            onChange={campusChange}
          >
            <Option value="">全部</Option>
            {campuses.map((campus) => (
              <Option value={campus.id} key={campus.id}>
                {campus.name}
              </Option>
            ))}
          </Select>
          <Select
            placeholder="年级"
            style={{ width: 120 }}
            onChange={gradeYearChange}
          >
            <Option value="">全部</Option>
            {grades.map((item) => (
              <Option value={item.year} key={item.year}>
                {util.getGradeName(item)}
              </Option>
            ))}
          </Select>
          <Select
            placeholder="班级"
            style={{ width: 120 }}
            onChange={gradeChange}
          >
            <Option value="">全部</Option>
            {classes.map((item) => (
              <Option value={item.id} key={item.id}>
                {item.clazz}班
              </Option>
            ))}
          </Select>
          <Select
            placeholder="任务类型"
            style={{ width: 120 }}
            onChange={taskCategoryChange}
          >
            <Option value="">全部</Option>
            {categories.map((cat) => (
              <Option value={cat.id} key={cat.id}>
                {cat.title}
              </Option>
            ))}
          </Select>
        </div>
        <div className="text-center margin-top">
          <Button type="primary" className="margin-left-sm" onClick={query}>
            查询
          </Button>
        </div>
      </div>
    );
  }

  render() {
    let start = this.state.start.format("YYYY-MM-DD HH:mm:ss");
    let end = this.state.end.format("YYYY-MM-DD HH:mm:ss");

    return (
      <div>
        <div className="padding bg-white flex justify-between  align-center h46">
          <Breadcrumb>
            <Breadcrumb.Item>统计分析</Breadcrumb.Item>
            <Breadcrumb.Item>问题统计</Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className="margin">
          <div className="margin text-center padding">{this.renderQuery()}</div>
          <div className="margin-bottom padding">
            <CampusQuestionStatistic
              campus={this.state.campus}
              year={this.state.year}
              grade={this.state.grade}
              category={this.state.category}
              start={start}
              end={end}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MyCampus;
