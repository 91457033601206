import Service from "./service";


/**
 * 附件相关服务接口
 */
class AttachService extends Service {

  static upload(files,callback) {
    let url = './api/attach';
    let data = new FormData();
    files.forEach(f=>{
      data.append('files',f,f.name);
    })
    super.upload(url,data,callback);
  }

}

export default AttachService;
