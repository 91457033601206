
import { createAction, createReducer} from "@reduxjs/toolkit";
import initState from './state';


export const setMe = createAction("setMe");
export const setMePreload = createAction("setMePreload");
export const setSetting = createAction("setSetting");
export const setDict = createAction("setDict");
export const setAuth = createAction("setAuth");

const reducer = createReducer(initState,{
    [setMe]:(state,action)=>{
        // 设置当前用户
        let me = action.payload;
        return Object.assign(state,{me});
    },
    [setMePreload]:(state,action)=>{
        // 设置当前用户
        let mePreload = action.payload;
        return Object.assign(state,{mePreload});
    },
    [setSetting]:(state,action)=>{
        // 获取系统设置
        let settings = action.payload;
        return Object.assign(state,{settings});
    },
    [setDict]:(state,action)=>{
        // 获取系统设置
        let dicts = action.payload;
        return Object.assign(state,{dicts});
    },
    [setAuth]:(state,action)=>{
        // 获取系统设置
        let auth = action.payload;
        return Object.assign(state,{auth:auth});
    },
});


export default reducer;