import React, { Component } from "react";
import { connect } from "react-redux";
import { FolderOpenOutlined,RadarChartOutlined,ExperimentOutlined,TrophyOutlined,RobotOutlined,PaperClipOutlined } from '@ant-design/icons';
import Main from "../../components/Main";
import Category from "./Category";
import TaskManage from "./TaskManage";
import Article from "./Article";
import Badge from "./Badge";
import Title from "./Title";
import Question from "./Question";


const menus = [
  { title: "分类管理", path: "category", icon: <FolderOpenOutlined/>, component: Category ,code:'TASK_CATEGORY'},
  { title: "任务管理", path: "level", icon: <RadarChartOutlined/>, component: TaskManage ,code:'TASK_LEVEL'},
  { title: "任务记录", path: "article", icon: <ExperimentOutlined/>, component: Article ,code:'TASK_RECORD'},
  { title: "奖章管理", path: "badge", icon: <TrophyOutlined/>, component: Badge ,code:'TASK_BADGE'},
  { title: "称号管理", path: "title", icon: <RobotOutlined/>, component: Title ,code:'TASK_TITLE'},
  { title: "问题管理", path: "question", icon: <PaperClipOutlined />, component: Question ,code:'TASK_ASK'},
];

export class Task extends Component {
  constructor() {
    super();
    this.state = {
      
    };
  }

  render() {
    return <Main menus={menus} />
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Task);
