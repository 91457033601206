import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
// import {setSetting} from './store/reducer';
// import SettingService from './services/setting';  
import App from "./App";
import { notification } from "antd";

import moment from 'moment'

moment.defaultFormat = 'YYYY-MM-DD HH:mm:ss'

notification.config({
  placement: 'bottomRight',
  bottom: '20px',
  duration: 3,
})

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

(function() {
  document.title = store.getState().site.title;
  
  // SettingService.getSetting(res=>{
  //   if(res.success){
  //     let rs = res.data;
  //     store.dispatch(setSetting(rs));
  //   }
  // })
  
})();
