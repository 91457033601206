import React, { Component } from "react";
import { notification, message, Table, Button, Modal } from "antd";
import emitter from "../emitter";
import QuestionForm from "./QuestionForm";
import util from "../utils/util";
import QuestionService from "../services/question";

const { confirm } = Modal;

export const E_TO_ADD_QUESTION = "toAddQuestion";

export class QuestionTable extends Component {
  constructor() {
    super();
    this.state = {
      rows: [],
      payload: null
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.payloadChange = this.payloadChange.bind(this);
    this.toAdd = this.toAdd.bind(this);
  }

  componentDidMount() {
    this.setState({loading:true})
    QuestionService.getAll(res => {
      this.setState({loading:false})
      if (res.success) {
        let rows = res.data;
        this.setState({ rows });
      } else {
        message.error("加载问题出错：" + res.message);
      }
    });
    emitter.addListener(E_TO_ADD_QUESTION, this.toAdd);
  }
  toAdd() {
    let payload = { times: 2 };
    this.setState({ payload });
  }
  toModify(item) {
    // console.log(item);
    let payload = Object.assign({}, item);
    this.setState({ payload });
  }
  toRemove(record, index) {
    let that = this;
    confirm({
      title: "请确认",
      content: "您确定要删除问题吗？",
      onOk: ()=> {
        QuestionService.remove(record.id, res => {
          if (res.success) {
            notification.success({
              message: "操作成功！",
              description: "问题删除成功！"
            });
            let rows = [].concat(that.state.rows);
            rows.splice(index, 1);
            that.setState({ rows });
          } else {
            notification.error({
              message: "删除问题失败",
              description: res.message
            });
          }
        });
      }
    });
  }
  getColumns() {
    return [
      {
        title: "序号",
        key: "index",
        className: "text-center",
        render: (text, record, index) => <span>{index + 1}</span>,
        width: "80px"
      },
      { title: "标题", dataIndex: "title" },
      {
        title: "类型",
        dataIndex : "type",
        width: "120px",
      },
      {
        title: "选项",
        dataIndex: "options",
      },
      {
        title: "默认值",
        dataIndex: "defaultValue",
        width: "120px",
      },
      {
        title: "权重",
        dataIndex: "weight",
        width: "80px",
      },
      {
        title: "操作",
        key: "action",
        width: "150px",
        render: (text, record, index) => {
          return (
            <Button.Group size="small">
              <Button
                onClick={() => {
                  this.toModify(record);
                }}
              >
                修改
              </Button>
              <Button
                type="danger"
                onClick={() => {
                  this.toRemove(record, index);
                }}
              >
                删除
              </Button>
            </Button.Group>
          );
        }
      }
    ];
  }
  handleOk() {
    let that = this;
    let { payload } = this.state;
    if (!payload.title) {
      message.warn("请填写问题标题");
      return;
    }
    if (!payload.type) {
      message.warn("请设置问题类型");      
      return;
    }else{
      if( (payload.type === 'select' || payload.type === 'radio'||payload.type === 'checkbox') && !payload.options ){
        message.warn("请设置问题选项");      
        return;
      }
    }
    if(payload.weight <0 || payload.weight > 1){
      message.warn("权重的范围是：0 - 1");      
      return;
    }
    confirm({
      title: "请确认",
      content: "您确定要保存问题信息吗？",
      onOk: ()=> {
        that.save(payload);
      }
    });
  }
  save(payload) {
    let rows = [].concat(this.state.rows);
    QuestionService.save(payload, res => {
      if (res.success) {
        notification.success({
          message: "操作成功！",
          description: "保存问题成功！"
        });
        let rs = res.data;
        let index = util.findIdxInArr(rows, "id", rs);
        if (index >= 0) {
          rows[index] = rs;
        } else {
          rows.push(rs);
        }
        rows.sort((a, b) => b.weight - a.weight);
        this.setState({ rows });
        this.handleCancel();
      } else {
        notification.error({
          message: "保存问题失败",
          description: res.message
        });
      }
    });
  }
  handleCancel() {
    this.setState({ payload: null });
  }
  payloadChange(payload) {
    this.setState({ payload });
  }
  render() {
    return (
      <div>
        <Table
          loading={this.state.loading}
          bordered={true}
          className="bg-white"
          columns={this.getColumns()}
          dataSource={this.state.rows}
          rowKey={record => record.id}
          pagination={false}
        />
        {this.state.payload ? (
        <Modal
          title="问题编辑"
          visible={!!this.state.payload}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          
            <QuestionForm
              item={this.state.payload}
              onChange={this.payloadChange}
            />
        </Modal>):null}
      </div>
    );
  }
}

export default QuestionTable;
