import Service from './service';

// const CACHE = {};

/**
 * 任务相关服务接口
 */
class TaskService extends Service {

    static getCategory(callback) {
        // let key = 'category';
        // let res = CACHE[key];
        // if (res) {
        //     callback(res);
        // } else {
        let url = './api/category?all=true';
        super.get(url, rs => {
            // CACHE[key] = rs;
            callback(rs);
        });
        // }
    }

    static saveCategory(payload, callback) {
        let url = './api/category';
        super.post(url, payload, callback);
    }
    static removeCategory(id, callback) {
        let url = `./api/category/${id}`;
        super.delete(url, callback);
    }
    static changeCategoryEnable(id, enable, callback) {
        let url = `./api/category/${id}/enable?enable=${enable}`;
        super.put(url, {}, callback);
    }
    static getTask(params, callback) {
        let url = './api/task?';
        url += Object.keys(params).map(key => {
            return `${key}=${params[key]}`;
        }).join('&');
        super.get(url, callback);
    }
    static saveTask(payload, callback) {
        let url = './api/task';
        super.post(url, payload, callback);
    }
    static removeTask(id, callback) {
        let url = `./api/task/${id}`;
        super.delete(url, callback);
    }
    static getStatistic(params, callback) {
        let url = `./api/task/statistic?`;
        url += Object.keys(params)
            .map(key => {
                return `${key}=${params[key]}`;
            })
            .join("&");
        super.get(url, callback);
    }

    /// 统计

    static getCampusStat(params, callback) {
        let url = `./api/task/stat/campus?`;
        url += Object.keys(params)
            .map(key => {
                return `${key}=${params[key]}`;
            })
            .join("&");
        super.get(url, callback);
    }
    static getCampusStatExport(params) {
        let url = `./api/task/stat/campus?`;
        url += Object.keys(params)
            .map(key => {
                return `${key}=${params[key]}`;
            })
            .join("&");
        return url + "&export=true";
    }
    /**
     * 获得统计校区头衔学生列表
     */
    static getCampusTitleStudent(params, callback) {
        let url = `./api/task/stat/campus/title/student?`;
        url += Object.keys(params)
            .map(key => {
                return `${key}=${params[key]}`;
            })
            .join("&");
        super.get(url, callback);
    }
    static getCampusTitleStudentExportLink(params) {
        let url = `./api/task/stat/campus/title/student?`;
        url += Object.keys(params)
            .map(key => {
                return `${key}=${params[key]}`;
            })
            .join("&");
        return url + "&export=true"
    }


    static getGradeStat(params, callback) {
        let url = `./api/task/stat/grade?`;
        url += Object.keys(params)
            .map(key => {
                return `${key}=${params[key]}`;
            })
            .join("&");
        super.get(url, callback);
    }

    static getGradeStatExport(params) {
        let url = `./api/task/stat/grade?`;
        url += Object.keys(params)
            .map(key => {
                return `${key}=${params[key]}`;
            })
            .join("&");
        return url + "&export=true";
    }
    static getClassStat(params, callback) {
        let url = `./api/task/stat/class?`;
        url += Object.keys(params)
            .map(key => {
                return `${key}=${params[key]}`;
            })
            .join("&");
        super.get(url, callback);
    }
    static getClassStatExport(params) {
        let url = `./api/task/stat/class?`;
        url += Object.keys(params)
            .map(key => {
                return `${key}=${params[key]}`;
            })
            .join("&");
        return url + "&export=true";
    }
    static getStudentStat(params, callback) {
        let url = `./api/task/stat/student?`;
        url += Object.keys(params)
            .map(key => {
                return `${key}=${params[key]}`;
            })
            .join("&");
        super.get(url, callback);
    }
    static getStudentStatExport(params) {
        let url = `./api/task/stat/student?`;
        url += Object.keys(params)
            .map(key => {
                return `${key}=${params[key]}`;
            })
            .join("&");
            return url + "&export=true";
    }

}

export default TaskService;