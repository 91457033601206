import React, { Component } from "react";
import { connect } from "react-redux";
import { 
  // SmileOutlined,
  CrownOutlined ,
  BarChartOutlined,
} from '@ant-design/icons';
import Main from "../../components/Main";

// import MyClass from "./MyClass";
import MyCampus from "./MyCampus";
import TaskStat from "./TaskStat";

const menus = [
  { title: "问题统计", path: "campus", icon: <CrownOutlined />, component: MyCampus,code:'STAT_ASK' },
  { title: "任务统计", path: "task", icon: <BarChartOutlined />, component: TaskStat ,code:'STAT_TASK'},
];

export class Workstation extends Component {
  constructor() {
    super();
    this.state = {
      
    };
  }

  render() {
    return <Main menus={menus} collapsed={true}/>
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Workstation);
