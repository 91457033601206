import React, { useState, useEffect } from "react";
import { Select, message } from "antd";

import { findItemInArr } from "../utils/util";

import SchoolService from "../services/school";

const { Option } = Select;

export default function CampusSelect(props) {
  const { onChange, all,onLoad } = props;

  const [rows, setRows] = useState([]);

  
  const loadCampus = () => {
    SchoolService.getCampus({ size: 999 }, (res) => {
      if (res && res.success) {
        let arr = res.data.rows;
        if (all && all === true) {
          arr.splice(0, 0, { name: "全部", id: "" });
        }
        setRows(arr);

        onLoad && onLoad(arr)
      } else {
        message.error("加载校区数据失败");
      }
    });
  };

  useEffect(loadCampus,[]);


  const onCampusChange = (id) => {
    let item = findItemInArr(rows, "id", { id });
    onChange(item);
  };

  return (
    <Select
      onChange={onCampusChange}
      style={{ width: "200px" }}
      placeholder="请选择"
    >
      {rows.map((g) => (
        <Option value={g.id} key={g.id}>
          {g.name + (g.year ? "(" + g.year + ")" : "")}
        </Option>
      ))}
    </Select>
  );
}
