import React, { useState, useEffect } from "react";
import { Button, DatePicker, message, Table } from "antd";

import { timeranges } from "../../../utils/cons";
import TaskService from "../../../services/task";
import CampusSelect from "../../../components/CampusSelect";
import GradeYearSelect from "../../../components/GradeYearSelect";

const { RangePicker } = DatePicker;

export default function TaskStatCampus(props) {
  const { downloader } = props;

  const [range, setRange] = useState(timeranges["30天内"]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [campus, setCampus] = useState(null);
  const [year, setYear] = useState(null);

  useEffect(() => {
    setYear(null);
  }, [campus]);

  const onRangeChange = (arr) => {
    setRange(arr);
  };

  const onCampusChange = (item) => {
    setCampus(item);
  };

  const doQuery = () => {
    if (!campus) {
      message.warn("请先选择校区");
      return;
    }
    if (!year) {
      message.warn("请先选择年级");
      return;
    }
    setLoading(true);
    let params = {
      year,
      campusId: campus.id,
      start: range[0].format(),
      end: range[1].endOf('day').format(),
    };
    TaskService.getClassStat(params, (res) => {
      setLoading(false);
      if (res.success) {
        let { rows, columns } = res.data || { rows: [], columns: [] };
        setRows(rows);
        setColumns(
          columns.map((c) => {
            return {
              ...c,
              sorter: (a, b) =>{
                let x =  a[c["dataIndex"]] ,y = b[c["dataIndex"]];
                if( typeof x === 'number'){
                  return x -y
                }else{
                  return (x+'').localeCompare(y+'')
                }
              },
              sortDirections: ["descend", "ascend"],
            };
          })
        );
        downloader.link = TaskService.getClassStatExport(params);
      } else {
        message.error("获取统计数据失败");
      }
    });
  };

  const onGradeYearChange = (year) => {
    setYear(year);
  };

  return (
    <div className="padding w100">
      <div className="margin-bottom ">
        <span className="margin-right">
          <CampusSelect onChange={onCampusChange} />
        </span>

        {campus && campus.id ? (
          <span className="margin-right">
            <GradeYearSelect
              campusId={campus.id}
              onChange={onGradeYearChange}
            />
          </span>
        ) : null}

        <RangePicker
          defaultValue={range}
          ranges={timeranges}
          onChange={onRangeChange}
          format="YYYY-MM-DD"
          className="margin-right"
        />
        <Button type="primary" onClick={doQuery}>
          统计
        </Button>
      </div>
      <Table
        className="bg-white"
        columns={columns}
        dataSource={rows}
        rowKey={(record) => record.id}
        loading={loading}
        pagination={false}
      />
    </div>
  );
}
