import Axios from "axios";

const KEYCLOAK_ID_TOKEN = "keycloak_id_token"
const axios = Axios.create();
const redirect_uri = window.location.origin+"/"

export const KEYCLOAK_URI = "https://oauth.cqrm.com/realms/master";
export const CLIENT_ID = process.env.REACT_APP_KEYCLOAK_CLIENT_ID || "ldmz";
export const OPENID_URL =
  KEYCLOAK_URI +
  `/protocol/openid-connect/auth?response_type=code&scope=openid&client_id=${CLIENT_ID}&redirect_uri=${encodeURIComponent(redirect_uri)}`


export const getToken = (code) => {
  const url = KEYCLOAK_URI + `/protocol/openid-connect/token`;
  return axios.post(
    url,
    new URLSearchParams({
      code,
      grant_type: "authorization_code",
      client_id: CLIENT_ID,
      redirect_uri
    })
  );
};

export const getAccount = (token) => {
  const url = KEYCLOAK_URI + "/account";
  return axios.get(url, {
    headers: {
      Authorization: "bearer " + token
    }
  });
};

export const getAccountByCode = async (code) => {
  const res = await getToken(code);
  console.log(res);
  if (res.data) {
    const {access_token,id_token }= res.data;
    window.localStorage.setItem(KEYCLOAK_ID_TOKEN,id_token)
    const res2 = await getAccount(access_token);
    console.log(res2);
    if (res2.data) {
      const profile = res2.data;
      return profile;
    }
  }
  return null;
};

export const logout = async ()=>{
  const id_token = window.localStorage.getItem(KEYCLOAK_ID_TOKEN)
  window.location.href = KEYCLOAK_URI + `/protocol/openid-connect/logout?client_id=${CLIENT_ID}&id_token_hint=${id_token}&post_logout_redirect_uri=${encodeURIComponent(redirect_uri)}`
}
export const login = async ()=>{
  window.location.href = OPENID_URL
}