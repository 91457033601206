import React, { Component } from "react";
import { connect } from "react-redux";
import { ControlOutlined ,ProfileOutlined,UserOutlined,IdcardOutlined} from '@ant-design/icons';
import Main from "../../components/Main";
import Role from "./Role";
import User from "./User";
import Config from "./Config";
import Dict from "./Dict";

const menus = [
  { title: "用户管理", path: "user", icon: <UserOutlined/>, component: User ,code:'SETTING_USER'},
  { title: "角色权限管理", path: "role", icon: <IdcardOutlined/>, component: Role ,code:'SETTING_ROLE'},
  { title: "字典管理", path: "dict", icon: <ProfileOutlined/>, component: Dict ,code:'SETTING_DICT'},
  { title: "参数设置", path: "setting", icon: <ControlOutlined/>, component: Config ,code:'SETTING_CONFIG'},
];

export class Setting extends Component {
  constructor() {
    super();
    this.state = {
      
    };
  }

  render() {
    return <Main menus={menus} />
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Setting);
