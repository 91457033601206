import React, { Component } from "react";
import util from "../utils/util";
import { Form, Input, Empty, Button, Select, Switch } from "antd";
import TaskService from "../services/task";

const { Option } = Select;

export class BadgeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.item,
      categories: [],
      iconUrl: null
    };
    this.iptChange = this.iptChange.bind(this);
    this.toChooseImg = this.toChooseImg.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.advanceChange = this.advanceChange.bind(this);
  }
  componentDidMount() {
    if (this.props.item && this.props.item.icon) {
      let iconUrl = this.getAttUrl(this.props.item.icon);
      this.setState({ iconUrl });
    }
    TaskService.getCategory(res => {
      if (res.success) {
        let categories = res.data;
        this.setState({ categories });
      }
    });
  }
  getAttUrl(att) {
    return util.getAttUrl(att);
  }
  iptChange(e) {
    e.persist();
    let { name, value } = e.target;
    let payload = util.setValue(this.state.payload, name, value);

    this.triggerChange(payload);
  }
  advanceChange(value) {
    let payload = util.setValue(this.state.payload, "advance", value);
    this.triggerChange(payload);
  }
  triggerChange(payload) {
    this.setState({ payload });
    this.props.onChange(payload);
  }

  getCategorySelect() {
    let cat = this.state.payload.category;
    let id = cat ? cat.id : "";
    return (
      <Select defaultValue={id} onChange={this.handleCategoryChange}>
        {this.state.categories.map(c => {
          return (
            <Option value={c.id} key={c.id}>
              {c.title}
            </Option>
          );
        })}
      </Select>
    );
  }
  handleCategoryChange(id) {
    let category = util.findItemInArr(this.state.categories, "id", { id });
    let payload = util.setValue(this.state.payload, "category", category);
    this.triggerChange(payload);
  }
  toChooseImg() {
    let input = this.input;
    if (!input) {
      input = document.createElement("input");
      input.setAttribute("type", "file");
      // input.setAttribute('multiple','multiple');
      input.setAttribute("accept", "image/*");
      input.addEventListener("change", e => {
        // console.log(input);
        let files = input.files;

        let file = files[0];
        let payload = this.state.payload;
        payload.file = file;
        this.triggerChange(payload);

        const reader = new FileReader();
        // eslint-disable-next-line
        reader.addEventListener("load", e2 => {
          // console.log(e2);
          let target = e2.currentTarget;
          let iconUrl = target.result;
          this.setState({ iconUrl });
        });
        reader.readAsDataURL(file);
      });
      this.input = input;
    }
    input.click();
  }
  render() {
    let payload = this.state.payload;

    return payload ? (
      <Form layout="vertical">
        <Form.Item label="名称" className="margin-bottom-sm">
          <Input
            defaultValue={payload.name}
            onChange={this.iptChange}
            name="name"
          />
        </Form.Item>
        <Form.Item label="图标" className="margin-bottom-sm">
          {this.state.iconUrl ? (
            <img
              src={this.state.iconUrl}
              alt="icon"
              className="margin-right"
              style={{ height: "46px" }}
            />
          ) : null}
          <Button type="primary" onClick={this.toChooseImg}>
            选择图片
          </Button>
        </Form.Item>
        <Form.Item label="关联任务分类" className="margin-bottom-sm">
          {this.getCategorySelect()}
        </Form.Item>

        <Form.Item label="要求（完成任务数量）" className="margin-bottom-sm">
          <Input
            type="number"
            defaultValue={payload.times || 1}
            onChange={this.iptChange}
            name="times"
          />
        </Form.Item>
        <Form.Item label="晋级(萌主徽章)" className="margin-bottom-sm">
          <Switch
            className="margin-left-sm"
            checkedChildren="是"
            unCheckedChildren="否"
            defaultChecked={payload.advance}
            onChange={this.advanceChange}
          />
        </Form.Item>
      </Form>
    ) : (
      <Empty />
    );
  }
}

export default BadgeForm;
