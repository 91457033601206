import React, { useState } from "react";
import { Button, DatePicker, message, Table } from "antd";

import { timeranges } from "../../../utils/cons";
import TaskService from "../../../services/task";
import CampusSelect from "../../../components/CampusSelect";
import GradeSelect from "../../../components/GradeSelect";

const { RangePicker } = DatePicker;

export default function TaskStatCampus(props) {
  const { downloader } = props;

  const [range, setRange] = useState(timeranges["30天内"]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [campus, setCampus] = useState(null);
  const [grade, setGrade] = useState(null);

  const onRangeChange = (arr) => {
    setRange([arr[0].startOf("day"), arr[1].endOf("day")]);
  };

  const onCampusChange = (item) => {
    setCampus(item);
  };

  const doQuery = () => {
    if (grade) {
      setLoading(true);
      let params = {
        gradeId: grade.id,
        start: range[0].format(),
        end: range[1].endOf('day').format(),
      };
      TaskService.getStudentStat(params, (res) => {
        setLoading(false);
        if (res.success) {
          let { rows, columns } = res.data || { rows: [], columns: [] };
          setRows(rows);
          setColumns(
            columns.map((c) => {
              return {
                ...c,
                sorter: (a, b) =>{
                  let x =  a[c["dataIndex"]] ,y = b[c["dataIndex"]];
                  if( typeof x === 'number'){
                    return x -y
                  }else{
                    return (x+'').localeCompare(y+'')
                  }
                },
                sortDirections: ["descend", "ascend"],
              };
            })
          );
          downloader.link = TaskService.getStudentStatExport(params);
        } else {
          message.error("获取统计数据失败");
        }
      });
    } else {
      message.warn("请先班级");
    }
  };

  const onGradeChange = (item) => {
    setGrade(item);
  };

  return (
    <div className="padding w100">
      <div className="margin-bottom ">
        <span className="margin-right">
          <CampusSelect onChange={onCampusChange} />
        </span>
        <span className="margin-right">
          {campus && campus.id ? (
            <GradeSelect campusId={campus.id} onChange={onGradeChange} all={false}/>
          ) : null}
        </span>
        <RangePicker
          defaultValue={range}
          ranges={timeranges}
          onChange={onRangeChange}
          format="YYYY-MM-DD"
          className="margin-right"
        />
        <Button type="primary" onClick={doQuery}>
          统计
        </Button>
      </div>
      <Table
        className="bg-white"
        columns={columns}
        dataSource={rows}
        rowKey={(r) => r.id}
        loading={loading}
        pagination={false}
      />
    </div>
  );
}
