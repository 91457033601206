import React, { Component } from "react";
import util from "../utils/util";
import {Form,Input,Empty} from 'antd';
import ColorRadio from './ColorRadio';
const { TextArea } = Input;

export class CategoryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.item
    };
    this.iptChange = this.iptChange.bind(this);
    this.colorChange = this.colorChange.bind(this);
  }

  iptChange(e) {
    e.persist();
    let { name, value } = e.target;
    let payload = util.setValue(this.state.payload, name, value);

    this.triggerChange(payload);
  }
  triggerChange(payload) {
    this.setState({ payload });
    this.props.onChange(payload);
  }
  colorChange(value) {
    let payload = util.setValue(this.state.payload, "bgcolor", value);
    this.triggerChange(payload);
  }
  render() {
    let payload = this.state.payload;
    return payload ? (
      <Form layout="vertical">
        <Form.Item label="名称" className="margin-bottom-sm">
          <Input
            defaultValue={payload.title}
            onChange={this.iptChange}
            name="title"
          />
        </Form.Item>
        <Form.Item label="图标" className="margin-bottom-sm">
            {payload.icon?<img src={payload.icon} alt="icon" style={{height:'46px'}}/> : null}
        <Input
            defaultValue={payload.icon}
            onBlur={this.iptChange}
            name="icon"
          />
        </Form.Item>
        <Form.Item label="背景色" className="margin-bottom-sm">
          <ColorRadio onChange={this.colorChange} defaultValue={payload.bgcolor}/>
        </Form.Item>
        <Form.Item label="排序值(大值优先)" className="margin-bottom-sm">
          <Input
            type="number"
            defaultValue={payload.sort||1}
            onChange={this.iptChange}
            name="sort"
          />
        </Form.Item>
        <Form.Item label="描述">
          <TextArea
            rows={3}
            defaultValue={payload.description}
            onChange={this.iptChange}
            name="description"
          />
        </Form.Item>
      </Form>
    ) : (
      <Empty />
    );
  }
}

export default CategoryForm;
