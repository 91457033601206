import Service from "./service";

/**
 * 文章相关服务接口
 */
class ArticleService extends Service {
  static get(params, callback) {
    let url = `./api/article?`;
    url += Object.keys(params)
      .map(key => {
        return `${key}=${params[key]}`;
      })
      .join("&");
    super.get(url, callback);
  }
  static getExportUrl(params) {
    let url = `./api/article/export?`;
    url += Object.keys(params)
      .map(key => {
        return `${key}=${params[key]}`;
      })
      .join("&");
    return url;
  }
  static getStudentStatistic(params, callback) {
    let url = `./api/article/statistic/student?`;
    url += Object.keys(params)
      .map(key => {
        return `${key}=${params[key]}`;
      })
      .join("&");
    super.get(url, callback);
  }
  static save(payload, callback) {
    let url = `./api/article`;
    super.post(url, payload, callback);
  }
  static remove(id, callback) {
    let url = `./api/article/${id}`;
    super.delete(url, callback);
  }
}

export default ArticleService;
