import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { CrownOutlined,StarOutlined, BulbOutlined,SmileOutlined} from '@ant-design/icons';
import Main from "../../components/Main";
import Campus from "./Campus";
import Student from "./Student";
import Teacher from "./Teacher";
import Grade from "./Grade";

const menus = [
  { title: "校区管理", path: "campus", icon: <CrownOutlined/>, component: Campus ,code:'INFO_CAMPUS'},
  { title: "班级管理", path: "grade", icon: <StarOutlined/>, component: Grade ,code:'INFO_GRADE'},
  { title: "教师管理", path: "teacher", icon: <BulbOutlined />, component: Teacher ,code:'INFO_TEACHER'},
  { title: "学生管理", path: "student", icon: <SmileOutlined/>, component: Student ,code:'INFO_STUDENT'},
];

export class Info extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return <Main menus={menus}/>
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Info));
