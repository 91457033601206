import React, { Component } from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import { ConfigProvider } from "antd";
import zhCN from "antd/es/locale/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";

import { createBrowserHistory } from "history";
import Login from "./views/Login";
import Index from "./views/Index";

import "./assets/less/common.less";

const history = createBrowserHistory();

history.listen(location=>{
  console.log(location);
})

class App extends Component {
  componentDidMount() {
    moment.locale("zh-cn");
  }
  render() {
    return (
      <ConfigProvider locale={zhCN}>
        <Router history={history}>
          <Switch>
            <Redirect from="/" to="/index" exact/>
            <Route path="/index">
              <Index />
            </Route>
            <Route path="/login" exact>
              <Login />
            </Route>
          </Switch>
        </Router>
      </ConfigProvider>
    );
  }
}
export default App;
