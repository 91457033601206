import React, { Component } from "react";
import { Breadcrumb, Menu, message, Button, Dropdown,Modal ,notification,Spin} from "antd";
import { DownOutlined, PlusOutlined, CaretUpOutlined, FilterOutlined,MoreOutlined } from '@ant-design/icons';
import util from "../../utils/util";
import emitter from '../../emitter';
import SchoolService from "../../services/school";
import ClazzFilter from "../../components/ClazzFilter";
import StudentTable, { E_TO_ADD_STUDENT, E_TO_RELOAD_STUDENT } from "../../components/StudentTable";
import Search from "antd/lib/input/Search";

const { confirm } = Modal;
export class Student extends Component {
  constructor() {
    super();
    this.state = {
      importing:false,
      crtClazz: null,
      campus: [],
      grades: [],
      filter: true,
      search: {
        keyword: '',
      }
    };

    // bind this.
    this.onCampus = this.onCampus.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onClazzChange = this.onClazzChange.bind(this);
  }

  componentDidMount() {
    SchoolService.getCampus({ size: 999 }, res => {
      if (res && res.success) {
        let campus = res.data.rows;

        let crtCampus = campus[0];
        this.setState({ campus, crtCampus });
      } else {
        message.error("加载校区数据失败");
      }
    });
  }

  onCampus(e) {
    let id = e.key;
    if (id === 'all') {
      this.setState({ crtCampus: null, crtClazz: null });
      this.reloadStudent();
    } else {
      let crtCampus = util.findItemInArr(this.state.campus, "id", { id });
      this.setState({ crtCampus });
    }
  }

  getCampusOverlay() {
    return (
      <Menu onClick={this.onCampus} style={{ width: '200px' }}>
        {this.state.campus.map(item => {
          return <Menu.Item key={item.id}>{item.name}</Menu.Item>;
        })}
        <Menu.Item key="all">全部</Menu.Item>
      </Menu>
    );
  }

  onClazzChange(clazz) {
    this.setState({ crtClazz: clazz });
    this.reloadStudent();
  }

  reloadStudent() {
    setTimeout(() => {
      emitter.emit(E_TO_RELOAD_STUDENT);
    }, 10);
  }



  onSearch(keyword) {
    let search = this.state.search;
    search.keyword = keyword;
    this.setState({ filter: search });
    this.reloadStudent();
  }

  moreAction(e) {
    let key = e.key;
    if (key === "template") {
      this.toDownloadTemplate();
    } else if (key === "import") {
      this.toImport();
    } else if (key === "export") {
      this.toExport();
    }
  }
  toDownloadTemplate() {
      var link = document.createElement("a");
      link.setAttribute("href", "./api/student/template");
      link.setAttribute("download", "学生数据导入模板.xls");
      document.body.appendChild(link); // Required for FF
      link.click();
  }

  toImport() {
    let that = this;
    let campus = this.state.crtCampus;
    if (campus) {
      let input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", ".xls");
      input.addEventListener("change", e => {
        // console.log(input);
        let files = input.files;
        if (files.length > 0) {
          let file = files[0];
          let ext = util.getFileExtention(file.name);
          if (ext !== "xls" && ext !== "XLS") {
            message.warn("请选择.xls格式的文件");
            return;
          }
          confirm({
            title: "请确认",
            content: "您确定要将学生数据导入到【" + campus.name + "("+campus.alias+")】校区吗？",
            onOk: ()=> {
              that.setState({ importing: true });
              SchoolService.importStudent(campus.id, file, res => {
                that.setState({ importing: false });
                if (res.success) {
                  notification.success({
                    message: "操作成功！",
                    description: "导入学生数据成功!"
                  });
                  emitter.emit(E_TO_RELOAD_STUDENT);
                } else {
                  notification.error({
                    message: "导入学生数据失败",
                    description: res.message
                  });
                }
              });
            }
          });
        } else {
          message.warn("请选择导入csv文件");
        }
      });
      input.click();
    } else {
      message.warn("请先选择校区！");
    }
  }
  toExport() {
    let campus = this.state.crtCampus;
    if (campus) {
      var link = document.createElement("a");
      link.setAttribute("href", "./api/student/export?cid=" + campus.id);
      link.setAttribute("download", campus.name + "学生数据.xls");
      document.body.appendChild(link); // Required for FF

      link.click();
    } else {
      message.warn("请先选择校区");
    }
  }

  render() {
    let { crtCampus, crtClazz, filter } = this.state;

    const toggleFilter = () => {
      let filter = !this.state.filter;
      this.setState({ filter });
    };
    const toAdd = () => {
      emitter.emit(E_TO_ADD_STUDENT);
    };
    const getFilter = () => {
      return (
        <div className="bg-brown light">
          <ClazzFilter cid={crtCampus.id} onClick={this.onClazzChange} />
        </div>
      )
    }
    const getStudent = () => {

      return (
        <div className="bgwhite margin">
          <StudentTable gid={crtClazz ? crtClazz.id : null} filter={filter} />
        </div>
      )
    }

    const moreActionView = () => {
      return (
        <Menu onClick={e=>this.moreAction(e)}>
          <Menu.Item key="template">下载导入模板</Menu.Item>
          <Menu.Item key="import">批量导入</Menu.Item>
          {/* <Menu.Item key="export">导出全部</Menu.Item> */}
        </Menu>
      );
    };

    return (
      <div >
        <div className="h46 padding-lr bg-white flex justify-between align-center shadow">
          <Breadcrumb>
            <Breadcrumb.Item>信息管理</Breadcrumb.Item>
            <Breadcrumb.Item>学生管理</Breadcrumb.Item>
          </Breadcrumb>

          <div className="flex-row">
            <Search
              placeholder="关键字"
              onSearch={this.onSearch}
              style={{ width: 200 }}
            />
            <Dropdown overlay={this.getCampusOverlay()} className="margin-left-sm">
              <Button>
                {crtCampus ? crtCampus.name : "全部"}
                <DownOutlined />
              </Button>
            </Dropdown>
            <Button type="primary" icon={<PlusOutlined />} className="margin-left-sm" onClick={toAdd}>新增学生</Button>

            <Dropdown overlay={moreActionView()} trigger={["click"]}>
              <Button icon={<MoreOutlined />} className="margin-left-sm" />
            </Dropdown>

            <Button
              className="margin-left-sm"
              icon={filter ? <CaretUpOutlined /> : <FilterOutlined />}
              theme="outlined"
              onClick={toggleFilter}
            ></Button>
          </div>
        </div>


        <div>
          {crtCampus && filter ? getFilter() : null}
          {getStudent()}
        </div>

        <Modal
          title={null}
          footer={null}
          visible={this.state.importing}
          maskClosable={false}
          closable={false}
        >
          <div className="padding text-center">
            <Spin size="large" tip="导入中..." />
          </div>
        </Modal>
      </div>
    );
  }
}

export default Student;
