import React, { Component } from "react";
import util from "../utils/util";
import { Form, Input, Empty, Select, Switch, Spin } from "antd";
import SchoolService from "../services/school";
import { connect } from "react-redux";

const { Option } = Select;

export class TeacherForm extends Component {
  constructor(props) {
    super(props);
    let payload = {};
    if (props.item) {
      payload = Object.assign({}, props.item);
      payload.name = payload.user ? payload.user.name : null;
      payload.phone = payload.user ? payload.user.phone : null;
      payload.enabled = payload.user ? payload.user.enabled : false;
    }
    this.state = {
      payload,
      campus: [],
      grades: []
    };
    this.iptChange = this.iptChange.bind(this);
    this.valueChange = this.valueChange.bind(this);
    this.onCampusChange = this.onCampusChange.bind(this);
    this.enabledChange = this.enabledChange.bind(this);
  }

  componentDidMount() {
    this.setState({ loadingCampus: true });
    SchoolService.getCampus({ size: 999 }, res => {
      if (res.success) {
        let campus = res.data.rows;
        this.setState({ campus });
      }
      this.setState({ loadingCampus: false });
    });
  }
  enabledChange(value) {
    let user = this.state.payload.user || {};
    user.enabled = value;
    let payload = util.setValue(this.state.payload, "user", user);
    this.triggerChange(payload);
  }
  iptChange(e) {
    e.persist && e.persist();
    let { name, value } = e.target;
    if (name === "name" || name === "phone" || name === "password") {
      let user = this.state.payload.user || {};
      user[name] = value;
      name = "user";
      value = user;
    }
    this.valueChange(name, value);
  }
  valueChange(name, value) {
    let payload = util.setValue(this.state.payload, name, value);
    this.triggerChange(payload);
  }

  triggerChange(payload) {
    this.setState({ payload });
    this.props.onChange(payload);
  }

  onCampusChange(id) {
    let campus = util.findItemInArr(this.state.campus, "id", { id });
    let payload = util.setValue(this.state.payload, "campus", campus);
    this.triggerChange(payload);
  }

  render() {
    let payload = this.state.payload;
    let campus = this.state.campus;
    let cids = campus.map(c => c.id);
    let defaultCid = payload.campus
      ? cids.indexOf(payload.campus.id) >= 0
        ? payload.campus.id
        : cids[0]
      : cids[0];
    const getCourseOptions = () => {
      return this.props.courses.map(d => (
        <Select.Option key={d.id} value={d.value}>
          {d.value}
        </Select.Option>
      ));
    };
    return payload ? (
      <Form layout="vertical">
        <Form.Item label="校区" className="margin-bottom-sm ">
          {this.state.loadingCampus ? (
            <Spin />
          ) : (
            <Select defaultValue={defaultCid} onChange={this.onCampusChange}>
              {campus.map(c => (
                <Option value={c.id} key={c.id}>
                  {c.name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="激活码" className="margin-bottom-sm " name="code">
          <Input
            defaultValue={payload.code}
            onChange={this.iptChange}
            name="code"
          />
        </Form.Item>
        <Form.Item label="姓名" className="margin-bottom-sm " name="name">
          <Input
            defaultValue={payload.name}
            onChange={this.iptChange}
            name="name"
          />
        </Form.Item>
        <Form.Item
          label="电话（默认用户名)"
          className="margin-bottom-sm "
          name="phone"
        >
          <Input
            defaultValue={payload.phone}
            onChange={this.iptChange}
            name="phone"
          />
        </Form.Item>
        <Form.Item label="学科" className="margin-bottom-sm " name="course">
          <Select
            defaultValue={payload.course}
            onChange={value => this.valueChange("course", value)}
          >
            {getCourseOptions()}
          </Select>
        </Form.Item>

        <Form.Item label="启用账号" className="margin-bottom-sm">
          <Switch
            checkedChildren="是"
            unCheckedChildren="否"
            defaultChecked={payload.enabled}
            onChange={this.enabledChange}
          />
        </Form.Item>
      </Form>
    ) : (
      <Empty />
    );
  }
}
const mapStateToProps = state => {
  let courses = state.dicts.filter(d => d.code === "COURSE");
  return { courses };
};
const mapDispatchToProps = dispatch => ({});
export default connect(mapStateToProps, mapDispatchToProps)(TeacherForm);
