import React, { Component } from "react";
import { message, Button, Table, notification, Modal } from "antd";
import TaskForm from "../components/TaskForm";
import TaskService from "../services/task";
import util from "../utils/util";
import emitter from "../emitter";
import { E_TASK_CHANGE } from "./TaskForm";

const { confirm } = Modal;

export const E_TO_ADD_TASK = "toAddTask";

export class TaskTable extends Component {
  constructor() {
    super();
    this.state = {
      rows: [],
      loading: false,
      payload: null,
      visible: false,
    };
    this.getDataSource = this.getDataSource.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.payloadChange = this.payloadChange.bind(this);
    this.toAddTask = this.toAddTask.bind(this);
  }
  componentDidMount() {
    this.setState({ loading: true });
    TaskService.getTask({}, (res) => {
      this.setState({ loading: false });
      if (res.success) {
        let rows = res.data;
        this.setState({ rows });
      } else {
        message.error("加载任务数据失败：" + res.message);
      }
    });

    emitter.addListener(E_TASK_CHANGE, this.payloadChange);
    emitter.addListener(E_TO_ADD_TASK, this.toAddTask);
  }
  componentWillUnmount() {
    emitter.removeListener(E_TASK_CHANGE, this.payloadChange);
    emitter.removeListener(E_TO_ADD_TASK, this.toAddTask);
  }
  toAddTask() {
    let payload = { bgcolor: "red", reward: 1, grade: "1" };
    this.setState({ payload });
  }
  getColumns() {
    return [
      {
        title: "序号",
        key: "index",
        render: (text, record, index) => <span>{index + 1}</span>,
        className: "text-center",
        width: "80px",
      },
      { title: "名称", key: "title", render: this.getTitle },
      {
        title: "分类",
        key: "category",
        width: "120px",
        render: this.getCategory,
      },
      {
        title: "背景色",
        key: "bgcolor",
        width: "80px",
        render: this.getBgcolor,
        className: "text-center",
      },
      {
        title: "排序",
        dataIndex: "sort",
        width: "80px",
        className: "text-center",
      },
      {
        title: "自选",
        key: "options",
        width: "80px",
        render: this.getOptional,
        className: "text-center",
      },
      // {
      //   title: "奖励星",
      //   dataIndex: "reward",
      //   width: "80px",
      //   className: "text-center"
      // },
      {
        title: "操作",
        key: "action",
        width: "150px",
        render: (text, record, index) => {
          return (
            <Button.Group size="small">
              <Button onClick={() => this.toModify(record)}>修改</Button>
              <Button
                type="danger"
                onClick={() => this.toRemove(record, index)}
              >
                删除
              </Button>
            </Button.Group>
          );
        },
      },
    ];
  }
  toModify(task) {
    // console.log(item);
    let payload = Object.assign({}, task);
    this.setState({ payload });
  }
  getTitle(text, record) {
    let grade = record.grade;
    let arr = grade ? grade.split(",") : [];
    return (
      <div className="flex flex-col">
        <div className="text-grey">{record.title}</div>
        <div className="text-gray text-sm">{record.description}</div>
        {arr.length > 0 ? (
          <div>
            {arr.map((i) => (
              <span className="cu-tag sm bg-orange" key={i}>
                {util.toChineseNum(parseInt(i)) + "年级"}
              </span>
            ))}
          </div>
        ) : null}
      </div>
    );
  }

  getBgcolor(text, record) {
    return record.bgcolor ? (
      <div className={`cu-tag bg-${record.bgcolor}`}>{record.bgcolor}</div>
    ) : (
      "-"
    );
  }
  getOptional(text, record) {
    return record.optional ? (
      <div className="cuIcon-check text-green"></div>
    ) : (
      <div className="cuIcon-close"></div>
    );
  }
  getCategory(text, record) {
    return record.category ? (
      <div className="cu-tag">{record.category.title}</div>
    ) : (
      "-"
    );
  }

  getDataSource() {
    let rs = this.state.rows,
      filter = this.props.filter;
    if (filter) {
      rs = this.state.rows.filter((item) => {
        let rs = [];
        if (filter.cid) {
          rs.push(item.category.id === filter.cid);
        }
        if (filter.grade) {
          rs.push(item.grade && item.grade.indexOf(filter.grade) >= 0);
        }
        return rs.filter((i) => i).length === rs.length;
      });
    }
    return rs.sort((a, b) => b.updateTime - a.updateTime);
  }
  payloadChange(payload) {
    this.setState({ payload });
  }
  handleCancel() {
    this.setState({ payload: null });
  }
  handleOk() {
    let { payload, rows } = this.state;
    let that = this;
    if (!payload.title) {
      message.warn("请填写任务名称");
      return;
    }
    if (!payload.category) {
      message.warn("请选择任务分类");
      return;
    }
    if (!payload.grade) {
      message.warn("请选择使用年级");
      return;
    }
    if (!payload.bgcolor) {
      message.warn("请选择任务背景色");
      return;
    }
    // if(!payload.reward){
    //   message.warn('请设置任务奖励星');
    //   return ;
    // }
    confirm({
      title: "请确认",
      content: "您确定要保存任务吗？",
      onOk: () => {
        TaskService.saveTask(payload, (res) => {
          if (res.success) {
            let rs = res.data;
            message.success("任务保存成功!");
            // 替换
            let index = util.findIdxInArr(rows, "id", rs);
            if (index >= 0) {
              // 修改
              rows[index] = rs;
            } else {
              // 添加
              rows.splice(0, 0, rs);
            }
            rows.sort((a, b) => b.sort - a.sort);
            that.setState({ rows });

            that.handleCancel();
          } else {
            notification.error({
              message: "保存任务失败",
              description: res.message,
            });
          }
        });
      },
    });
  }
  toRemove(record, index) {
    let that = this;
    confirm({
      title: "请确认",
      content: "您确定要删除任务吗？",
      onOk: () => {
        TaskService.removeTask(record.id, (res) => {
          if (res.success) {
            notification.success({
              message: "操作成功！",
              description: "任务删除成功！",
            });
            let rows = [].concat(that.state.rows);
            rows.splice(index, 1);
            that.setState({ rows });
          } else {
            notification.error({
              message: "删除任务失败",
              description: res.message,
            });
          }
        });
      },
    });
  }

  render() {
    return (
      <div>
        <Table
          loading={this.state.loading}
          bordered={true}
          className="bg-white"
          columns={this.getColumns()}
          dataSource={this.getDataSource()}
          rowKey={(record) => record.id}
          pagination={false}
        />
        {this.state.payload ? (
          <Modal
            title="任务编辑"
            visible={!!this.state.payload}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            <TaskForm item={this.state.payload} />
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default TaskTable;
