import React, { Component } from "react";
import { Button, Table,notification,Modal,Empty, message } from "antd";
import SettingService from "../services/setting";
import SettingForm from "./SettingForm";
import emitter from "../emitter";
// import util from "../utils/util";

const {confirm} = Modal;

export const E_TO_ADD_SETTING ="toAddSetting"

export class SettingTable extends Component {
  constructor() {
    super();
    this.state = {
      rows: [],
      payload: null
    };
    this.toAdd = this.toAdd.bind(this);
    this.toModify = this.toModify.bind(this);
    this.toRemove = this.toRemove.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.payloadChange = this.payloadChange.bind(this);
  }
  componentDidMount() {
    this.load();
    emitter.addListener(E_TO_ADD_SETTING,this.toAdd);
  }
  load() {
    SettingService.getAll(res => {
        if (res.success) {
          let rows = res.data;
          this.setState({  rows});
        } else {
          notification.error({
            message: "加载参数列表失败",
            description: res.message
          });
        }
      }
    );
  }
  
  getColumns() {
    return [
      {
        title: "序号",
        key: "index",
        render: (text, record, index) => <span>{index + 1}</span>,
        className: "text-center",
        width: "80px"
      },
      { title: "参数KEY", dataIndex: "code" ,width: "120px" },
      { title: "参数名称", dataIndex: "cname" ,width: "140px" },
      { title: "参数值", dataIndex: "value"},
      {
        title: "操作",
        key: "action",
        width: "150px",
        render: (text, record, index) => {
          return (
            <Button.Group size="small">
              <Button onClick={() => this.toModify(record)}>修改</Button>
              <Button
                type="danger"
                onClick={() => this.toRemove(record, index)}
              >
                删除
              </Button>
            </Button.Group>
          );
        }
      }
    ];
  }
  toModify(item) {
    // console.log(item);
    let payload = Object.assign({}, item);
    this.setState({ payload });
  }
  toRemove(record, index) {
    let that= this;
    confirm({
      title: "请确认",
      content: "您确定要删除参数吗？",
      onOk: ()=> {
        SettingService.remove(record.code, res => {
          if (res.success) {
            notification.success({
              message: "操作成功！",
              description: "参数删除成功！"
            });
            let rows = [].concat(that.state.rows);
            rows.splice(index, 1);
            that.setState({ rows });
          } else {
            notification.error({
              message: "删除失败",
              description: res.message
            });
          }
        });
      }
    });
  }

  toAdd(){
    let payload = {};
    this.setState({payload});
  }
  handleCancel() {
    this.setState({ payload: null });
  }
  payloadChange(payload) {
    this.setState({ payload });
  }
  handleOk(){
    let that = this;
    let {rows,payload}= this.state;
    if(!payload.code){
      message.warn('请填写参数KEY')
      return;
    }
    if(!payload.cname){
      message.warn('请填写参数名称')
      return;
    }
    if(!payload.value){
      message.warn('请填写参数值')
      return;
    }
    confirm({
      title: "请确认",
      content: "您确定要保存参数信息吗？",
      onOk: ()=> {
        SettingService.save(payload, res => {
          if (res.success) {
            notification.success({
              message: "操作成功！",
              description: "保存参数成功！"
            });
            // let rs = res.data;
            // let index = util.findIdxInArr(rows,'code',rs);
            // if(index >= 0){
            //     rows[index] = rs;
            // }else{
            //     rows.splice(0,0,rs);
            // }           
            // that.setState({ rows });
            that.load();
            that.handleCancel();
          } else {
            notification.error({
              message: "保存参数失败",
              description: res.message
            });
          }
        });
      }
    });
  }


  render() {
    return (
      <div>
        <Table
          bordered={true}
          className="bg-white"
          columns={this.getColumns()}
          dataSource={this.state.rows}
          rowKey={record => record.code}
          pagination={false}
        />
         <Modal
          title="参数编辑"
          visible={!!this.state.payload}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          {this.state.payload ? (
            <SettingForm
              item={this.state.payload}
              onChange={this.payloadChange}
            />
          ) : (
            <Empty />
          )}
        </Modal>
      </div>
    );
  }
}

export default SettingTable;
