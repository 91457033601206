import React, { Component } from "react";
import { Form, Input, Empty, InputNumber } from "antd";

export class AuthForm extends Component {
  constructor(props) {
    super(props);

    this.form = React.createRef();
  }

  triggerChange(payload) {
    this.props.onChange(payload);
  }

  componentDidUpdate(pre) {
    let item = this.props.item;
    if (item !== pre.item) {
      let fc = this.form.current;
      fc.setFieldsValue(item);
    }
  }

  render() {
    let payload = this.props.item;

    const onFieldsChange = () => {
      this.triggerChange({ ...payload, ...this.form.current.getFieldsValue() });
    };

    return payload ? (
      <Form
        ref={this.form}
        layout="vertical"
        initialValues={payload}
        onFieldsChange={onFieldsChange}
      >
        <Form.Item label="父权限" className="margin-bottom-sm ">
          <Input
            disabled
            value={payload.parent ? payload.parent.name : ""}
          />
        </Form.Item>
        <Form.Item
          label="标识"
          className="margin-bottom-sm "
          name="code"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="名称"
          className="margin-bottom-sm"
          name="name"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="排序（大值优先）"
          className="margin-bottom-sm"
          name="sort"
        >
          <InputNumber />
        </Form.Item>
      </Form>
    ) : (
      <Empty />
    );
  }
}

export default AuthForm;
