import React, { Component } from "react";
import util from "../utils/util";
import { Form, Input, Empty, Switch, Select, Row, Col } from "antd";
import SchoolService from "../services/school";
import { Spin } from "antd";

const { Option } = Select;
const { TextArea } = Input;

export class ArticleForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.item,
      campus: [],
      grades: [],
      imgList: []
    };
    this.iptChange = this.iptChange.bind(this);
    this.onCampusChange = this.onCampusChange.bind(this);
    this.onGradeChange = this.onGradeChange.bind(this);
    this.shareCampusChange = this.shareCampusChange.bind(this);
    this.shareGradeChange = this.shareGradeChange.bind(this);

    this.toChooseImg = this.toChooseImg.bind(this);
    this.delImg = this.delImg.bind(this);
  }

  componentDidMount() {
    this.setState({ loadingCampus: true });
    SchoolService.getCampus({ size: 999 }, res => {
      if (res.success) {
        let campus = res.data.rows;
        this.setState({ campus });
        let crtCampus;
        if (this.state.payload.grade) {
          crtCampus = util.findItemInArr(
            campus,
            "id",
            this.state.payload.grade.campus.id
          );
        }
        if (!crtCampus) {
          crtCampus = campus[0];
        }
        this.setState({ loadingCampus: false });
        this.getGrades(crtCampus.id);
      }
    });
  }
  getGrades(id) {
    this.setState({ loadingGrade: true });
    SchoolService.getGrade(id, res => {
      if (res.success) {
        let grades = [];
        res.data.forEach(g => {
          grades.push(...g.grades.sort((a, b) => a.clazz - b.clazz));
        });
        this.setState({ grades });
      }
      this.setState({ loadingGrade: false });
    });
  }

  iptChange(e) {
    e.persist && e.persist();
    let { name, value } = e.target;
    let payload = util.setValue(this.state.payload, name, value);
    this.triggerChange(payload);
  }

  triggerChange(payload) {
    this.setState({ payload });
    this.props.onChange(payload);
  }

  onCampusChange(id) {
    let campus = util.findItemInArr(this.state.campus, "id", { id });
    let grades = [];
    this.setState({ grades });
    this.getGrades(campus.id);
    //校区
    let payload = util.setValue(this.state.payload, "campus", { id });
    this.triggerChange(payload);
  }
  onGradeChange(id) {
    // let grade = util.findItemInArr(this.state.grades, "id", {id});
    let payload = util.setValue(this.state.payload, "grade", { id });
    this.triggerChange(payload);
  }
  shareCampusChange(value) {
    let payload = util.setValue(this.state.payload, "shareCampus", value);
    this.triggerChange(payload);
  }
  shareGradeChange(value) {
    let payload = util.setValue(this.state.payload, "shareGrade", value);
    this.triggerChange(payload);
  }
  toChooseImg() {
    let input = this.input;
    if (!input) {
      input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("multiple", "multiple");
      input.setAttribute("accept", "image/*");
      input.addEventListener("change", e => {
        // console.log(input);
        let files = input.files;
        let arr = [];
        let readed = 0;

        for (let i = 0; i < files.length; i++) {
          let file = files[i];
          const reader = new FileReader();
          // eslint-disable-next-line
          reader.addEventListener("load", e2 => {
            // console.log(e2);
            let target = e2.currentTarget;
            let idx = -1;
            for (let j = 0; j < arr.length; j++) {
              if (arr[j].reader === target) {
                idx = j;
                break;
              }
            }
            arr[idx].dataurl = target.result;
            // eslint-disable-next-line
            readed += 1;
            if (readed === files.length) {
              this.setImgList(arr);
            }
          });
          reader.readAsDataURL(file);
          let item = {
            reader,
            file,
            dataurl: null
          };
          arr.push(item);
        }
      });
      this.input = input;
    }
    input.click();
  }
  delImg(index) {
    let imgList = this.state.imgList;
    imgList.splice(index, 1);
    //this.setState({imgList});
    this.setImgList(imgList);
  }
  setImgList(imgList) {
    this.setState({ imgList });
    let payload = util.setValue(this.state.payload, "imgList", imgList);
    this.triggerChange(payload);
  }
  render() {
    let payload = this.state.payload;
    let campus = this.state.campus;
    let grades = this.state.grades;
    let cids = campus.map(c => c.id);
    let gids = grades.map(g => g.id);

    let defaultCid = payload.grade
      ? payload.grade.campus
        ? cids.indexOf(payload.grade.campus.id) >= 0
          ? payload.grade.campus.id
          : cids[0]
        : cids[0]
      : cids[0];
    let defaultGid = payload.grade
      ? gids.indexOf(payload.grade.id) >= 0
        ? payload.grade.id
        : ""
      : "";

    let inline = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 }
    };
    return payload ? (
      <Form layout="vertical">
        <Form.Item label="校区" className="margin-bottom-sm ">
          {this.state.loadingCampus ? (
            <Spin />
          ) : (
            <Select defaultValue={defaultCid} onChange={this.onCampusChange}>
              {campus.map(c => (
                <Option value={c.id} key={c.id}>
                  {c.name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="班级" className="margin-bottom-sm ">
          {this.state.loadingGrade ? (
            <Spin />
          ) : (
            <Select defaultValue={defaultGid} onChange={this.onGradeChange}>
              {grades.map(g => (
                <Option value={g.id} key={g.id}>
                  {g.name + "(" + g.year + ")"}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Row className="inline-form">
          <Col span={12}>
            <Form.Item
              labelCol={inline.labelCol}
              wrapperCol={inline.wrapperCol}
              label="同校区共享"
              className="margin-bottom-sm"
            >
              <Switch
                className="margin-left-sm"
                checkedChildren="是"
                unCheckedChildren="否"
                defaultChecked={payload.shareGrade}
                onChange={this.shareGradeChange}
              />
            </Form.Item>
          </Col>
          <Col span={12} className="padding-left">
            <Form.Item
              labelCol={inline.labelCol}
              wrapperCol={inline.wrapperCol}
              label="全校区共享"
              className="margin-bottom-sm"
            >
              <Switch
                className="margin-left-sm"
                checkedChildren="是"
                unCheckedChildren="否"
                defaultChecked={payload.shareCampus}
                onChange={this.shareCampusChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="文章内容" className="margin-bottom-sm ">
          <TextArea
            defaultValue={payload.content}
            onChange={this.iptChange}
            name="content"
          />
        </Form.Item>
        <div className="margin-bottom-sm text-bold">图片</div>
        <div className="grid col-4 grid-square flex-sub">
          {this.state.imgList.map((item, index) => (
            <div className="bg-img" key={index}>
              <img src={item.dataurl} alt="img" />
              <div className="cu-tag bg-red" onClick={() => this.delImg(index)}>
                <span className="cuIcon-close pointer"></span>
              </div>
            </div>
          ))}
          <div className="solids pointer" onClick={this.toChooseImg}>
            <span className="cuIcon-add"></span>
          </div>
        </div>
      </Form>
    ) : (
      <Empty />
    );
  }
}

export default ArticleForm;
