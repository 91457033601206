import React, { Component } from "react";
import { notification, Tree } from "antd";
import UserService from "../services/user";
import util from "../utils/util";
import emitter from "../emitter";
import { Empty } from "antd";

export const E_TO_RELOAD_AUTH = "toReloadAuthTree"

export class AuthTree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeData: []
    };
    this.load = this.load.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onCheck = this.onCheck.bind(this);
  }
  componentDidMount() {
    this.load();
    emitter.addListener(E_TO_RELOAD_AUTH,this.load);
  }
  componentWillUnmount(){
      emitter.removeListener(E_TO_RELOAD_AUTH,this.load);
  }
  load() {
    UserService.getAuthTree(res => {
      if (res.success) {
        let treeData = res.data.map(n => {
          if (n.children && n.children.length === 0) {
            delete n.children;
          }
          return n;
        });
        this.setState({ treeData });
      } else {
        notification.error({
          message: "加载权限树失败",
          description: res.message
        });
      }
    });
  }
  onSelect(keys) {
    let item = util.findNode(this.state.treeData, keys[0]);
    this.props.onSelect && this.props.onSelect(item);
  }
  onCheck(keys) {
    let arr = keys.map(k => {
      return util.findNode(this.state.treeData, k);
    });
    this.props.onCheck && this.props.onCheck(arr);
  }
  render() {
    let treeData = util.treeMap(this.state.treeData||[] , item=>{
      item.title = item.name;
      item.key = item.id;
      return item;
    });
    return treeData.length > 0 ? (
      <div>
        <Tree
          checkable={this.props.checkable}
          treeData={treeData}
          showLine
          defaultExpandAll
          defaultSelectedKeys={this.props.selectedKeys}
          checkedKeys={this.props.checkedKeys}
          onSelect={this.onSelect}
          onCheck={this.onCheck}
        ></Tree>
      </div>
    ) : <Empty />;
  }
}

export default AuthTree;
