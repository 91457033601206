import React, { Component } from "react";
import util from "../utils/util";
import { Form, Input, Empty, Radio } from "antd";

export class QuestionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.item,
      types: ["select", "radio", "checkbox", "input", "textarea"]
    };
    this.iptChange = this.iptChange.bind(this);
  }

  iptChange(e) {
    e.persist && e.persist();
    let { name, value } = e.target;
    let payload = util.setValue(this.state.payload, name, value);

    this.triggerChange(payload);
  }

  triggerChange(payload) {
    this.setState({ payload });
    this.props.onChange(payload);
  }

  render() {
    let payload = this.state.payload;

    const getTypoRadio = () => {
      return this.state.types.map((t,i) => {
        return <Radio value={t} key={i}>{t}</Radio>;
      });
    };

    return payload ? (
      <Form layout="vertical">
        <Form.Item label="标题" className="margin-bottom-sm">
          <Input
            defaultValue={payload.title}
            onChange={this.iptChange}
            name="title"
          />
        </Form.Item>
        <Form.Item label="问题类型" className="margin-bottom-sm">
          <Radio.Group
            defaultValue={payload.type}
            onChange={this.iptChange}
            name="type"
          >
            {getTypoRadio()}
          </Radio.Group>
        </Form.Item>
        {
          (payload.type !== 'input' || payload.type !== 'textarea') ? <Form.Item label="选项（多个之间逗号分隔）" className="margin-bottom-sm">
          <Input
            placeholder='是,否,不确定'
            defaultValue={payload.options}
            onChange={this.iptChange}
            name="options"
          />
        </Form.Item> : null
        }
        <Form.Item label="默认值" className="margin-bottom-sm">
          <Input
            defaultValue={payload.defaultValue}
            onChange={this.iptChange}
            name="defaultValue"
          />
        </Form.Item>
        <Form.Item label="权重（值范围:0 - 1，大值优先）" className="margin-bottom-sm">
          <Input
            type='number'
            defaultValue={payload.weight||0.5}
            onChange={this.iptChange}
            name="weight"
          />
        </Form.Item>       
      </Form>
    ) : (
      <Empty />
    );
  }
}

export default QuestionForm;
