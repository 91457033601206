import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { notification, Spin, Row, Col } from "antd";
import QuestionService from "../services/question";
import util from '../utils/util';
import emitter from '../emitter';

export const E_RELOAD_QUESTION_STATISTIC = "reloadQuestionStatistic"

class CampusQuestionStatistic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
    };
    this.reload = this.reload.bind(this)
  }
  componentDidMount() {
    this.loadStatistic();
    emitter.on(E_RELOAD_QUESTION_STATISTIC,this.reload)
  }
  componentWillUnmount(){
    emitter.off(E_RELOAD_QUESTION_STATISTIC,this.reload)
  }
  reload(){
    this.loadStatistic()
  }
  loadStatistic() {
    let param = {
      start: this.props.start,
      end: this.props.end,
    };
    let {campus,year,grade,category} = this.props;

    if (campus) {
      param.campusId = campus.id;
    }
    if(year){
      param.gradeYear = year
    }
    if(grade){
      param.gradeId = grade.id
    }
    if(category){
      param.categoryId = category.id
    }
    this.setState({ loading: true });
    QuestionService.getQuestionStatistic(param, (res) => {
      if (res.success) {
        let data = res.data.sort((a, b) => {
          return (
            b.items.reduce(
              (i, j) => (typeof i === "number" ? i : i.count) + j.count,
              0
            ) - (typeof a === "number"
              ? a
              : a.items.reduce(
                  (i, j) => (typeof i === "number" ? i : i.count) + j.count,
                  0
                ))
            
          );
        });
        this.setState({ data, loading: false });
      } else {
        this.setState({ loading: false });
        notification.error({
          message: "加载出错",
          description: "加载校区统计信息失败：" + res.message,
        });
      }
    });
  }

  getOption(item) {
    let items = item.items.sort(
      (a, b) => (typeof a === "number" ? a : a.count) - b.count
    );
    let total = items.reduce((a,b)=>(typeof a === "number" ? a : a.count) + b.count,0)
    let category = item.options.split(","); //items.map(i=>i.title)
    return {
      title: {
        text: item.title.split("：")[0] + "统计",
        subtext: item.title + `共${total}次`,
        left: "left",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      toolbox: {
        show: true,
        feature: {
          dataView: { title: "数据视图" },
          magicType: {
            type: ["line", "bar"],
          },
          saveAsImage: { title: "保存图片" },
        },
      },
      xAxis: {
        type: "value",
      },
      yAxis: {
        type: "category",
        data: category,
      },
      series: [
        {
          data: category.map((c) => {
            //items.map(i=>i.count)
            let i = util.findItemInArr(items, "title", { title: c });
            return i ? i.count : 0;
          }),
          type: "bar",
        },
      ],
    };
  }

  getOptionAll(){
    let data = [...this.state.data];
    let total = data.reduce((a,b)=>
      (typeof a === "number" ? a :  a.items.reduce((n,m)=>(typeof n === "number" ? n : n.count) + m.count,0) ) + 
      b.items.reduce((n,m)=>(typeof n === "number" ? n : n.count) + m.count,0),
      0);
    let category = data.map(item=>item.title.split('：')[0])

    return {
      title: {
        text: "问题汇总统计",
        subtext: `共${total}条记录`,
        left: "left",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      toolbox: {
        show: true,
        feature: {
          dataView: { title: "数据视图" },
          magicType: {
            type: ["line", "bar"],
          },
          saveAsImage: { title: "保存图片" },
        },
      },
      xAxis: {
        type: "category",
        data: category,
        
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: data.map(item=>{
            return item.items.reduce((n,m)=>(typeof n === "number" ? n : n.count) + m.count,0)
          }),
          type: "bar",
        },
      ],
    };
  }

  render() {
    const getChart = () => {

      const optionAll = this.getOptionAll();

      return (
        <div>
          <div className="padding bg-white margin-bottom">
          <ReactEcharts option={optionAll} /> 
          </div>

          <Row gutter={16}>
            {this.state.data.map((qs) => {
              let option = this.getOption(qs);
              return <Col xl={8} lg={12} md={24} key={qs.id}> 
              <div className="padding bg-white margin-bottom">          
                <ReactEcharts option={option} /> 
              </div>
              </Col>;
            })}
          </Row>
        </div>
      );
    };

    return (
      <div>
        {this.state.loading ? (
          <div className="padding text-center">
            <Spin />
          </div>
        ) : (
          getChart()
        )}
      </div>
    );
  }
}

export default CampusQuestionStatistic;
