import React, { Component } from "react";
import { Breadcrumb, Button, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import RoleTable, { E_TO_ADD_ROLE } from "../../components/RoleTable";
import AuthTable from '../../components/AuthTable';

import emitter from "../../emitter";

export class Role extends Component {
  render() {
    const toAdd = () => {
      emitter.emit(E_TO_ADD_ROLE);
    };

    return (
      <div className="padding">
        <Row gutter={16}>
          <Col xl={16} lg={24}>
            <div className="h46 padding bg-white flex justify-between  align-center">
              <Breadcrumb>
                <Breadcrumb.Item>角色管理</Breadcrumb.Item>
              </Breadcrumb>
              <div>
                <Button type="primary" icon={<PlusOutlined />} onClick={toAdd}>
                  新增角色
                </Button>
              </div>
            </div>
            <div className="bg-white margin-bottom">
              <RoleTable />
            </div>
          </Col>
          <Col xl={8} lg={24}>            
              <AuthTable />            
          </Col>
        </Row>
      </div>
    );
  }
}

export default Role;
