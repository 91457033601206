import React, { useState, useEffect } from "react";
import { Select, Spin } from "antd";
import { findItemInArr } from "../utils/util";
import SchoolService from "../services/school";

const { Option } = Select;

export default function GradeSelect(props) {

  const { onChange, campusId ,all=true } = props;
  const [loading, setLoading] = useState(false);
  const [grades, setGrades] = useState([]);

  useEffect(() => {
    loadGrades(campusId);
  }, [campusId]);

  const loadGrades = (cid) => {
    setLoading(true);
    SchoolService.getGrade(cid, (res) => {
      setLoading(false);
      let grades = [];
      if (res.success) {
        res.data.forEach((g) => {
          grades.push(
            ...g.grades.sort(
              (a, b) => (typeof a === "number" ? a : a.clazz) - b.clazz
            )
          );
        });
      }
      if(all){
        grades.splice(0, 0, { name: "全部", id: "" });
      }
      setGrades(grades);
    });
  };

  const onGradeChange = (gid) => {
    let grade = findItemInArr(grades, "id", { id: gid });
    //setGrade(grade);
    onChange && onChange(grade);
  };

  return loading ? (
    <Spin />
  ) : (
    <Select
      onChange={onGradeChange}
      style={{ width: "160px" }}
      placeholder="请选择"
    >
      {grades.map((g) => (
        <Option value={g.id} key={g.id}>
          {g.name + (g.year ? "(" + g.year + ")" : "")}
        </Option>
      ))}
    </Select>
  );
}
