import React, { Component } from "react";
import util from "../utils/util";
import { Form, Input, Empty } from "antd";

export class RoleForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.item
    };
    this.iptChange = this.iptChange.bind(this);
    this.enabledChange = this.enabledChange.bind(this);
  }

  iptChange(e) {
    e.persist();
    let { name, value } = e.target;
    let payload = util.setValue(this.state.payload, name, value);

    this.triggerChange(payload);
  }
  triggerChange(payload) {
    this.setState({ payload });
    this.props.onChange(payload);
  }
  enabledChange(value) {
    let payload = util.setValue(this.state.payload);
    this.triggerChange(payload);
  }

  render() {
    let payload = this.state.payload;

    return payload ? (
      <Form layout="vertical" initialValues={payload}>
        <Form.Item label="标识" className="margin-bottom-sm ">
          <Input
            defaultValue={payload.code}
            onChange={this.iptChange}
            name="code"
          />
        </Form.Item>

        <Form.Item label="名称" className="margin-bottom-sm">
          <Input
            defaultValue={payload.name}
            onChange={this.iptChange}
            name="name"
          />
        </Form.Item>        
      </Form>
    ) : (
      <Empty />
    );
  }
}

export default RoleForm;
