import React, { Component } from "react";
import { Button, Table, notification, Modal,  message } from "antd";
import DictService from "../services/dict";
import DictForm from "./DictForm";
import emitter from "../emitter";
import util from "../utils/util";

const { confirm } = Modal;

export const E_TO_ADD_DICT = "toAddDict";

export class DictTable extends Component {
  constructor() {
    super();
    this.state = {
      rows: [],
      payload: null,
    };
    this.toAdd = this.toAdd.bind(this);
    this.toModify = this.toModify.bind(this);
    this.toRemove = this.toRemove.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.payloadChange = this.payloadChange.bind(this);
  }
  componentDidMount() {
    this.load();
    emitter.addListener(E_TO_ADD_DICT, this.toAdd);
  }
  load() {
    this.setState({ loading: true });
    DictService.getAll((res) => {
      this.setState({ loading: false });
      if (res.success) {
        let rows = res.data.rows;
        this.setState({ rows });
      } else {
        notification.error({
          message: "加载字典列表失败",
          description: res.message,
        });
      }
    });
  }
  getColumns() {
    return [
      {
        title: "序号",
        key: "index",
        render: (text, record, index) => <span>{index + 1}</span>,
        className: "text-center",
        width: "80px",
      },
      { title: "字典CODE", dataIndex: "code", width: "120px" },
      { title: "字典名称", dataIndex: "cname", width: "120px" },
      { title: "字典值", dataIndex: "value" },
      {
        title: "操作",
        key: "action",
        width: "150px",
        render: (text, record, index) => {
          return (
            <Button.Group size="small">
              <Button onClick={() => this.toModify(record)}>修改</Button>
              <Button
                type="danger"
                onClick={() => this.toRemove(record, index)}
              >
                删除
              </Button>
            </Button.Group>
          );
        },
      },
    ];
  }
  toModify(item) {
    // console.log(item);
    let payload = Object.assign({}, item);
    this.setState({ payload });
  }
  toRemove(record, index) {
    let that = this;
    confirm({
      title: "请确认",
      content: "您确定要删除字典吗？",
      onOk: () => {
        DictService.remove(record.id, (res) => {
          if (res.success) {
            notification.success({
              message: "操作成功！",
              description: "字典删除成功！",
            });
            let rows = [].concat(that.state.rows);
            rows.splice(index, 1);
            that.setState({ rows });
          } else {
            notification.error({
              message: "删除失败",
              description: res.message,
            });
          }
        });
      },
    });
  }

  toAdd() {
    let payload = {};
    this.setState({ payload });
  }
  handleCancel() {
    this.setState({ payload: null });
  }
  payloadChange(payload) {
    this.setState({ payload });
  }
  handleOk() {
    let that = this;
    let { payload } = this.state;
    if (!payload.code) {
      message.warn("请填写字典CODE");
      return;
    }
    if (!payload.cname) {
      message.warn("请填写字典名称");
      return;
    }
    if (!payload.value) {
      message.warn("请填写字典值");
      return;
    }
    confirm({
      title: "请确认",
      content: "您确定要保存字典信息吗？",
      onOk: () => {
        DictService.save(payload, (res) => {
          if (res.success) {
            notification.success({
              message: "操作成功！",
              description: "保存字典成功！",
            });
            let rows = [].concat(that.state.rows);
            let rs = res.data;
            let index = util.findIdxInArr(rows, "id", rs);
            if (index >= 0) {
              rows[index] = rs;
            } else {
              rows.splice(0, 0, rs);
            }
            that.setState({ rows });
            that.handleCancel();
          } else {
            notification.error({
              message: "保存字典失败",
              description: res.message,
            });
          }
        });
      },
    });
  }
  render() {
    return (
      <div>
        <Table
          loading={this.state.loading}
          bordered={true}
          className="bg-white"
          columns={this.getColumns()}
          dataSource={this.state.rows}
          rowKey={(record) => record.id}
          pagination={false}
        />
        {this.state.payload ? (
          <Modal
            title="字典编辑"
            visible={!!this.state.payload}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            <DictForm item={this.state.payload} onChange={this.payloadChange} />
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default DictTable;
