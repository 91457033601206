import React, { Component } from "react";
import { Button, Table, notification, Modal,  Tag, message } from "antd";
import UserService from "../services/user";
import RoleForm from "./RoleForm";
import RoleUserModal from "./RoleUserModal";
import RoleAuthModal from "./RoleAuthModal";
import emitter from "../emitter";
// import util from "../utils/util";

const { confirm } = Modal;

export const E_TO_ADD_ROLE = "toAddRole";

export class RoleTable extends Component {
  constructor() {
    super();
    this.state = {
      rows: [],
      payload: null,
      crtRole: null,
      userModal: false,
      authModal: false,
    };
    this.toAdd = this.toAdd.bind(this);
    this.toModify = this.toModify.bind(this);
    this.toRemove = this.toRemove.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleOk = this.handleOk.bind(this);
    this.payloadChange = this.payloadChange.bind(this);
    this.showUser = this.showUser.bind(this);
    this.showAuth = this.showAuth.bind(this);
  }
  componentDidMount() {
    this.load();
    emitter.addListener(E_TO_ADD_ROLE, this.toAdd);
  }
  componentWillUnmount() {
    emitter.removeListener(E_TO_ADD_ROLE, this.toAdd);
  }
  load() {
    UserService.getRole((res) => {
      if (res.success) {
        let rows = res.data || [];
        this.setState({ rows });
      } else {
        notification.error({
          message: "加载角色列表失败",
          description: res.message,
        });
      }
    });
  }
  showUser(item) {
    this.setState({ crtRole: item, userModal: true });
  }
  showAuth(item) {
    this.setState({ crtRole: item, authModal: true });
  }
  getColumns() {
    return [
      {
        title: "标识",
        key: "index",
        render: (text, record, index) => <span>{index + 1}</span>,
        className: "text-center",
        width: "80px",
      },
      {
        title: "姓名",
        key: "name",
        render: (text, record) => (
          <div>
            <span>{record.name}</span>{" "}
            <small className="margin-left">{record.code}</small>
          </div>
        ),
      },
      {
        title: "用户",
        key: "users",
        width: "80px",
        render: (text, record) => (
          <Tag
            color="green"
            className="pointer"
            onClick={() => this.showUser(record)}
          >
            查看
          </Tag>
        ),
      },
      {
        title: "权限",
        key: "users",
        width: "80px",
        render: (text, record) => (
          <Tag
            color="green"
            className="pointer"
            onClick={() => this.showAuth(record)}
          >
            查看
          </Tag>
        ),
      },
      {
        title: "操作",
        key: "action",
        width: "150px",
        render: (text, record, index) => {
          return (
            <Button.Group size="small">
              <Button onClick={() => this.toModify(record)}>修改</Button>
              <Button
                type="danger"
                onClick={() => this.toRemove(record, index)}
              >
                删除
              </Button>
            </Button.Group>
          );
        },
      },
    ];
  }
  toModify(item) {
    // console.log(item);
    let payload = Object.assign({}, item);
    this.setState({ payload });
  }
  toRemove(record) {
    let that = this;
    confirm({
      title: "请确认",
      content: "您确定要删除角色吗？",
      onOk: () => {
        UserService.removeRole(record.id, (res) => {
          if (res.success) {
            notification.success({
              message: "操作成功！",
              description: "角色删除成功！",
            });
            that.load();
          } else {
            notification.error({
              message: "删除角色失败",
              description: res.message,
            });
          }
        });
      },
    });
  }

  toAdd() {
    if (!this.unmount) {
      let payload = { passed: true, enabled: true };
      this.setState({ payload });
    }
  }
  handleCancel() {
    this.setState({ payload: null });
  }
  payloadChange(payload) {
    this.setState({ payload });
  }
  handleOk() {
    let that = this;
    let { payload } = this.state;
    if (!payload.name) {
      message.warn("请输入角色名称");
    }
    if (!payload.code) {
      message.warn("请输入角色标识");
    }

    confirm({
      title: "请确认",
      content: "您确定要保存角色信息吗？",
      onOk: () => {
        UserService.saveRole(payload, (res) => {
          if (res.success) {
            that.handleCancel();
            notification.success({
              message: "操作成功！",
              description: "保存角色成功！",
            });
            that.load();
          } else {
            notification.error({
              message: "保存角色失败",
              description: res.message,
            });
          }
        });
      },
    });
  }
  render() {
    return (
      <div>
        <Table
          bordered={true}
          className="bg-white"
          columns={this.getColumns()}
          dataSource={this.state.rows}
          rowKey={(record) => record.id}
          pagination={false}
        />
        {this.state.payload ? (
          <Modal
            title="角色信息编辑"
            visible={!!this.state.payload}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            <RoleForm item={this.state.payload} onChange={this.payloadChange} />
          </Modal>
        ) : null}
        {this.state.crtRole ? (
          <div>
            <RoleUserModal
              visible={this.state.userModal}
              item={this.state.crtRole}
              onCancel={() =>
                this.setState({ userModal: false, crtRole: null })
              }
            />
            <RoleAuthModal
              visible={this.state.authModal}
              item={this.state.crtRole}
              onCancel={() =>
                this.setState({ authModal: false, crtRole: null })
              }
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default RoleTable;
