import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { message, Form, Input, Button } from "antd";
import { UserOutlined,LockOutlined} from '@ant-design/icons';
import { connect } from "react-redux";
import UserService from "../services/user";
import { setMe } from "../store/reducer";
class NormalLoginForm extends Component {

  constructor(props){
    super(props)

    this.state ={
      payload:{
        username:null,
        password:null,
      }
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleError = this.handleError.bind(this);
  }

  handleSubmit = values => {
    //   console.log(values);
    UserService.login(values.username, values.password, res => {
      if (res && res.success) {
        message.success("登录成功");
        UserService.getMe(res2 => {
          if (res2 && res2.success) {
            let me = res2.data;
            this.props.setMe(me);
            this.props.history.push(`/index`);
          }
        });
      } else {
        message.error("用户名或密码错误");
      }
    });
  };

  handleError = ({errorFields})=>{
    console.log(errorFields);
  }

  render() {
    return (
      <div>
        <h1 className="margin-bottom">登录</h1>
        <Form  layout="vertical" initialValues={this.state.payload} onFinish={this.handleSubmit} onFinishFailed={this.handleError} className="login-form">
          <Form.Item name="username" rules={[{ required: true, message: "请输入用户名!" }]}>
            <Input
              prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="用户名"
            />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: "请输入密码!" }]}>
            <Input
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="密码"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setMe: me => dispatch(setMe(me))
});

export default withRouter(
  connect(() => ({}), mapDispatchToProps)(NormalLoginForm)
);
