import React, { Component } from "react";
import util from "../utils/util";
import { Form, Input, Empty, Radio, Select } from "antd";
import SchoolService from "../services/school";
import { Spin } from "antd";

const { Option } = Select;

export class StudentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payload: props.item,
      campus: [],
      grades: []
    };
    this.iptChange = this.iptChange.bind(this);
    this.onCampusChange = this.onCampusChange.bind(this);
    this.onGradeChange = this.onGradeChange.bind(this);
  }

  componentDidMount() {
    this.setState({ loadingCampus: true });
    SchoolService.getCampus({ size: 999 }, res => {
      if (res.success) {
        let campus = res.data.rows;
        this.setState({ campus });
        let crtCampus;
        if (this.state.payload.grade) {
          let id = this.state.payload.grade.campus.id;
          crtCampus = util.findItemInArr(
            campus,
            "id",
            {id}
          );
        }
        if (!crtCampus) {
          crtCampus = campus[0];
        }
        this.setState({ loadingCampus: false });
        this.getGrades(crtCampus.id);
      }
    });
  }
  getGrades(id,callback) {
    this.setState({ loadingGrade: true });
    SchoolService.getGrade(id, res => {
      if (res.success) {
        let grades = [];
        res.data.forEach(g => {
          grades.push(...g.grades.sort((a, b) => (typeof a === "number" ? a : a.clazz) - b.clazz));
        });
        
        
        this.setState({ grades });

        callback && callback(grades);
      }
      this.setState({ loadingGrade: false });
    });
  }

  iptChange(e) {
    e.persist && e.persist();
    let { name, value } = e.target;
    let payload = util.setValue(this.state.payload, name, value);
    this.triggerChange(payload);
  }

  triggerChange(payload) {
    this.setState({ payload });
    this.props.onChange(payload);
  }

  onCampusChange(id) {
    let campus = util.findItemInArr(this.state.campus, "id", { id });
    let grades = [];
    this.setState({ grades });
    this.getGrades(campus.id,arr=>{
      if(arr.length > 0){
        this.onGradeChange(arr[0].id);
      }
    });
  }
  onGradeChange(id) {
    let grade = util.findItemInArr(this.state.grades, "id", { id });
    let payload = util.setValue(this.state.payload, "grade", grade);
    this.triggerChange(payload);
  }

  render() {
    let payload = this.state.payload;
    let campus = this.state.campus;
    let grades = this.state.grades;
    let cids = campus.map(c => c.id);
    let gids = grades.map(g => g.id);
    return payload ? (
      <Form layout="vertical">
        <Form.Item label="校区" className="margin-bottom-sm ">
          {this.state.loadingCampus ? (
            <Spin />
          ) : (
            <Select
              defaultValue={
                payload.grade
                  ? cids.indexOf(payload.grade.campus.id) >= 0
                    ? payload.grade.campus.id
                    : cids[0]
                  : cids[0]
              }
              onChange={this.onCampusChange}
            >
              {campus.map(c => (
                <Option value={c.id} key={c.id}>
                  {c.name}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="班级" className="margin-bottom-sm ">
          {this.state.loadingGrade ? (
            <Spin />
          ) : (
            <Select
              defaultValue={
                payload.grade
                  ? gids.indexOf(payload.grade.id) >= 0
                    ? payload.grade.id
                    : gids[0]
                  : gids[0]
              }
              onChange={this.onGradeChange}
            >
              {grades.map(g => (
                <Option value={g.id} key={g.id}>
                  {g.name + "(" + g.year + ")"}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="姓名" className="margin-bottom-sm ">
          <Input
            defaultValue={payload.name}
            onChange={this.iptChange}
            name="name"
          />
        </Form.Item>
        <Form.Item label="性别" className="margin-bottom-sm">
          <Radio.Group
            value={payload.sex}
            onChange={this.iptChange}
            name="sex"
          >
            <Radio value="男" >男</Radio>
            <Radio value="女" >女</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="激活码" className="margin-bottom-sm ">
          <Input
            defaultValue={payload.code}
            onChange={this.iptChange}
            name="code"
          />
        </Form.Item>
      </Form>
    ) : (
      <Empty />
    );
  }
}

export default StudentForm;
