import Service from './service';

/**
 * 称号相关服务接口
 */
class TitleService extends Service {

    static getAll(callback) {
        let url = './api/title';
        super.get(url, callback);
    }

    static save(payload, callback) {
        let url = './api/title';
        super.post(url, payload, callback);
    }
    static remove(id, callback) {
        let url = `./api/title/${id}`;
        super.delete(url, callback);
    }

}

export default TitleService;