import Service from './service';

/**
 * 用户相关服务接口
 */
class UserService extends Service {

    static login(username,password,callback){
        let url = './api/user/token';
        let params = new FormData();
        params.set('username',username);
        params.set('password',password);
        super.postForm(url,params,res=>{
            if(res && res.success){
                let token = res.data.token;
                super.setAuth(token);
            }
            callback && callback(res);
        });
    }
    static loginKeycloak(id,username,callback){
        let url = './api/user/token-keycloak';
        let params = new FormData();
        params.set('keycloakId',id);
        params.set('username',username);
        super.postForm(url,params,res=>{
            if(res && res.success){
                let token = res.data.token;
                super.setAuth(token);
            }
            callback && callback(res);
        });
    }

    static logout(callback){
        let url = './api/user/logout';
        super.get(url,callback);
    }
    static getMe(callback){
        let url = './api/user/me';
        super.get(url,callback);
    }
    static getMeAuth(callback){
        let url = './api/user/me/auth';
        super.get(url,callback);
    }
    static getUser(params,callback){
        let url = './api/user?';
        url += Object.keys(params).map(key=>{
            return  `${key}=${params[key]}`;
        }).join('&');
        super.get(url,callback);
    }
    static removeUser(id,callback){
        let url = `./api/user/${id}`;
        super.delete(url,callback);
    }
    static removeUserRole(id,callback){
        let url = `./api/user/role?ids=${id}`;
        super.delete(url,callback);
    }
    static save(payload,callback){
        let url = `./api/user`;
        super.post(url,payload,callback);
    }
    static update(payload,callback){
        let url = `./api/user`;
        super.put(url,payload,callback);
    }

    // role
    // role
    // role

    static getRole(callback){
        let url = './api/role';
        super.get(url,callback);
    }
    static getRoleAuth(id,callback){
        let url = `./api/role/${id}/auth`;
        super.get(url,callback);
    }
    static saveRoleUser(id,ids,callback){
        let url = `./api/role/${id}/user`;
        let data = new FormData();
        data.set('ids',ids);
        super.postFormEncode(url,data,callback);
    }
    static saveRoleAuth(id,ids,callback){
        let url = `./api/role/${id}/auth`;
        let data = new FormData();
        data.set('ids',ids);
        super.postFormEncode(url,data,callback);
    }
    static removeRole(id,callback){
        let url = `./api/role/${id}`;
        super.delete(url,callback);
    }
    static saveRole(payload,callback){
        let url = `./api/role`;
        super.post(url,payload,callback);
    }

    // auth
    // auth
    // auth

    static getAuthTree(callback) {
        let url = "./api/auth/tree";
        super.get(url,callback);    
      }
      static removeAuth(id, callback) {
        let url = `./api/auth/${id}`;
        super.delete(url, callback);
      }
      static saveAuth(payload, callback) {
        let url = `./api/auth`;
        super.post(url,payload, callback);
      }

}

export default UserService;