import React, { Component } from "react";
import { Spin } from "antd";
export class Loading extends Component {
  render() {
    return (
      <div className="padding-lg text-center flex flex-col">
        <Spin type="large" />
        <div className="margin-top"> {this.props.text || "努力加载中..."}</div>
      </div>
    );
  }
}

export default Loading;
